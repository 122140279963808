import { FC, useEffect, useState } from 'react'
import { IGetOrdersResponse } from '../../DTO/IGetOrdersResponse'
import BillingItem from './BillingItem/BillingItem'
import BillingTable from './BillingTable/BillingTable'
import { ElementsWrapper, ListWrapper } from './billingList.styles'

interface IBillingListProps {
    orders: IGetOrdersResponse[]
}

const BillingList: FC<IBillingListProps> = ({ orders }) => {
    const [selectItem, setSelectItem] = useState<number>(0)
    const [selectData, setSelectData] = useState(orders[0])

    useEffect(() => {
        setSelectData(orders[0])
    }, [orders])

    const handleSelection = (e: any, index: number): void => {
        e.preventDefault()
        setSelectItem(index)
        setSelectData(orders[index])
    }
    return (
        <ListWrapper>
            <ElementsWrapper>
                {orders.map((item, index) => {
                    return (
                        <BillingItem
                            key={item.AccountID}
                            data={item.Ordered}
                            cost={`${item.SalesPrice} EUR`}
                            isActive={selectItem === index}
                            onClick={(e: any) => handleSelection(e, index)}
                            onKeyPress={(e: any) => handleSelection(e, index)}
                        />
                    )
                })}
            </ElementsWrapper>

            <BillingTable
                data={selectData.Ordered}
                price={+selectData.SalesPrice}
                accountId={+selectData.AccountID}
                capital={+selectData.Type}
                risk={selectData.Risk}
                broker={selectData.Broker}
                platform={selectData.Platform}
                spreads={selectData.Spreads}
                paymentMethod={selectData.Payment}
            />
        </ListWrapper>
    )
}

export default BillingList
