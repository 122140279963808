import { Dispatch, FC, ReactElement, SetStateAction } from 'react'
import {
    CategoriesList,
    Category,
} from '../../../components/FilterInstruments/filterInstruments.styles'
import { clearTitle } from '../../Instruments/Instruments'
export interface IDashboardStatusFilterProps {
    status: DashboardStatus
    setStatus: Dispatch<SetStateAction<DashboardStatus>>
}

export enum DashboardStatus {
    Active = 'Active',
    Pending = 'Pending',
    AwaitingPayment = 'Awaiting Payment',
    Failed = 'Failed',
}

let status = Object.values(DashboardStatus)

const DashboardStatusFilter: FC<IDashboardStatusFilterProps> = (props) => {
    const getStatus = () => {
        let elements: ReactElement[] = []

        status.map((category, index) => {
            elements.push(
                <Category
                    key={index}
                    style={{ maxWidth: '540px' }}
                    className={`dashboard ${category === props.status ? 'active' : ''}`}
                    onClick={() => {
                        props.setStatus(category)
                    }}
                >
                    {clearTitle(category)}
                </Category>,
            )
        })
        return elements
    }
    return (
        <>
            <CategoriesList>{getStatus()}</CategoriesList>
        </>
    )
}

export default DashboardStatusFilter
