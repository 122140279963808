import styled from 'styled-components'
import { devices } from '../../devices'
import { IOpenMenuProps } from '../Menu/menu.styles'

export const StyledContainer = styled.section<IOpenMenuProps>`
    margin-left: 240px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    @media ${devices.md} {
        margin-left: ${({ isMenuOpen }) => (isMenuOpen ? '240px' : '0')};
        padding: 0 20px;
    }

    @media (max-width: 428px) {
        padding: 0;
    }
`
