export const format = (content: any, precision: number) => {
    let val
    if (isNaN(content)) val = content
    else
        val = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(content)
    return val
}
