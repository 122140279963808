import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Label, StyledCheckbox, Wrap } from '../OrderAndPay/orderAndPay.styles'
import { selectAllChallenge } from '../../features/challengeSlice'
import { t } from '../../utils/translate/t'
import 'reactjs-popup/dist/index.css'
import Popup from 'reactjs-popup'
import * as S from './challengeAddOns.styles'

interface IAddOn {
    costMultiplier: number
    disabled: boolean
    selected: boolean
    exclude?: string[]
    allowedSizes?: number[]
}

const ChallengeAddOns: FC = () => {
    const challenges = useSelector(selectAllChallenge)

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)
    const [addOns, setAddOns] = useState<any>({})
    const [checkedAddOnKeys, setCheckedAddOnKeys] = useState<Set<string>>(new Set())

    function excludeAddOns(key: string, checked: boolean) {
        if (!addOns[key].exclude) {
            return
        }
        let excludes = addOns[key].exclude

        window.wlcfg = window.wlcfg || {}
        let WlcfgAddOns = window.wlcfg.AddOns

        excludes.forEach((exclude: string) => {
            if (!WlcfgAddOns[exclude]) {
                return
            }
            WlcfgAddOns[exclude].disabled = checked
        })
        return
    }

    // useEffect(() => {
    //     return () => {
    //         effect
    //     };
    // }, [input]);

    // dispatch(
    //     priceChange({
    //         price: currentPrice,
    //     }),
    // )

    // console.log((window as any).wlcfg)

    useEffect(() => {
        setAddOns((window as any).wlcfg.AddOns)
    }, [])

    // isPossible double Capital
    useEffect(() => {
        if (!addOns.ADDON_DOUBLE_FUNDED_CAPITAL) {
            return
        }
        let isDoubleFundedPossible = !addOns.ADDON_DOUBLE_FUNDED_CAPITAL.allowedSizes.includes(
            Number(challenges.challenge.initialCapital.value),
        )
        addOns.ADDON_DOUBLE_FUNDED_CAPITAL.disabled = isDoubleFundedPossible
        if (!isDoubleFundedPossible && addOns.ADDON_DOUBLE_FUNDED_CAPITAL.selected) {
            addOns.ADDON_DOUBLE_FUNDED_CAPITAL.selected = false
        }
        checkedAddOnKeys.delete('ADDON_DOUBLE_FUNDED_CAPITAL')
    }, [challenges.challenge.initialCapital])

    // const Modal = (key: string) => (
    // )

    const AddOnsCheckBoxes = () => {
        let AddOnsKays = Object.keys(addOns)

        let CheckBoxes: any[] = []
        AddOnsKays.forEach((key) => {
            if (!addOns[key]) {
                return
            }
            let AddOn: IAddOn = addOns[key]
            CheckBoxes.push(
                <div style={{ display: 'inline-flex' }}>
                    <Label htmlFor={key}>
                        <StyledCheckbox
                            id={key}
                            disabled={AddOn.disabled}
                            defaultChecked={AddOn.selected}
                            checked={checkedAddOnKeys.has(key) || false}
                            onChange={(event) => {
                                excludeAddOns(key, event.target.checked)
                                if (event.target.checked) {
                                    setCheckedAddOnKeys((prevState) => new Set([...prevState, key]))
                                } else {
                                    setCheckedAddOnKeys(
                                        (prevState) =>
                                            new Set([...prevState].filter((x) => x !== key)),
                                    )
                                }
                            }}
                        />
                        <Wrap>
                            <p>{t(key)}</p>

                            {/*<S.Close />*/}
                            {/*<Popup trigger={<button> Trigger</button>} position="right center">*/}
                            {/*    <div>content</div>*/}
                            {/*</Popup>*/}
                        </Wrap>
                    </Label>
                    <Popup
                        key={key}
                        trigger={<S.Help />}
                        modal
                        on="click"
                        open={isOpen}
                        // onOpen={() => setIsOpen(!isOpen)}
                        // todo: check prev IsOpen
                    >
                        <h3>{key}</h3>
                        <span>{t('D_' + key)}</span>
                        {/*<S.Close onClick={() => setIsOpen(!isOpen)} />*/}
                    </Popup>
                </div>,
            )
        })
        return CheckBoxes
    }

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>{AddOnsCheckBoxes()}</div>
    )
}
export default ChallengeAddOns
