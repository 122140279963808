import { FC } from 'react'
import { Arrow, ItemCost, ItemTitle, ItemWrapper, Logo } from './billingItem.styles'

interface IBillingItem {
    isActive?: boolean
    data: string
    cost: string
    onClick: (e: any) => void
    onKeyPress: (e: any) => void
}

const BillingItem: FC<IBillingItem> = ({ isActive, data, cost, onClick, onKeyPress }) => {
    return (
        <ItemWrapper isActive={isActive} onClick={onClick}>
            <ItemTitle>
                <Logo />
                <p>{data}</p>
            </ItemTitle>

            <ItemCost>
                <p>{cost}</p>
                <Arrow isActive={isActive} />
            </ItemCost>
        </ItemWrapper>
    )
}

export default BillingItem
