import styled from 'styled-components'

export const StyledInitialCapital = styled.div``
export const StyledOptionHeader = styled.h4`
    margin-bottom: 12px;
    height: 21px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    /* 50% text */

    color: ${({ theme }) => theme.colors.textGrey};
`

export const StyledOptionWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
`
export const StyledOption = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-height: 42px;
    padding: 11px 20px;
    //width: 126px; right block Pro Trade ||| width: 144px; // Micro Trader

    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.borderTable};

    background: ${({ theme }) => theme.colors.bgContent};

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.textLink};
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    &.active {
        color: ${({ theme }) => theme.colors.bgContent}; //ex theme.white
        background: ${({ theme }) => theme.colors.textLink};
        border: 1px solid ${({ theme }) => theme.colors.borderTable};
    }
`
export const StyledStartChallenge = styled.div`
    max-width: 555px;
    margin: 0 0 30px 0;
    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};
    border-radius: 15px;
    padding: 20px;
`
