export enum ThemeEnum {
    light = 'light',
    dark = 'dark',
}

export interface ITheme {
    colors: {
        bgPage: string
        bgContent: string
        bgItems: string
        bgInput: string
        bgMenu: string
        bgCurrentMenuItem: string
        bgMsgCounter: string
        bgCurrentTableRow: string
        bgLoginPage: string
        bgStatusGreen: string
        bgStatusYellow: string
        bgStatusRed: string

        bgCompletedStatus: string
        bgFailedStatus: string
        bgAwaitingPaymentStatus: string
        bgInProgressStatus: string

        bgCompletedSwitch: string
        bgFailedSwitch: string
        bgAwaitingPaymentSwitch: string
        bgInProgressSwitch: string

        bgWhiteForQR: string

        textStandart: string
        textMenu: string
        textCurrentItemMenu: string
        textLogOutBtn: string
        textLogo: string
        textBreadCrumbs: string
        textBreadCrumbsCurrent: string
        textLink: string
        textGrey: string
        textPayGrey: string
        textStatusGreen: string

        btnChat: string
        btnLogIn: string
        btnSignIn: string
        bgPromoBanner: string
        bgBtnSignIn: string
        bgBtnLogIn: string

        bgTotal: string
        borderTable: string
    }

    media: {
        extraLarge: string
        large: string
        medium: string
        mobile: string
    }

    durations: {
        // in ms
        ms300: number
    }

    sizes: {
        header: {
            height: number
            width: number
        }
        menu: { width: number }
        container: { width: number }
        footer: {
            height: number
            width: number
        }
    }

    order: {
        // z-index
        header: number
        footer: number
    }
}
