import styled from 'styled-components'
import { devices } from '../../devices'

export const StyledPaymentMethods = styled.div`
    text-align: center;
    width: 100%;
    max-width: 555px;
    box-sizing: content-box;
`

export const StyledPaymentMethod = styled.div`
    position: relative;
    display: inline-block;
    padding: 7px;
    margin-bottom: 15px;
    margin-right: 15px;
    width: 161px;
    height: 159px;
    align-items: stretch;
    background: #ffffff;
    /* Shadow */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
    border-radius: 15px;

    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(7) {
        margin-right: 0;
    }
    &:hover {
        cursor: pointer;
    }
    &:before {
        content: ' ';
        position: absolute;
        display: flex;
        margin: 0 auto;
        width: 139px;
        height: 137px;
        background-position: center;
        background-repeat: no-repeat;
        //background: #fff;
        justify-content: center;
    }
    &:after {
        text-align: left;
    }

    @media ${devices.md} {
        width: 110px;
        height: 108px;
        &:before {
            width: 100px;
            height: 98px;
            background-size: 100%;
        }

        &:nth-child(3),
        &:nth-child(6) {
            margin-right: 8px;
        }
    }
    @media ${devices.sm} {
        margin-bottom: 8px;
        margin-right: 8px;
    }
`
