import { FC, ReactElement, SetStateAction, useState } from 'react'
import AccordionItem from './AccordionItem'

interface IProps {
    headerData: ReactElement[]
    detailData: ReactElement[]
}

export interface handleToggleFunction {
    title: string

    callback(index: SetStateAction<number | null>): void
}

const Accordion: FC<IProps> = ({ headerData, detailData }) => {
    const [active, setActive] = useState<number | null>(null)

    const handleToggle = (index: SetStateAction<number | null>) => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }
    return (
        <>
            {headerData.map((header, index) => {
                return (
                    <AccordionItem
                        active={active}
                        key={index}
                        handleToggle={handleToggle}
                        header={header}
                        detail={detailData[index]}
                        id={index}
                    ></AccordionItem>
                )
            })}
        </>
    )
}
export default Accordion
