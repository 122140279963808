import styled from 'styled-components'

import { devices } from '../../devices'
import warningDoc from '../../img/svg/docs/danger.svg'
import iconDoc from '../../img/svg/docs/docs-logo.svg'

export const ItemWrapper = styled.div`
    max-width: 1140px;
    min-height: 70px;
    border-radius: 15px;
    background: ${({ theme }) => theme.colors.bgContent};
    margin: 15px 30px 15px 30px;
    padding: 10px;

    display: flex;
    flex-flow: row;

    align-items: center;

    justify-content: flex-start;
    @media ${devices.lg} {
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 20px;
    }
    @media ${devices.xs} {
        margin: 15px 0;
    }
`

export const Logo = styled.div`
    min-width: 44px;
    min-height: 44px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    background-image: url(${iconDoc});
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
`

export const Warning = styled.div`
    min-width: 44px;
    min-height: 44px;
    background-image: url(${warningDoc});

    background-position: center;
    background-repeat: no-repeat;

    @media ${devices.lg} {
        min-width: 22px;
        min-height: 22px;
    }
`
export const ItemTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 11px 20px;
    gap: 10px;
    width: 33%;
    height: 44px;

    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};
    margin: 0 10px 0 0;
    border-radius: 10px;
    min-width: 223px;

    > p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    @media ${devices.lg} {
        width: 40%;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
    }
    @media (max-width: 408px) {
        min-width: 184px;
    }
`
interface IItemStatusProps {
    disabled: boolean
}
export const ItemStatus = styled.div<IItemStatusProps>`
    display: flex;
    flex-direction: row;
    width: 28%;
    justify-content: space-between;
    align-items: center;
    /* margin-right: 270px; */

    @media ${devices.lg} {
        display: flex;
        justify-content: space-evenly;
        order: 3;
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
    }
`
export const ButtonWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    @media ${devices.sm} {
        > div > button {
            width: 99px;
            margin-right: 0;
        }
    }
`
export const Column = styled.div`
    & h4 {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.textStandart};
    }
    & a {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.textGrey};
        font-size: 12px;
        text-decoration: none;
        padding-bottom: 8px;
    }
    > p {
        @media ${devices.lg} {
            margin: 8px 0;
        }
    }
`
