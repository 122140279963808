import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { devices } from '../../devices'
import iconBack from '../../img/svg/metrix/icon_back.svg'

export const MetrixWrapper = styled.div`
    padding: 30px 30px 0;
    display: flex;
    flex-direction: column;
`
export const BackToDashboard = styled(NavLink)`
    height: 20px;
    text-decoration: none;
    margin-bottom: 20px;
`
export const BackText = styled.div`
    font-size: 12px;
    line-height: 18px;
    display: flex;
    color: ${({ theme }) => theme.colors.textBreadCrumbsCurrent};

    &:before {
        content: url(${iconBack});
        width: 17px;
        height: 17px;
        border-radius: 50%;
        margin-right: 5px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
`
export const ToogleGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: fit-content;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.bgContent};
    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    margin-bottom: 30px;

    @media ${devices.lg} {
        flex-direction: column;
        align-items: flex-start;
    }
`
export const AccountTag = styled.div`
    color: ${({ theme }) => theme.colors.textBreadCrumbs};
    font-size: 16px;
    line-height: 24px;
    padding-right: 30px;
`
export const AccountNumber = styled.div`
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    @media ${devices.lg} {
        width: 100%;
        justify-content: space-between;
    }
`
export const ToggleButtonGroup = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 20px;

    @media ${devices.lg} {
        width: 100%;
        justify-content: space-between;
    }
`
export const ShareLink = styled.div`
    display: none;

    &.visible {
        display: flex;
        border: 2px solid ${({ theme }) => theme.colors.borderTable};
        border-radius: 10px;
        padding: 10px;
        background: ${({ theme }) => theme.colors.bgPage};
        color: ${({ theme }) => theme.colors.textStandart};
    }
`
export const BtnLabel = styled.div`
    display: none;
    color: ${({ theme }) => theme.colors.textStandart};

    &.visible {
        display: block;
    }
`
export const ShareLinkToggle = styled.div`
    display: flex;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    align-items: center;
    width: 50px;
    height: 25px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.bgBtnSignIn};

    &:after {
        content: '';
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.bgContent};
        position: absolute;
        left: 2px;
        transition: all 0.3s ease 0s;
        cursor: pointer;
    }

    &.active:after {
        left: unset;
        right: 2px;
    }
`

export const ObjectivesTable = styled(ToogleGroup)`
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    overflow-x: auto;
`
export const GroupTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    padding: 20px;
    padding-left: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const BalanceChart = styled.div``
export const Table = styled.table`
    min-width: 500px;
`
export const TableHistory = styled.table`
    width: 100%;
    min-width: 1099px;
`

export const TData = styled.td`
    font-size: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    font-weight: 500;
    padding: 15px 0;

    &.date {
        flex-direction: column;
        align-items: flex-start;
    }

    &:first-child {
        justify-content: left;
        width: 30%;
    }

    &:last-child {
        justify-content: right;
        font-weight: 600;
    }
`
export const TDataHeader = styled(TData)`
    font-size: 12px;
    padding: 0;
`

export const TDataHistory = styled.td`
    font-size: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    font-weight: 500;
    padding: 15px 0;
    overflow-wrap: break-word;
    white-space: normal;

    &.date {
        flex-direction: column;
        align-items: flex-start;
    }

    justify-content: left;

    &:last-child {
        justify-content: right;
    }
    > p {
        margin-left: 5px;
        font-weight: 800;
    }
`
export const TDataHistoryHeader = styled(TDataHistory)`
    font-size: 12px;
    padding: 0;
`

export const TRow = styled.tr`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderTable};

    &:last-child {
        border: none;
    }
`
export const THead = styled.thead`
    font-weight: 500;
    font-size: 12px !important;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.textGrey};

    & > ${TRow} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderTable};
        padding-bottom: 15px;
    }
`
export const Status = styled.div`
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textStatusGreen};
    background-color: ${({ theme }) => theme.colors.bgStatusGreen};
    border-radius: 15px;
    margin-left: auto;

    &.inProgress {
        color: ${({ theme }) => theme.colors.textLink};
        background-color: ${({ theme }) => theme.colors.bgStatusYellow};
    }

    &.failed {
        color: ${({ theme }) => theme.colors.bgMsgCounter};
        background-color: ${({ theme }) => theme.colors.bgStatusRed};
    }
`
export const NumberBold = styled.tbody`
    font-weight: 600;
    padding: 0 3px;
`
export const TBody = styled.tbody`
    width: 100%;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const StatisticGroup = styled(ToogleGroup)`
    flex-direction: column;
`
export const TableGroupRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    @media ${devices.lg} {
        flex-direction: column;
    }
`
export const StatisticTable = styled(ToogleGroup)`
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    margin: 8px;

    & > ${TData} {
        width: 50%;
    }
    @media ${devices.lg} {
        margin: 8px 0;
    }
`
export const HistoryTable = styled(ToogleGroup)`
    flex-direction: column;
    overflow-x: auto;

    & > ${Table} {
        width: 100%;

        &:has(${TData}) {
            justify-content: left;
            align-items: flex-start;
        }
    }
`
