import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import iconCopy from '../../img/svg/copytoclipboard/icon_copytoclipboard.svg'

export const CopyWrapper = styled.text`
    display: flex;
    color: ${({ theme }) => theme.colors.textStandart};
    cursor: pointer;
    &:after {
        content: ' ';
        width: 17px;
        height: 17px;
        margin-left: 10px;
        cursor: pointer;
        background-image: url(${iconCopy});
        background-repeat: no-repeat;
        background-position: center;
        z-index: 50;
    }
`

export const CopyLink = styled(NavLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textStandart};
`

export const Text = styled.p`
    max-width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: all;
`
