import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import * as S from './ContactSupport.styles'
import { useForm } from 'react-hook-form'
import SupportTopicSelect from '../../components/SupportTopicSelect/SupportTopicSelect'
import { useAuth } from '../../hooks/useAuth'
import { ISupportPayload } from '../../DTO/IContactSupport'
import { contactSupportApi } from '../../services/ContactSupportService/ContactSupportService'
import { Error } from '../../ui-kit/Error/Error.styles'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { t } from '../../utils/translate/t'

const ContactSupport: FC<IAppProps> = (props) => {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [sendMessage, response] = contactSupportApi.useSendSupportMessageMutation()
    const form = useForm<ISupportPayload>()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = form
    const onSubmit = (data: ISupportPayload) => {
        if (!user?.id) {
            console.log('User ID not found!')
            return
        }
        const supportMessagePayload: ISupportPayload = {
            id: user?.id!,
            topic: data.topic,
            subject: data.subject,
            message: data.message,
        }
        try {
            sendMessage(supportMessagePayload)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        props.setTitle(t('contact_support'))
    }, [])
    useEffect(() => {
        if (response.isSuccess && !response.data?.status) {
            toast.error('Something wrong. Please, reload page and try again')
        }
        if (response.data?.status) {
            toast.success(t('support_message_sent'))
            setTimeout(() => {
                navigate('/dashboard')
            }, 5000)
        }
    }, [response])

    return (
        <>
            <S.SupportForm onSubmit={handleSubmit(onSubmit)}>
                <S.Label>
                    {t('support_topic')}
                    <SupportTopicSelect register={register} required setValue={form.setValue} />
                    <Error>{errors.topic ? errors.topic?.message : ' '}</Error>
                </S.Label>
                <S.Label>
                    {t('support_subject')}
                    <S.SubjectInput
                        type="text"
                        {...register('subject', {
                            required: t('support_subject_required'),
                        })}
                    />
                    <Error>{errors.subject ? errors.subject?.message : ' '}</Error>
                </S.Label>
                <S.Label>
                    {t('support_message')}
                    <S.MessageTextArea
                        {...register('message', {
                            required: t('support_message_required'),
                        })}
                    />
                    <Error>{errors.message ? errors.message?.message : ' '}</Error>
                </S.Label>
                <S.BtnSend>{t('support_send')}</S.BtnSend>
            </S.SupportForm>
            <ToastContainer />
        </>
    )
}
export default ContactSupport
