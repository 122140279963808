import { FC, useEffect } from 'react'
import { messageAPI } from '../../services/MessageService/MessageService'

interface IMessageID {
    messageID: string
    messageSeen?: string
}

const Seen: FC<IMessageID> = (props) => {
    const [
        trigger,
        {
            isLoading: isSeenLoading,
            isSuccess,
            isError,
            data: seenMessage,
            error: seenMessageError,
        },
    ] = messageAPI.useLazyGetMessageSeenQuery()
    useEffect(() => {
        trigger(props.messageID)
    }, [isSuccess])
    return <></>
}
export default Seen
