// @todo for future
function brokerageTitle() {
    return [
        { symbol: 'FXH', title: 'Foreign Exchange Clearing House' },
        { symbol: 'FCM', title: 'FC Markets', fontSize: '12px' },
        { symbol: 'ICM', title: 'IC Markets' },
        { symbol: 'TRD', title: 'Tradeview' },
    ]
}

function BrokerageService() {
    return {
        brokerage: brokerageTitle(),
    }
}

export default BrokerageService
