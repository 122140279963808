import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ILeaderboardDTO } from '../../../DTO/ILeader'

export const leaderAPI = createApi({
    reducerPath: 'leaderboardAPI',
    baseQuery: fetchBaseQuery({
        prepareHeaders: () => {},
        baseUrl: process.env.REACT_APP_SERVER_URL,
    }),
    endpoints: (build) => ({
        fetchAllLeaders: build.query<ILeaderboardDTO[], string>({
            query: () => ({
                url: `/leaderboard`,
            }),
        }),
        fetchSizedLeaders: build.query<ILeaderboardDTO[], number>({
            query: (size: number) => ({
                url: `/leaderboard/${size}`,
            }),
        }),
    }),
})
