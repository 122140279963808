import React from 'react'
import * as S from './themeToggle.styles'
import { Theme } from '../../App'

export interface IThemeToggle {
    theme: Theme
    setTheme: React.Dispatch<React.SetStateAction<Theme>>
}

const ThemeToggle: React.FC<IThemeToggle> = (props) => {
    const toggleTheme = () => {
        props.setTheme(props.theme === Theme.light ? Theme.dark : Theme.light)
    }
    return (
        <>
            {props.theme === Theme.light ? (
                <S.StyledThemeToggle onClick={toggleTheme} />
            ) : (
                <S.StyledDarkThemeToggle onClick={toggleTheme} />
            )}
        </>
    )
}
export default ThemeToggle
