import styled from 'styled-components'
import { devices } from '../../devices'
import { PageItemWrapper as HeaderWrapper } from '../../helpers/ItemWrapper'
import iconArrowDown from '../../img/svg/header/arrow-down.svg'
import iconAvatar from '../../img/svg/header/icon_user.svg'
import iconLangBtn from '../../img/svg/header/lang_en_us.svg'

export const HeaderWrap = styled(HeaderWrapper)`
    z-index: 20;
    background-color: ${({ theme }) => theme.colors.bgContent};
    display: flex;
    width: 100%;
`
export const Header = styled.header`
    display: flex;
    width: 100%;
    height: ${({ theme }) => theme.sizes.header.height}px;
    align-items: center;

    @media ${devices.md} {
        align-items: baseline;
        height: 130px;
    }

    @media ${devices.md} {
        align-items: baseline;
        height: 130px;
    }
`
export const LogoGroup = styled.div`
    position: fixed;
    z-index: 20;
    background: ${({ theme }) => theme.colors.bgMenu};
    display: flex;
    min-width: 240px;
    align-items: center;
    height: 80px;
    //justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;

    @media ${devices.md} {
        display: none;
    }
`
export const Logo = styled.img`
    padding: 0.2em 0.1em;
    border: 0;
    margin-right: 10px;
`
export const LogoMobile = styled.img`
    border: 0;
    margin-left: 20px;
`
export const Company = styled.span`
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 2.29px;
    color: ${({ theme }) => theme.colors.textLogo};
`
export const BodyHeader = styled.div`
    position: fixed;
    z-index: 50;
    margin-left: 240px;
    display: flex;
    flex: content;
    background-color: ${({ theme }) => theme.colors.bgContent};
    width: calc(100vw - 240px);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;

    @media ${devices.md} {
        margin-left: 0;
        width: 100%;
        margin-top: 50px;
    }
    @media ${devices.xs} {
    }
`
export const BodyHeaderMobile = styled.div`
    position: fixed;
    z-index: 50;
    flex: content;
    background-color: ${({ theme }) => theme.colors.bgMenu};
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    display: none;
    @media ${devices.md} {
        display: flex;
        margin-left: 0;
        width: 100%;
    }
`
export const HeaderRow = styled.div`
    display: flex;
    flex-direction: column;
    min-width: min-content;
    padding-top: 10px;
    width: 30vw;

    @media ${devices.md} {
        width: 100vw;
        padding-top: 2px;
    }
`
export const PageName = styled.p`
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const LogoWrapper = styled.div`
    display: flex;
`
export const ActivePage = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textBreadCrumbsCurrent};
`
export const OtherPage = styled(ActivePage)`
    color: ${({ theme }) => theme.colors.textBreadCrumbs};
    cursor: pointer;
    font-weight: 500;

    &:after {
        content: '>';
        width: 10px;
        height: 10px;
        margin: 0 5px;
    }
`
export const BreadCrumbs = styled.div`
    display: flex;
    flex-direction: row;
`
export const HeaderButtons = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 100%;

    @media ${devices.md} {
        display: none;
    }
`
export const HeaderButtonsMobile = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 100%;
    margin-right: 20px;
`
export const LanguageBtn = styled.div`
    background-image: url(${iconLangBtn});
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin: 0 10px;
    background-position: center;
    background-size: cover;
`
export const ProfileDropdown = styled.div`
    margin: 0 10px;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const ProfileAvatar = styled.div`
    background-color: ${({ theme }) => theme.colors.textStandart};
    -webkit-mask-image: url(${iconAvatar});
    mask-image: url(${iconAvatar});

    min-width: 30px;
    min-height: 35px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    @media ${devices.md} {
        background-color: ${({ theme }) => theme.colors.bgItems};
    }
`
export const ProfileName = styled.span`
    font-family: 'Montserrat', serif;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textStandart};
    margin-left: 14px;
    white-space: nowrap;

    &:after {
        content: ' ';
        color: ${({ theme }) => theme.colors.textBreadCrumbs};
        background-image: url(${iconArrowDown});
        width: 13px;
        height: 8px;
        margin-left: 15px;
        display: inline-block;
    }
`

interface IStyledBurgerProps {
    open: boolean
}

export const BurgerWrap = styled.div`
    display: none;
    @media ${devices.md} {
        display: flex;
        align-items: center;
        margin-left: 15px;
        position: relative;
        min-width: 30px;
        min-height: 30px;
        z-index: 1;
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.textCurrentItemMenu};
        border-radius: 50%;
    }
`
export const StyledBurger = styled.button`
    margin-left: ${(p: IStyledBurgerProps) => (p.open ? '9px' : '7px')};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 15px;
    height: 15px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 1rem;
        height: 0.15rem;
        background: ${({ theme }) => theme.colors.bgMenu};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${(p: IStyledBurgerProps) => (p.open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${(p: IStyledBurgerProps) => (p.open ? '0' : '1')};
            transform: ${(p: IStyledBurgerProps) =>
                p.open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${(p: IStyledBurgerProps) => (p.open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`

export const BurgerBtn = styled.div`
    display: none;
    @media (max-width: 1000px) {
        display: flex;
        align-items: center;
        margin-left: 15px;
        position: relative;
        min-width: 30px;
        min-height: 30px;
        z-index: 1;
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.textCurrentItemMenu};
        border-radius: 50%;
    }
`
export const BtnLine = styled.div`
    @media ${devices.md} {
        display: block;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.bgMenu};
        width: 100%;
        height: 2px;
        top: 6px;
        transition: all 0.3s ease 0s;
    }
`
export const Cross = styled.div`
    @media ${devices.md} {
        position: relative;
        width: 15px;
        margin: 0 auto;
        height: 15px;
        z-index: 1;

        &:before,
        &:after {
            content: '';
            background-color: ${({ theme }) => theme.colors.bgMenu};
            position: absolute;
            width: 100%;
            height: 2px;
            transition: all 0.3s ease 0s;
        }

        &:before {
            top: 1px;
        }

        &:after {
            bottom: 2px;
        }

        &.cross > ${BtnLine} {
            display: none;
        }

        &.cross:after {
            transform: rotate(45deg);
            bottom: 7px;
        }

        &.cross:before {
            transform: rotate(-45deg);
            top: 6px;
        }
    }
`
