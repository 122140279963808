import { FC, useEffect, useState } from 'react'
import { IAppProps } from '../../App'
import { IGetOrdersResponse } from '../../DTO/IGetOrdersResponse'
import BillingList from '../../components/BillingList/BillingList'
import { useAuth } from '../../hooks/useAuth'
import { billingAPI } from '../../services/BillingService/BillingService'
import { t } from '../../utils/translate/t'

const Billing: FC<IAppProps> = (props) => {
    const [billingItemData, setBillingItemData] = useState<IGetOrdersResponse[]>([
        {
            AccountID: '',
            Broker: '',
            Platform: '',
            Spreads: '',
            Type: '',
            Risk: '',
            SalesPrice: '',
            Ordered: '',
            Status: '',
            Payment: '',
        },
    ])
    useEffect(() => {
        props.setTitle(t('billing'))
    }, [])
    const { user } = useAuth()
    const {
        data: orders,
        isError,
        isLoading,
        isSuccess,
        error,
    } = billingAPI.useGetOrdersQuery(Number(user?.id || 0))

    useEffect(() => {
        if (orders && orders.length) {
            const filteredOrders = orders.filter((order) => Number(order.SalesPrice) > 0)
            setBillingItemData(filteredOrders)
        }
    }, [isSuccess])

    return (
        <div>
            {/* <BillingChart /> */}
            {isSuccess && billingItemData.length > 0 && <BillingList orders={billingItemData} />}
        </div>
    )
}

export default Billing

// const BillingChart: React.FC = () => {
//   return (
//     <>
//       <BillingChartStyles />
//     </>
//   );
// };
