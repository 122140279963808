import { createSlice } from '@reduxjs/toolkit'
import { PaymentMethodsEnum } from '../components/PaymentMethods/PaymentMethods'
import { IRisks } from '../DTO/IProgram'
import { getPrettierMoney, IPrice } from '../services/ChallengeService/ChallengeService'
import { TypeRootState } from '../store/store'

interface IValueName {
    value: string
    name: string
}

interface ITraderType extends IValueName {
    key: string
}

export interface IRiskState extends IValueName {}

interface ISpreads extends IValueName {}

interface IPlatforms extends IValueName {}

export interface IBrokers extends IValueName {}

export interface IBillingField {
    country?: string
    first_name?: string
    last_name?: string
    email?: string
    phone?: string
    city?: string
    legal_address?: string
    zip_code?: string
    promo?: string
}

export interface IBillingFieldCompany extends IBillingField {
    company_name?: string
    company_reg_nr?: string
    company_tax_nr?: string
}

export interface IInitialCapital extends IValueName {}

export interface IChallengeState {
    price: number
    traderType: ITraderType
    risk: IRiskState
    initialCapital: IInitialCapital
    optionsTraderTypes: string
    optionsInitialCapital: IInitialCapital[][]
    platform: IPlatforms
    broker: IBrokers
    spreads: ISpreads
    optionsPlatforms: IPlatforms[]
    optionsBrokers: IBrokers[]
    optionsPrices: IPrice[]
    optionsRisks: IRisks[][]
    privateField: IBillingField
    corporateField: IBillingFieldCompany
    paymentMethod: PaymentMethodsEnum
    activeBillingInfo: 'Private' | 'Corporate'
    privatePromo: IPromo
    corporatePromo: IPromo
}

export interface IChallState {
    challenge: IChallengeState
}

interface IPromo {
    code: string
    valid: boolean | null
    error: boolean
    isEmpty: boolean
}

const initialState: IChallState = {
    challenge: {
        price: 199,
        spreads: { value: '', name: '' },
        traderType: { value: '1', key: 'micro', name: '' },
        risk: { value: '2', name: '' },
        initialCapital: { value: '15000', name: getPrettierMoney(15000) },
        optionsInitialCapital: [],
        optionsPrices: [],
        optionsTraderTypes: '[]',
        optionsRisks: [
            // { value: '2', name: 'Normal' },
            // { value: '3', name: 'Aggressive' },
        ],
        platform: { value: '4', name: 'MetaTrader 4' },
        optionsPlatforms: [
            // 4
            // 5
        ],
        broker: { value: '', name: '' },
        optionsBrokers: [],
        privateField: {
            first_name: 'Alex',
            last_name: 'Kim',
            email: 'superherokim2020@gmail.com',
            phone: '+37256173321',
            city: 'Tallinn',
            legal_address: 'Narva mnt 1',
            zip_code: '10110',
            country: 'UA',
            promo: '',
        },
        corporateField: {
            company_name: 'ABC+ OU',
            company_reg_nr: '1212313',
            company_tax_nr: 'vsd4334',
            first_name: 'Alex',
            last_name: 'Kim',
            email: 'superherokim2020@gmail.com',
            phone: '+37256173321',
            city: 'Tallinn',
            legal_address: 'Narva mnt 1',
            zip_code: '10110',
            country: 'UA',
            promo: '',
        },
        paymentMethod: PaymentMethodsEnum.Decta,
        activeBillingInfo: 'Private',
        privatePromo: {
            code: '',
            valid: true,
            error: false,
            isEmpty: true,
        },
        corporatePromo: {
            code: '',
            valid: true,
            error: false,
            isEmpty: true,
        },
    },
}

const challengeSlice = createSlice({
    name: 'challenge',
    initialState,
    reducers: {
        traderTypeChange(state, action) {
            state.challenge.traderType = action.payload.traderType
        },
        optionsTraderTypes(state, action) {
            state.challenge.optionsTraderTypes = action.payload.optionsTraderTypes
        },
        initialCapitalChange(state, action) {
            state.challenge.initialCapital = action.payload.initialCapital
        },
        optionsInitialCapital(state, action) {
            state.challenge.optionsInitialCapital = action.payload.optionsInitialCapital
        },
        platformChange(state, action) {
            state.challenge.platform = action.payload.platform
        },
        riskChange(state, action) {
            state.challenge.risk = action.payload.risk
        },
        optionsRisks(state, action) {
            state.challenge.optionsRisks = action.payload.optionsRisks
        },
        spreadsChange(state, action) {
            state.challenge.spreads = action.payload.spreads
        },
        optionsPlatforms(state, action) {
            state.challenge.optionsPlatforms = action.payload.optionsPlatforms
        },
        optionPrices(state, action) {
            state.challenge.optionsPrices = action.payload.optionsPrices
        },
        priceChange(state, action) {
            state.challenge.price = action.payload.price
        },
        privateFieldChange(state, action) {
            state.challenge.privateField = action.payload.privateField
        },
        corporateFieldChange(state, action) {
            state.challenge.corporateField = action.payload.corporateField
        },
        brokerChange(state, action) {
            state.challenge.broker = action.payload.broker
        },
        optionBrokers(state, action) {
            state.challenge.optionsBrokers = action.payload.optionsBrokers
        },
        activeBillingInfoChange(state, action) {
            state.challenge.activeBillingInfo = action.payload
        },
        paymentMethodChange(state, action) {
            state.challenge.paymentMethod = action.payload
        },
        privatePromoChange(state, action) {
            state.challenge.privatePromo = action.payload
        },
        corporatePromoChange(state, action) {
            state.challenge.corporatePromo = action.payload
        },
    },
})

export const selectAllChallenge = (state: TypeRootState) => state.challenge

export const {
    traderTypeChange,
    optionsTraderTypes,
    initialCapitalChange,
    optionsInitialCapital,
    platformChange,
    riskChange,
    optionsRisks,
    optionsPlatforms,
    spreadsChange,
    optionPrices,
    priceChange,
    privateFieldChange,
    corporateFieldChange,
    brokerChange,
    optionBrokers,
    activeBillingInfoChange,
    paymentMethodChange,
    privatePromoChange,
    corporatePromoChange,
} = challengeSlice.actions
export default challengeSlice.reducer
