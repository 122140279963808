import styled from 'styled-components'
import { devices } from '../../devices'

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media ${devices.xxl} {
        flex-direction: column;
    }
`

export const ElementsWrapper = styled.div`
    width: 555px;
    height: auto;
    background-color: ${({ theme }) => theme.colors.bgContent};
    border-radius: 15px;
    margin: 30px 10px 30px 0px;
    padding: 20px;

    @media ${devices.xxl} {
        margin: 30px 0;
        max-width: 100%;
    }
    @media ${devices.md} {
        max-width: 100%;
    }
`
