import { DevTool } from '@hookform/devtools'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../../hooks/useAuth'
import { changePasswordAPI } from '../../../services/ChangePasswordService/ChangePasswordService'
import Password from '../../../ui-kit/Password/Password'
import { ToastConfig } from '../../../ui-kit/Toast/Toast.config'
import { t } from '../../../utils/translate/t'
import * as S from '../../PrivateBillingInfo/privateBillingInfo.styles'

export type FormValues = {
    id: number
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

const ChangePasswordForm: React.FC = () => {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [canSubmit, setCanSubmit] = useState(false)
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [changePassword, response] = changePasswordAPI.useChangePasswordMutation()

    const form = useForm<FormValues>({
        defaultValues: {
            id: 0,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    })

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = form

    useEffect(() => {
        if (response?.data?.ok === 1) {
            setCanSubmit(!canSubmit)
        }

        reset({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    }, [response])
    const onSubmit = (data: FormValues) => {
        if (!user?.id) {
            console.log('User id not found')
            toast.error(t('error_something_wrong'), ToastConfig)
            return
        }
        const changePasswordPayload = {
            id: +user?.id,
            old_password: data.oldPassword,
            new_password: data.newPassword,
        }
        try {
            changePassword(changePasswordPayload).unwrap()
            setCanSubmit(!canSubmit)
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
            toast.error(t('error_something_wrong'), ToastConfig)
            // @todo check error msg (401 404 400)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <Password
                                passwordInput={oldPass}
                                setPasswordInput={setOldPass}
                                style={{
                                    width: '100%',
                                    margin: 0,
                                    height: '44px',
                                }}
                                label={'Old Password'}
                                name={'oldPassword'}
                                register={register}
                                errors={errors}
                            />
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <Password
                                passwordInput={newPass}
                                setPasswordInput={setNewPass}
                                style={{
                                    width: '100%',
                                    margin: 0,
                                    height: '44px',
                                }}
                                label={'New Password'}
                                name={'newPassword'}
                                register={register}
                                errors={errors}
                            />
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <Password
                                passwordInput={confirmPassword}
                                setPasswordInput={setConfirmPassword}
                                style={{
                                    width: '100%',
                                    margin: 0,
                                    height: '44px',
                                }}
                                label={'New Password Again'}
                                name={'confirmPassword'}
                                register={register}
                                errors={errors}
                            />
                        </S.FormInputWrapper>
                    </S.Column>
                </S.Wrapper>

                <DevTool control={control} />

                <S.ButtonWrapper>
                    <S.ColumnReverse>
                        <S.ResetBtn
                            type={'button'}
                            onClick={() =>
                                reset({
                                    oldPassword: '',
                                    newPassword: '',
                                    confirmPassword: '',
                                })
                            }
                        >
                            Reset
                        </S.ResetBtn>
                    </S.ColumnReverse>

                    <S.ColumnReverse>
                        <S.SubmitBtn disabled={canSubmit}>Save Changes</S.SubmitBtn>
                    </S.ColumnReverse>
                </S.ButtonWrapper>
            </form>
            <ToastContainer />
        </>
    )
}
export default ChangePasswordForm
