import React, { FC, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Error, StyledLabel } from '../../components/PrivateBillingInfo/privateBillingInfo.styles'
import * as S from '../../pages/Login/login.styles'
import { t } from '../../utils/translate/t'

export enum PasswordInputType {
    password = 'password',
    text = 'text',
}

interface IProps {
    passwordInput: string
    setPasswordInput: React.Dispatch<React.SetStateAction<string>>
    style?: any
    label?: string
    loginPage?: boolean
    register: UseFormRegister<any>
    name: string
    errors?: any
}

const Password: FC<IProps> = (props) => {
    const [passwordType, setPasswordType] = useState(PasswordInputType.password)
    const togglePasswordVisibility = () => {
        setPasswordType(
            passwordType === PasswordInputType.password
                ? PasswordInputType.text
                : PasswordInputType.password,
        )
    }
    const Label = props.loginPage ? (
        <S.Label htmlFor={props.label || 'loginPassword'}>{props.label || 'Password'}</S.Label>
    ) : (
        <StyledLabel htmlFor={props.label || 'loginPassword'}>
            {props.label || 'Password'}
        </StyledLabel>
    )
    return (
        <>
            {Label}
            <S.InputWrapper page={props.loginPage ? 'loginPage' : ''}>
                <S.InputPassword
                    id={props.label || 'loginPassword'}
                    style={props.style}
                    type={passwordType}
                    page={props.loginPage ? 'loginPage' : ''}
                    {...props.register(props.name, {
                        required: props.label + ' ' + t('required') ,
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props.setPasswordInput(e.target.value)
                    }
                    value={props.passwordInput}
                    placeholder={t('type_here')}
                />
                <S.ShowButton onClick={togglePasswordVisibility}>
                    {passwordType === PasswordInputType.password ? (
                        <S.EyeIcon />
                    ) : (
                        <S.EyeIconCross />
                    )}
                </S.ShowButton>
                <Error>{!!props.errors[props.name]}</Error>
                <S.PasswordInputError page={props.loginPage ? 'loginPage' : ''}>
                    {props?.errors[props.name]?.message}
                </S.PasswordInputError>
            </S.InputWrapper>
        </>
    )
}
export default Password
