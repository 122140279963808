export const instrumentsData = [
    {
        title: 'instruments_forex',
        items: [
            'AUDCAD',
            'AUDCHF',
            'AUDHUF',
            'AUDJPY',
            'AUDNZD',
            'AUDSEK',
            'AUDSGD',
            'AUDUSD',
            'CADCHF',
            'CADJPY',
            'CHFHUF',
            'CHFJPY',
            'CHFNOK',
            'EURAUD',
            'EURCAD',
            'EURCHF',
            'EURCZK',
            'EURDKK',
            'EURGBP',
            'EURHUF',
            'EURILS',
            'EURJPY',
            'EURMXN',
            'EURNOK',
            'EURNZD',
            'EURPLN',
            'EURSEK',
            'EURUSD',
            'EURZAR',
            'GBPAUD',
            'GBPCAD',
            'GBPCHF',
            'GBPDKK',
            'GBPHUF',
            'GBPJPY',
            'GBPNOK',
            'GBPNZD',
            'GBPPLN',
            'GBPTRY',
            'GBPUSD',
            'NOKSEK',
            'NZDCAD',
            'NZDCHF',
            'NZDHUF',
            'NZDJPY',
            'NZDUSD',
            'SGDJPY',
            'USDBRL',
            'USDCAD',
            'USDCHF',
            'USDCLP',
            'USDCNH',
            'USDCZK',
            'USDDKK',
            'USDHKD',
            'USDHUF',
            'USDILS',
            'USDJPY',
            'USDMXN',
            'USDNOK',
            'USDPLN',
            'USDSEK',
            'USDSGD',
            'USDZAR',
        ],
    },
    {
        title: 'instruments_metals',
        items: ['XAGUSD', 'XAUUSD'],
    },
    {
        title: 'instruments_stocks',
        items: [
            'AA',
            'AAPL',
            'ADBE',
            'AMZN',
            'AXP',
            'BA',
            'BABA',
            'BAC',
            'C',
            'CAT',
            'CMCSA',
            'COST',
            'CSCO',
            'CVX',
            'DELTA',
            'DIS',
            'EBAY',
            'GE',
            'GOOG',
            'GS',
            'HD',
            'HPQ',
            'IBM',
            'INTC',
            'JNJ',
            'JPM',
            'KO',
            'MCD',
            'META',
            'MMM',
            'MO',
            'MRK',
            'MSFT',
            'NFLX',
            'NKE',
            'NVDA',
            'ORCL',
            'PEP',
            'PFE',
            'PG',
            'QCOM',
            'RACE',
            'SBUX',
            'SYMC',
            'T',
            'TSLA',
            'TWTR',
            //'UTX',
            'V',
            'VZ',
            'WMT',
            'WU',
            'XOM',
            'YNDX',
        ],
    },
    {
        title: 'instruments_indices',
        items: [
            'AUS200',
            'CAC40',
            'DAX40',
            'HK50',
            'JP225',
            'SPA35',
            'STOXX50',
            'UK100',
            'US100',
            'US30',
            'US500',
        ],
    },
    {
        title: 'instruments_commodities',
        items: ['UKOIL', 'USOIL'],
    },
    {
        title: 'instruments_crypto',
        items: [
            'BATUSD',
            'BCHUSD',
            'BNBUSD',
            'BTCUSD',
            'DSHUSD',
            'EOSUSD',
            'ETCUSD',
            'ETHUSD',
            'IOTUSD',
            'LTCUSD',
            'NEOUSD',
            'OMGUSD',
            'TRXUSD',
            'XLMUSD',
            'XMRUSD',
            'XRPUSD',
            'ZECUSD',
        ],
    },
]
