import { FC } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'
import Select from 'react-select'
import { useTheme } from 'styled-components'
import { SignInFormValues } from '../../pages/Signup/Signup'
import { t } from '../../utils/translate/t'

type SelectOptionType = { label: string; value: string }

const titles = [
    { value: 'MR', label: 'Mr.' },
    { value: 'MRS', label: 'Mrs.' },
    { value: 'MS', label: 'Ms.' },
    { value: 'MX', label: 'Mx.' },
]

interface IProps {
    setValue: UseFormSetValue<SignInFormValues>
    register: UseFormRegister<SignInFormValues>
    required: boolean
    defaultValue?: string
}

const TitleSelect: FC<IProps> = (props) => {
    const theme = useTheme()
    const customStyles: any = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: theme.colors.bgMenu,
            border: state.isSelected ? '0' : '0',
            color: theme.colors.textGrey,
            boxShadow: 'none',
            borderRadius: '10px',
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textCurrentItemMenu,
            fontSize: '14px',
            fontWeight: 400,
            fontStyle: 'italic',
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textMenu,
            cursor: 'pointer',
            backgroundColor: state.isSelected ? theme.colors.borderTable : theme.colors.bgMenu,
            fontSize: '14px',
            padding: '10px',
        }),
        menuList: (base: any, state: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    }

    const changeHandler = (option: SelectOptionType | null) => {
        props.setValue('countryResidence', option?.value.toUpperCase() || '')
    }

    let requiredOption = {}
    if (props.required) {
        requiredOption = {
            required: `${t('title')} ${t('required')}`,
        }
    }
    return (
        <>
            <Select
                {...props.register('title', requiredOption)}
                styles={customStyles}
                options={titles}
                onChange={changeHandler}
            />
        </>
    )
}

/// @todo RM after use EXAMPLE:

/*<Selector
    placeholder="Title"
    autoFocus={true}
    value={title}
    setValue={setTitle}
    options={titles}
    labels={labels}
/>*/
export default TitleSelect
