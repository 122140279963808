import { FC } from 'react'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import { IAccount } from '../../services/AccountServise/AccountService'
import CopyToClipboard from '../../ui-kit/CopyToClipboard/CopyToClipboard'
import { currencyFormat } from '../../utils/currencyFormat'
import { t } from '../../utils/translate/t'
import * as S from './phaseDetail.styles'

interface IPhaseDetail {
    token: string | null
    accountData: IAccount
}

const Risks = new Map()
Risks.set('2', t('normal'))
Risks.set('3', t('aggressive'))
const Platforms = new Map()
Platforms.set('4', 'Metatrader4')
Platforms.set('5', 'Metatrader5')
const Spreads = new Map()
Spreads.set('r', 'Raw')
Spreads.set('s', 'Standard')

const PhaseDetail: FC<IPhaseDetail> = ({ token, accountData }) => {
    const capital = currencyFormat(+accountData.Type, 'USD')
    const risk = Risks.get(accountData.Risk) ? Risks.get(accountData.Risk) : 'Error'
    const platform = Platforms.get(accountData.Platform)
        ? Platforms.get(accountData.Platform)
        : 'Error'
    const spreads = Spreads.get(accountData.Spreads) ? Spreads.get(accountData.Spreads) : 'Error'

    return (
        <>
            <S.Wrap>
                <SubHeader>
                    {t('account')} {accountData.AccountID || 58047}
                </SubHeader>
                <S.PhaseTable>
                    <S.Tr>
                        <S.Td>{t('capital')}</S.Td>
                        <S.Td>{capital}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('risk')}</S.Td>
                        <S.Td>{risk}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('broker')}</S.Td>
                        <S.Td>{accountData.Broker}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('platform')}</S.Td>
                        <S.Td>{platform}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('spreads')}</S.Td>
                        <S.Td>{spreads}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('payment_method')}</S.Td>
                        <S.Td>{accountData.Payment}</S.Td>
                    </S.Tr>
                </S.PhaseTable>
            </S.Wrap>
            <S.Wrap>
                <SubHeader>{t('credentials')}</SubHeader>
                <S.CredentialsTable>
                    <S.Tr>
                        <S.Td>{t('login')}</S.Td>
                        <S.Td>
                            <CopyToClipboard>{accountData.Login}</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('password')}</S.Td>
                        <S.Td>
                            <CopyToClipboard>{accountData.Password}</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('server')}</S.Td>
                        <S.Td>
                            <CopyToClipboard>{accountData.Server}</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                </S.CredentialsTable>
                <S.TermUseBtn
                    target="_blank"
                    rel="noreferrer"
                    to={`https://trade.fidelcrest.com/api3/tos6.php?id=${accountData.AccountID}&amp;token=${token}`}
                >
                    {t('agree_tos')}
                </S.TermUseBtn>
                <S.Metrix to={`/metrix/${accountData.AccountID}`}>{t('metrix')}</S.Metrix>
            </S.Wrap>
        </>
    )
}
export default PhaseDetail
