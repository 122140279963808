import React, { FC } from 'react'
import Select from 'react-select'
import { useTheme } from 'styled-components'

type SelectOptionType = { label: string; value: number }

const allSizes = 'All sizes'
const options: SelectOptionType[] = [
    { label: '$10,000', value: 10000 },
    { label: '$15,000', value: 15000 },
    { label: '$25,000', value: 25000 },
    { label: '$30,000', value: 30000 },
    { label: '$50,000', value: 50000 },
    { label: '$60,000', value: 60000 },
    { label: '$120,000', value: 120000 },
    { label: '$150,000', value: 150000 },
    { label: '$250,000', value: 250000 },
    { label: '$500,000', value: 500000 },
    { label: '$1,000,000', value: 1000000 },
    { label: allSizes, value: 0 },
]

interface IAccountSizeSelectProps {
    accountSize: number
    setAccountSize: React.Dispatch<React.SetStateAction<number>>
}

export const AccountSizeSelect: FC<IAccountSizeSelectProps> = ({ accountSize, setAccountSize }) => {
    const theme = useTheme()
    const changeHandler = (option: SelectOptionType | null) => {
        setAccountSize(option?.value || 0)
    }
    const currentOption = options.filter((value) => {
        return value.value === accountSize
    })[0]
    const customStyles: any = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderRadius: '10px',
            width: '150px',
            boxShadow: 'none',
            backgroundColor: theme.colors.bgContent,
            border: '1px solid ' + theme.colors.textGrey,
            padding: 0,
            fontSize: '14px',
            color: theme.colors.textStandart,
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textStandart,
            backgroundColor: state.isSelected
                ? 'rgba(189 , 197, 209, 0.9)'
                : theme.colors.bgContent,
            fontSize: '14px',
            padding: '.5rem 3rem .5rem .5rem',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textStandart,
            fontSize: '14px',
            fontWeight: 400,
        }),
        menuList: (base: any, state: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    }

    return (
        <>
            <label htmlFor="">Account Size: </label>
            <Select
                options={options}
                styles={customStyles}
                isSearchable
                onChange={changeHandler}
                defaultValue={currentOption}
            />
        </>
    )
}
