import { FC, ReactElement } from 'react'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import { IInstrumentsProps } from '../../pages/Instruments/Instruments'
import { t } from '../../utils/translate/t'
import { instrumentsData } from '../Data/instrumentsData'
import * as S from './filterInstruments.styles'

const FilterInstruments: FC<IInstrumentsProps> = (props) => {
    const getCategories = () => {
        let elements: ReactElement[] = []
        instrumentsData.map((category, index) => {
            elements.push(
                <S.Category
                    key={index}
                    className={index == props.category ? 'active' : ''}
                    onClick={() => {
                        props.setCategory(index)
                        props.setSymbol('')
                    }}
                >
                    {t(category.title)}
                </S.Category>,
            )
        })
        return elements
    }

    return (
        <>
            <S.FilterInstruments>
                <SubHeader>{t('categories')}</SubHeader>
                <S.CategoriesList>{getCategories()}</S.CategoriesList>
            </S.FilterInstruments>
        </>
    )
}
export default FilterInstruments
