import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { IAppProps } from '../../App'
import { instrumentsData } from '../../components/Data/instrumentsData'
import FilterInstruments from '../../components/FilterInstruments/FilterInstruments'
import { CapitalizeSubHeader } from '../../layouts/MainElements/mainElements.styles'
import { Back, Instrument, InstrumentsBlock, InstrumentsList } from './instruments.styles'

import { useTheme } from 'styled-components'
import { t } from '../../utils/translate/t'

export interface IInstrumentsProps {
    category: number
    setCategory: Dispatch<SetStateAction<number>>
    symbol: string
    setSymbol: Dispatch<SetStateAction<string>>
}

export const clearTitle = (t: string): string => {
    return t.replace('instruments_', '')
}

const Content: FC<IInstrumentsProps> = (props) => {
    const theme = useTheme()
    let srcAdvancedWidget = 'https://s.tradingview.com/widgetembed/'
    let srcMiniWidget = 'https://s.tradingview.com/embed-widget/mini-symbol-overview/'
    let srcCompanyProfileWidget = 'https://s.tradingview.com/embed-widget/symbol-profile/'
    let miniWidgetParams = `?symbol=${props.symbol}&colorTheme${theme.type}`
    let advancedWidgetParams = `?symbol=${props.symbol}&theme${theme.type}&autosize=true&interval=D`
    return (
        <div style={{ width: '100%' }}>
            <Back onClick={() => props.setSymbol('')}>
                <span>{props.symbol}</span>
                <div>
                    <span className="material-icons-outlined">{t('back_to_instruments')}</span>
                </div>
            </Back>
            <iframe
                title="market-overview"
                allowTransparency
                frameBorder="0"
                style={{
                    boxSizing: 'border-box',
                    display: 'block',
                    height: '200px',
                    width: '100%',
                    marginBottom: '30px',
                }}
                src={srcMiniWidget + miniWidgetParams}
            ></iframe>
            {[0, 3, 5].indexOf(props.category) > -1 ? (
                <iframe
                    title="market-overview"
                    allowTransparency
                    frameBorder="0"
                    style={{
                        boxSizing: 'border-box',
                        display: 'block',
                        height: '370px',
                        width: '100%',
                        marginBottom: '30px',
                    }}
                    src={srcAdvancedWidget + advancedWidgetParams}
                ></iframe>
            ) : (
                <iframe
                    title="market-overview"
                    allowTransparency
                    frameBorder="0"
                    style={{
                        boxSizing: 'border-box',
                        display: 'block',
                        height: '200px',
                        width: '100%',
                        marginBottom: '30px',
                    }}
                    src={srcCompanyProfileWidget + miniWidgetParams}
                ></iframe>
            )}
        </div>
    )
}

const Instruments: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle('Instruments')
    }, [])

    const [category, setCategory] = useState(0)
    const [symbol, setSymbol] = useState('')
    const params = { category, setCategory, symbol, setSymbol }

    const getInstruments = (Instruments: string[], props: IInstrumentsProps): ReactElement[] => {
        let items: ReactElement[] = []
        Instruments.forEach((instrumentTitle: string, index) => {
            items.push(
                <Instrument key={index} onClick={() => props.setSymbol(instrumentTitle)}>
                    {instrumentTitle}
                </Instrument>,
            )
        })
        return items
    }

    const items = instrumentsData[category].items
    const titleInstrumentsList = clearTitle(instrumentsData[category].title)
    return (
        <>
            <FilterInstruments {...params} />
            <InstrumentsBlock>
                <CapitalizeSubHeader>{titleInstrumentsList} Instruments</CapitalizeSubHeader>
                {symbol === '' ? (
                    <InstrumentsList>{getInstruments(items, { ...params })}</InstrumentsList>
                ) : (
                    <Content {...params} />
                )}
            </InstrumentsBlock>
        </>
    )
}

export default Instruments
