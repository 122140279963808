import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'
import { ICompetitionDTO } from '../../DTO/ICompetition'

export const competitionAPI = createApi({
    reducerPath: 'competitionAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getCompetition: build.query<ICompetitionDTO[], void>({
            query: () => ({
                url: '/competition',
            }),
        }),
    }),
})
