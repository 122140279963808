import styled from 'styled-components'
import { devices } from '../../devices'
import validatePromocode from '../../img/svg/verified.svg'

export const StyledInput = styled.input`
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px;
    gap: 10px;

    max-width: 250px;
    max-height: 42px;
    width: 100%;

    background: ${({ theme }) => theme.colors.bgPage};
    color: ${({ theme }) => theme.colors.textStandart};
    border-radius: 10px;
    position: relative;

    @media ${devices.xs} {
        max-width: 100%;
    }

    @media (max-width: 850px) {
        width: 100%;
        min-width: 164px;
    }
`
export const StyledPromoCodeInput = styled(StyledInput)`
    width: 100%;
    max-width: 330px;
    @media ${devices.xs} {
        max-width: 100%;
    }
`
export const FormInputWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    width: 100%;
`
export const Error = styled.span`
    position: absolute;
    color: ${({ theme }) => theme.colors.bgMsgCounter};
    font-size: 12px;
    text-align: left;
    bottom: -15px;
    left: 0;
`

export const ErrorChangePassword = styled(Error)`
    top: 68px;
`
export const ErrorBillingInfo = styled(Error)`
    top: 89px;
`

export const StyledLabel = styled.label`
    width: 68px;
    height: 18px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.textStandart};
    margin: 18px 0 10px;

    white-space: nowrap;
`

interface IPromoLabelProps {
    isHidden?: boolean
}

export const PromoLabel = styled(StyledLabel)<IPromoLabelProps>`
    display: block;
    width: 100%;
    max-width: 330px;
    @media ${devices.xs} {
        max-width: 100%;
    }
    position: relative;
    height: 48px;

    &:after {
        position: absolute;
        content: ' ';
        display: ${(props) => (props?.isHidden ? 'block' : 'none')};
        background: url(${validatePromocode});
        background-size: contain;
        width: 32px;
        height: 32px;
        right: 10px;
        top: 22px;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
    gap: 20px;

    @media ${devices.xs} {
        flex-direction: column;
    }
`
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
    gap: 20px;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 164px;
`
export const ColumnReverse = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-content: space-between;
`

export const ResetBtn = styled.button`
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    width: 102px;
    height: 48px;

    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.bgCurrentMenuItem};

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
    vertical-align: center;
    margin-top: 15px;
`
export const SubmitBtn = styled.button.attrs({ type: 'submit' })`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    flex: none;
    order: 0;
    flex-grow: 0;

    min-width: 107px;
    height: 42px;
    padding: 11px 20px;
    gap: 15px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: ${({ theme }) => theme.colors.bgContent};
    background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    border: none;
    border-radius: 10px;
    vertical-align: center;
    &:disabled {
        pointer-events: none;
        background-color: ${({ theme }) => theme.colors.borderTable};
    }
`

export const BillingInfoCountySelectWrap = styled.div`
    min-width: 250px;

    @media ${devices.lg} {
        min-width: 250px;
    }
    @media (max-width: 850px) {
        min-width: 164px;
    }
`
