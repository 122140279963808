import { useEffect } from 'react'
import { IAppProps } from '../../App'
import ChoosePayment from '../../components/ChoosePayment/ChoosePayment'
import { t } from '../../utils/translate/t'
import { Container, TotalPaymentChartStyles } from './BankingDetails.styles'

const BankingDetails: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('banking_details'))
    }, [])

    return (
        <Container>
            <TotalPaymentChart />
            <ChoosePayment />
        </Container>
    )
}

export default BankingDetails

const TotalPaymentChart: React.FC = () => {
    return (
        <>
            <TotalPaymentChartStyles />
        </>
    )
}
