import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'

interface IPaymentMethodsResponse {
    result: number
}

export interface IPaymentMethodsDTO {
    PaymentMethod: string
    PaypalAccount: string
    PaypalMobile: string
    SkrillAddress: string
    BTCAddress: string
    DeelEmail: string
    IBAN: string
    SWIFT: string
    BankName: string
    BankAddress: string
    USDTAddress: string
}

export enum PaymentStatus {
    SUCCESS_PAYMENT = 1,
    FAIL_PAYMENT = 0,
}

export const bankingDetailsAPI = createApi({
    reducerPath: 'bankingDetailsAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        sendPayment: build.mutation<IPaymentMethodsResponse, IPaymentMethodsDTO>({
            query: (payload) => ({
                url: '/banking-details',
                method: 'POST',
                body: { ...payload },
            }),
        }),
        getPaymentMethods: build.query<IPaymentMethodsDTO, void>({
            query: () => ({
                url: `/banking-details`,
            }),
        }),
    }),
})
