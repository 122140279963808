import { createSlice } from '@reduxjs/toolkit'
import { TypeRootState } from '../store/store'
import { IAccountMetrix } from '../services/AccountServise/AccountService'

//
const initialState: IAccountMetrix[] = [
    {
        AccountID: '106057',
        Broker: 'FXH',
        Platform: '5',
        Spreads: 'r',
        Type: '15000',
        Risk: '2',
        SalesPrice: '0',
        Ordered: '2023-06-10 00:36:51',
        Status: '-1',
        AccountType: '1',
        FailReason: '4',
        BonusOption: null,
        DerivedFrom: null,
        ShareCode: '05e2a8cb-779d-4d74-83bf-51df51c6844c',
        Shared: '1',
        MaximumLossThreshold: '13518.2',
    },
]

const metrixAccountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        accountDataChange(state, action) {
            state.push(action.payload)
        },
        rmPrevAccData(state) {
            return []
        },
    },
})

export const selectAccount = (state: TypeRootState) => state.account

export const { accountDataChange, rmPrevAccData } = metrixAccountSlice.actions

export default metrixAccountSlice.reducer
