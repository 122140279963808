import styled from 'styled-components'
import { devices } from '../../devices'

export const StyledAverageRow = styled.article`
    max-width: 1155px;
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @media screen and (${({ theme }) => theme.media.large}) {
        max-width: 555px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 0;
    }
`

export const StyledChallengeColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledChallengeRowWrap = styled.article`
    display: flex;
    flex-direction: row;
    gap: 30px;

    margin-top: 30px;
    @media screen and (${({ theme }) => theme.media.large}) {
        flex-direction: column;
    }

    @media ${devices.md} {
        margin-bottom: 30px;
    }
`
