import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../baseQuery'
import { IPspValidatePromocode } from '../../../DTO/IPspValidatePromocode'

interface IPspValidatePromocodeResponse {
    discount: number
    maxPurchaseAmount: number
    minPurchaseAmount: number
    mode: string
    valid: boolean
}

export const pspValidatePromocodeAPI = createApi({
    reducerPath: 'pspValidatePromocodeAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        validatePromocode: build.mutation<IPspValidatePromocodeResponse, IPspValidatePromocode>({
            query: (payload) => ({
                url: '/psp-validate-promocode',
                method: 'POST',
                body: { ...payload },
            }),
        }),
    }),
})
