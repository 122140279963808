import React, { FC } from 'react'
import * as S from '../../../pages/Pay/Pay.styles'
import CopyToClipboard from '../../../ui-kit/CopyToClipboard/CopyToClipboard'
import { t } from '../../../utils/translate/t'

interface IWirePayment {
    wallet: string
    amount: string
}

const Wire: FC<IWirePayment> = (props) => {
    return (
        <>
            <S.PageTitle>
                <h3>{t('btc_pay_with_bitcoin')}</h3>
                <h3>{t('banking_details')}</h3>
            </S.PageTitle>
            <S.TextWrapper></S.TextWrapper>
            <S.WireDetails>
                {t('account_name')}
                <CopyToClipboard>{t('fidelcrest_ltd')}</CopyToClipboard>
            </S.WireDetails>

            <S.WireDetails>
                {t('iban')}
                <CopyToClipboard>CY97 0020 0195 0000 3570 3541 4059</CopyToClipboard>
            </S.WireDetails>
            <S.WireDetails>
                {t('swift')}
                <CopyToClipboard>BCYPCY2N</CopyToClipboard>
            </S.WireDetails>
            <S.WireDetails>
                {t('payment_amount')}
                <CopyToClipboard>{props.amount}</CopyToClipboard>
            </S.WireDetails>
            <S.WireDetails>
                {t('reference_message')}
                <CopyToClipboard>Account 119137</CopyToClipboard>
            </S.WireDetails>
            <S.Text>{t('do_not_write_anything_else')}</S.Text>
            <S.WireDetails>
                {t('bank_name')}
                <CopyToClipboard>BANK OF CYPRUS</CopyToClipboard>
            </S.WireDetails>
            <S.WireDetails>
                {t('bank_address')}
                <CopyToClipboard>
                    51 Stassinos street Ayia Paraskevi, Strovolos, Nicosia, Cyprus
                </CopyToClipboard>
            </S.WireDetails>
        </>
    )
}
export default Wire
