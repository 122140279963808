import { createSlice } from '@reduxjs/toolkit'
import { TypeRootState } from '../store/store'
import { IMetrixStats } from '../DTO/IMetrix'

//
const initialState: IMetrixStats[] = [
    {
        MaxDailyLossPct: '5',
        MaxLossPct: '10',
        MinTradingDays: '1',
        MaxDays: '60',
        CalendarDaysTraded: '3',
        MarginLimit: null,
        MarginLimitSingle: null,
        RequireSL: null,
        MinTradeDuration: null,
        IsFunded: '0',
        WeekendTrading: null,
        NewsTrading: null,
        StartingBalance: 15000,
        ProfitTargetPct: '10',
        LastBalance: 14702.2,
        LastEquity: 14253,
        OverallGainPct: -4.98,
        LargestOverallGainPct: 0.13,
        DrawdownPct: 5.11,
        LargestDrawdownPct: 5.11,
        CurrentDailyLossPct: 5.1078,
        LargestDailyLossPct: 5.11,
        LargestDailyLossDate: '2023-06-16',
        CurrentDayStartEquity: '15020.2',
        CurrentDayResetsIn: '09:10:46',
        CurrentDailyLossThreshold: 14269.19,
        CurrentMaximumLossThreshold: 13518.18,
        MaxBalance: '15020.2',
        MinEquity: '14253',
        MaxEquity: '15020.2',
        DaysTraded: '3',
        PositiveDays: 1,
        NegativeDays: 2,
        NumberOfTrades: '26',
        WinningRatePct: 23.08,
        LosingRatePct: 76.92,
        AverageProfit: -11.45,
        SymbolsFailure: 0,
        OpenTrades: '15',
    },
]

const metrixStatsSlice = createSlice({
    name: 'metrixStats',
    initialState,
    reducers: {
        metrixStatsDataChange(state, action) {
            state.push(action.payload)
        },
        rmPrevpMetrixStatsData(state) {
            return []
        },
    },
})

export const selectMetrixStat = (state: TypeRootState) => state.metrixStats

export const { metrixStatsDataChange, rmPrevpMetrixStatsData } = metrixStatsSlice.actions

export default metrixStatsSlice.reducer
