import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getXToken, removeFromStorage } from './services/auth/auth.helper'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
        const XToken = getXToken()
        if (XToken) {
            headers.set('x-token', `${XToken}`)
        }
        return headers
    },
})

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error && result?.error?.status === 401) {
        removeFromStorage()
    }
    return result
}
