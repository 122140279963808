import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'

interface IKYCStatusResponse {
    KYCStatus: string
}
export interface IDocumentResponse {
    DocumentID: string
    ProfileID: string
    Type: string
    Uploaded: string
    Reviewed: null
    Status: string
    Comments: null
    Path: string
    KYCStatus: string
}

export const documentAPI = createApi({
    reducerPath: 'documentAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getKYCStatus: build.query<IKYCStatusResponse, void>({
            query: () => ({
                url: `/kyc-status`,
            }),
        }),
        getDocuments: build.query<IDocumentResponse[], void>({
            query: () => ({
                url: `/get-documents`,
            }),
        }),
    }),
})
