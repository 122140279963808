import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    IPaymentMethodsDTO,
    PaymentStatus,
    bankingDetailsAPI,
} from '../../../services/BankingDetailsService/BankingDetailsService'
import { Button } from '../../../ui-kit/Button/Button.styles'
import {
    CheckBoxInput,
    CheckBoxLabel,
    CheckBoxWrapper,
} from '../../../ui-kit/CheckBox/CheckBox.styles'
import { Error } from '../../../ui-kit/Error/Error.styles'
import TextInput from '../../../ui-kit/TextInput/TextInput'
import { ToastConfig } from '../../../ui-kit/Toast/Toast.config'
import { t } from '../../../utils/translate/t'
import { IPayments } from '../ChoosePayment'
import { ButtonWrapper } from '../ChoosePayment.styles'

interface IFormValues {
    BTCAddress: string
    agree: boolean
}

const BTCPayment: FC<IPayments> = ({ payments, updatePayments }) => {
    const [canSubmit, setCanSubmit] = useState(true)
    const [sendPayments, response] = bankingDetailsAPI.useSendPaymentMutation()

    const previousBTCAddress = payments?.BTCAddress ? payments?.BTCAddress : ''
    const form = useForm<IFormValues>({
        defaultValues: {
            BTCAddress: previousBTCAddress,
            agree: false,
        },
    })
    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = form
    useEffect(() => {
        if (response.isSuccess) {
            if (response.data.result === PaymentStatus.SUCCESS_PAYMENT) {
                setCanSubmit(false)
                toast.success('Changes saved!', ToastConfig)
                updatePayments()
            }
            if (response.data.result === PaymentStatus.FAIL_PAYMENT) {
                setCanSubmit(false)
                toast.error(t('error_something_wrong'), ToastConfig)
            }
        }
    }, [response])
    useEffect(() => {
        isDirty ? setCanSubmit(true) : setCanSubmit(false)
    }, [isDirty])
    useEffect(() => {
        isValid ? setCanSubmit(false) : setCanSubmit(true)
    }, [isValid])

    const onSubmit = (data: IFormValues, e: any) => {
        e.preventDefault()
        setCanSubmit(false)
        try {
            if (payments && payments.hasOwnProperty('BTCAddress')) {
                let _payments: IPaymentMethodsDTO = JSON.parse(JSON.stringify(payments))
                _payments.BTCAddress = data.BTCAddress
                _payments.PaymentMethod = 'BTC'
                console.log(_payments)
                sendPayments(_payments).unwrap()
                setCanSubmit(true)
            } else {
                setCanSubmit(true)
                // @ todo error payment doesn't exist
            }
        } catch (error) {
            console.log(error)
            setCanSubmit(true)
            toast.error(t('error_something_wrong'), ToastConfig)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    label={t('btc_address')}
                    placeholder={t('type_here')} 
                    {...register('BTCAddress', {
                        required: `${t('btc_address')} ${t('required')}`,
                    })}
                />
                {errors.BTCAddress && <Error>{t('error_file_required')}</Error>}
                <CheckBoxWrapper>
                    <CheckBoxInput
                        type="checkbox"
                        {...register('agree', {
                            required: t('required'),
                        })}
                    />
                    <CheckBoxLabel htmlFor="checkbox">
                        {t('agree_my_banking_details')}
                    </CheckBoxLabel>
                </CheckBoxWrapper>

                {errors.agree && <Error>{t('error_file_required')}</Error>}
                <ButtonWrapper>
                    <Button type="submit" disabled={canSubmit}>
                        {t('save')}
                    </Button>
                </ButtonWrapper>
            </form>
            <ToastContainer />
        </>
    )
}

export default BTCPayment
