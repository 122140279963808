import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { activeBillingInfoChange } from '../../features/challengeSlice'
import * as S from './tabs.styles'

interface ITab {
    active: boolean
    title: string
    component: React.ReactElement
}

export enum TabsMode {
    default = 0,
    btn = 1,
}

interface ITabsProps {
    tabs: ITab[]
    mode?: TabsMode
}

const Tabs: React.FC<ITabsProps> = (tabProps) => {
    const TAB_DEFAULT = 0
    const [activeTab, setActiveTab] = useState(TAB_DEFAULT)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(activeBillingInfoChange(tabProps.tabs[activeTab].title))
    }, [activeTab])

    function toggleTab(idTab: number): void {
        setActiveTab(idTab)
    }

    function getTabs(tabProps: ITabsProps) {
        let tabLabels: ReactElement[] = []
        let tabContents: ReactElement[] = []
        tabProps.tabs.forEach((tab, key) => {
            let currentTab = (
                <S.StyledTab
                    key={key}
                    className={activeTab === key ? 'current' : ''}
                    onClick={() => toggleTab(key)}
                >
                    {tab.title}
                </S.StyledTab>
            )

            if (tabProps.mode === TabsMode.btn) {
                currentTab = (
                    <S.StyledBtnTab
                        key={key}
                        className={activeTab === key ? 'active' : ''}
                        onClick={() => toggleTab(key)}
                    >
                        {tab.title}
                    </S.StyledBtnTab>
                )
            }
            tabLabels.push(currentTab)

            if (activeTab === key) {
                tabContents.push(tab.component)
            }
        })

        return [tabLabels, tabContents]
    }

    const [labels, content] = getTabs(tabProps)

    let wrap = <S.StyledTabsWrap>{labels}</S.StyledTabsWrap>
    if (tabProps.mode === TabsMode.btn) {
        wrap = <S.StyledTabsBtnWrap>{labels}</S.StyledTabsBtnWrap>
    }
    return (
        <>
            <S.StyledTabs>
                {wrap}
                <S.StyledTabContent>{content}</S.StyledTabContent>
            </S.StyledTabs>
        </>
    )
}
export default Tabs
