import { FC, useEffect, useState } from 'react'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import {
    bankingDetailsAPI,
    IPaymentMethodsDTO,
} from '../../services/BankingDetailsService/BankingDetailsService'
import Tabs, { TabsMode } from '../../ui-kit/Tabs/Tabs'
import { t } from '../../utils/translate/t'
import BTCPayment from './BTCPayment/BTCPayment'
import { ChoosePaymentCard } from './ChoosePayment.styles'
import SkrillPayment from './SkrillPayment/SkrillPayment'
import USDTAddressPayment from './USDTAddressPayment/USDTAddressPayment'
import WirePayment from './WirePayment/WirePayment'

export interface IPayments {
    payments?: IPaymentMethodsDTO
    updatePayments: () => void
}

const ChoosePayment: FC = (props) => {
    const [
        getPaymentMethods,
        { isLoading, isError, data: paymentMethods, error },
        lastPromiseInfo,
    ] = bankingDetailsAPI.useLazyGetPaymentMethodsQuery()

    const [paymentMethodFields, setPaymentMethodFields] = useState<IPaymentMethodsDTO>()

    useEffect(() => {
        getPaymentMethods()
    }, [])

    useEffect(() => {
        if (!isError && !isLoading) {
            console.log('paymentMethods', paymentMethods)
            if (!paymentMethods) return
            setPaymentMethodFields(paymentMethods)
        }
    }, [isLoading])

    let tabProps = [
        {
            active: false,
            title: 'SKRILL',
            component: (
                <SkrillPayment payments={paymentMethodFields} updatePayments={getPaymentMethods} />
            ),
        },
        {
            active: false,
            title: t('btc'),
            component: (
                <BTCPayment payments={paymentMethodFields} updatePayments={getPaymentMethods} />
            ),
        },
        {
            active: false,
            title: 'WIRE',
            component: (
                <WirePayment payments={paymentMethodFields} updatePayments={getPaymentMethods} />
            ),
        },
        {
            active: false,
            title: t('usdt_address'),
            component: (
                <USDTAddressPayment
                    payments={paymentMethodFields}
                    updatePayments={getPaymentMethods}
                />
            ),
        },
    ]
    return (
        <ChoosePaymentCard>
            <SubHeader>{t('choose_your_payment_method')}</SubHeader>
            <Tabs tabs={tabProps} mode={TabsMode.btn} />
        </ChoosePaymentCard>
    )
}

export default ChoosePayment
