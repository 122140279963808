import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import { FrameWrapper } from '../EconomicCalendar/Calendar.styles'
import { t } from '../../utils/translate/t'

const VolatilityAnalysis: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('volatility_analysis'))
    }, [])
    return (
        <>
            <FrameWrapper style={{ maxWidth: '1200px' }}>
                <iframe
                    width={'100%'}
                    height={'1700px'}
                    src="https://component.autochartist.com/va/#/results?account_type=LIVE&amp;broker_id=833&amp;token=9476b4099257ed55ab4d2ddce318e1a8&amp;expire=1717192800&amp;user=Fidelcrest&amp;locale=en&amp;css=https:%2F%2Fbroker-resources.autochartist.com%2Fcss%2Fcomponents%2F833-va-app.css"
                    style={{ border: 'none' }}
                ></iframe>
            </FrameWrapper>
        </>
    )
}
export default VolatilityAnalysis
