import { FC, useEffect, useState } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { pspValidatePromocodeAPI } from '../../api/services/PspValidatePromocodeService/PspValidatePromocodeService'
import {
    corporatePromoChange,
    privatePromoChange,
    selectAllChallenge,
} from '../../features/challengeSlice'
import { Error } from '../../ui-kit/Error/Error.styles'
import { ToastConfig } from '../../ui-kit/Toast/Toast.config'
import { t } from '../../utils/translate/t'
import { FormValues } from '../PrivateBillingInfo/PrivateBillingInfo'
import * as S from '../PrivateBillingInfo/privateBillingInfo.styles'
import { ErrorBillingInfo } from '../PrivateBillingInfo/privateBillingInfo.styles'

interface IProps {
    registerParent: UseFormRegister<any>
    promoCodeError?: FieldError
    setValue: UseFormSetValue<any>
}

const Promocode: FC<IProps> = ({ registerParent, promoCodeError, setValue }) => {
    const [validPromocode, setValidPromocode] = useState<boolean | null>(null)
    const [promoCode, setPromoCode] = useState<string>('')
    const [promoIsEmpty, setPromoIsEmpty] = useState(false)

    const challenges = useSelector(selectAllChallenge)
    const dispatch = useDispatch()
    const form = useForm<FormValues>({
        defaultValues: {
            promoCode: '',
        },
    })
    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = form
    const [validatePromocode, response] = pspValidatePromocodeAPI.useValidatePromocodeMutation()

    useEffect(() => {
        setPromoIsEmpty(challenges.challenge.privatePromo.isEmpty)
    }, [challenges.challenge.privatePromo.isEmpty])
    useEffect(() => {
        setPromoIsEmpty(challenges.challenge.corporatePromo.isEmpty)
    }, [challenges.challenge.corporatePromo.isEmpty])

    const changePromo = () => {
        const promoCodeValue = getValues('promoCode')

        switch (challenges.challenge.activeBillingInfo) {
            case 'Private':
                dispatch(
                    privatePromoChange({
                        code: promoCodeValue,
                        valid: false,
                        error: false,
                        isEmpty: promoCodeValue.length === 0,
                    }),
                )
                break

            case 'Corporate':
                dispatch(
                    corporatePromoChange({
                        code: promoCodeValue,
                        valid: false,
                        error: false,
                        isEmpty: promoCodeValue.length === 0,
                    }),
                )
                break

            default:
                console.log(
                    `Something went wrong, redux cant get 2 tabs please contact support and show this Store error msg`,
                )
                break
        }

        if (promoCodeValue === '') {
            setValidPromocode(null)
            setPromoIsEmpty(false)
        }
    }

    useEffect(() => {
        try {
            setValue('promoCode', form.getValues('promoCode'))
            if (response?.data?.valid) {
                setValidPromocode(true)
            } else {
                if (undefined === response.data) {
                    return
                }
                setValidPromocode(false)
                setPromoIsEmpty(true)
                toast.error('Not valid Promocode', ToastConfig)
            }
        } catch (error) {
            if (!response?.data?.valid) {
                setValidPromocode(false)
                setPromoIsEmpty(true)
            }
            toast.error('Error: Try validate Promocode later', ToastConfig)
        }

        switch (challenges.challenge.activeBillingInfo) {
            case 'Private':
                dispatch(
                    privatePromoChange({
                        code: promoCode,
                        valid: validPromocode,
                        error: false,
                    }),
                )
                break
            case 'Corporate':
                dispatch(
                    corporatePromoChange({
                        code: promoCode,
                        valid: validPromocode,
                        error: false,
                    }),
                )
                break

            default:
                console.log(
                    `Something went wrong, redux cant get 2 tabs please contact support and show this Store error msg`,
                )
                break
        }
    }, [response.isSuccess])

    const onSubmit = (data: FormValues) => {
        if (data?.promoCode === '') return
        validatePromocode({ promocode: data.promoCode })
        setPromoCode(data.promoCode)
    }

    let confirmNotValidPromo = validPromocode === false
    let notConfirmedAndNotValidPromo = validPromocode !== false

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.PromoLabel htmlFor="PromoCode" isHidden={!!validPromocode}>
                                Promo Code
                                <S.StyledPromoCodeInput
                                    type="text"
                                    {...register('promoCode', {
                                        onBlur: changePromo,
                                    })}
                                    placeholder={t('type_here')}
                                />
                            </S.PromoLabel>
                            <ErrorBillingInfo>{promoCodeError?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                    </S.Column>
                    <S.ColumnReverse>
                        <S.SubmitBtn type={'submit'}>{t('validate')}</S.SubmitBtn>
                    </S.ColumnReverse>
                </S.Wrapper>
                {confirmNotValidPromo && <Error>{t('error_promo')}</Error>}
                {!promoIsEmpty && notConfirmedAndNotValidPromo && (
                    <Error>{t('please_validate_promo_code')}</Error>
                )}
            </form>
            <ToastContainer />
        </>
    )
}
export default Promocode
