import React, { FC, useEffect, useState } from 'react'
import * as S from './customCheckbox.styles'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'

type Props = {
    children?: React.ReactNode
    setValue: UseFormSetValue<any>
    register: UseFormRegister<any>
    name: string
}
const CustomCheckbox: FC<Props> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false)

    const content = (
        <>
            <S.StyledCheckbox
                {...props.register(props.name, {
                    required: ` Must be accepted!`,
                })}
                id={props.name}
            />
            <S.TextWrap>{props.children}</S.TextWrap>
        </>
    )

    // @todo After all will be done (in June), useYupValidationResolver https://react-hook-form.com/advanced-usage
    useEffect(() => {
        props.setValue(props.name, isChecked)
    }, [isChecked])

    return (
        <>
            {isChecked ? (
                <S.CheckedCheckBoxLabel
                    htmlFor={props.name}
                    onClick={() => setIsChecked(!isChecked)}
                >
                    {content}
                </S.CheckedCheckBoxLabel>
            ) : (
                <S.CheckBoxLabel htmlFor={props.name} onClick={() => setIsChecked(!isChecked)}>
                    {content}
                </S.CheckBoxLabel>
            )}
        </>
    )
}
export default CustomCheckbox
