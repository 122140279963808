import React from 'react'
import { IDocumentResponse } from '../../services/DocumentsService/DocumentsService'
import Upload from '../../ui-kit/Upload/Upload'
import { t } from '../../utils/translate/t'
import * as S from './docsItem.styles'

interface IDocsItem {
    title: string
    documentData: IDocumentResponse[]
}

const DocumentRow: React.FC<IDocsItem> = ({ title, documentData }) => {
    return (
        <S.ItemWrapper>
            <S.ItemTitle>
                <S.Logo />
                <p>{title}</p>
                {documentData[0]?.Status === '0' ? <S.Warning /> : <></>}
            </S.ItemTitle>

            <S.ItemStatus disabled={documentData.length > 0 ? false : true}>
                <S.Column>
                    <h4>{t('date')}</h4>
                    {documentData.map((field) => (
                        <p>
                            <a href="http://">{field.DocumentID}</a>
                        </p>
                    ))}
                </S.Column>
                <S.Column>
                    <h4>{t('status')}</h4>
                    {documentData.map((field) => (
                        <p>
                            <a href="http://">{field.Status === '0' ? 'Reviewing' : 'Approve'}</a>
                        </p>
                    ))}
                </S.Column>
                <S.Column>
                    <h4>{t('comments')}</h4>
                    {documentData.map((field) => (
                        <p>
                            <a href="http://">{field.Comments ? field.Comments : '-'}</a>
                        </p>
                    ))}
                </S.Column>
            </S.ItemStatus>
            <S.ButtonWrapper>
                <Upload title="Upload" />
            </S.ButtonWrapper>
        </S.ItemWrapper>
    )
}

export default DocumentRow
