import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'
import { ISupportPayload, ISupportResponse } from '../../DTO/IContactSupport'

export const contactSupportApi = createApi({
    reducerPath: 'contactSupportApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        sendSupportMessage: build.mutation<ISupportResponse, ISupportPayload>({
            query: (payload) => ({
                url: '/support',
                method: 'POST',
                body: { ...payload },
            }),
        }),
    }),
})
