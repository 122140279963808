import styled from 'styled-components'
import { devices } from '../../devices'

export const StyledDescription = styled.p`
    display: flex;
    align-items: center;
    height: 36px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */
    margin: 15px 0;

    /* 50% text */

    color: ${({ theme }) => theme.colors.textStandart};
`
export const StyledBillingInfo = styled.div`
    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};

    border-radius: 15px;
    padding: 20px;
    max-width: 555px;
    margin: 0 0 30px 0;
    min-width: 555px;

    @media screen and (${({ theme }) => theme.media.large}) {
        margin-bottom: 0;
    }

    @media ${devices.md} {
        min-width: 250px;
    }
`
