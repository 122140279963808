import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { IMetrixStats } from '../../../../DTO/IMetrix'
import { selectAccount } from '../../../../features/metrixAccountSlice'
import { selectMetrixStat } from '../../../../features/metrixStatsSlice'
import * as S from '../../../../pages/Metrix/metrix.styles'
import { IAccountMetrix } from '../../../../services/AccountServise/AccountService'
import { format } from '../../../../utils/format'
import { t } from '../../../../utils/translate/t'

type THeadTitle = {
    title: string
}

type Objective = {
    title: string
    titleValue?: string
    current: string
    highest?: string | number
    status?: string
    failedStatus?: string
    passedStatus?: string
    percent?: boolean
    criteria?: string | null
    pass?: boolean
    limit?: string
    result?: number | null
    result2?: number | null
}

interface IGoal {
    FailReason: number
    RuleAttribute: keyof IMetrixStats
    FailTitle: string
    Title: string
    key?: string
}

const goals: IGoal[] = [
    {
        FailReason: 9,
        RuleAttribute: 'MinTradeDuration',
        Title: 'Min Trade Duration',
        FailTitle: 'min_trade_duration_fail_title',
    },
    {
        FailReason: 10,
        RuleAttribute: 'RequireSL',
        Title: 'Require SL',
        FailTitle: 'require_sl_fail_title',
    },
    {
        FailReason: 11,
        RuleAttribute: 'NewsTrading',
        Title: 'News Trading',
        FailTitle: 'news_trading_fail_title',
    },
    {
        FailReason: 12,
        RuleAttribute: 'WeekendTrading',
        Title: 'Weekend Trading',
        FailTitle: 'weekend_trading_fail_title',
    },
    {
        FailReason: 14,
        RuleAttribute: 'MaxDailyLossPct',
        Title: 'Max Daily Loss Pct',
        FailTitle: 'max_daily_loss_pct_fail_title',
    },
    {
        FailReason: 20,
        RuleAttribute: 'CopyTrading',
        Title: 'Copy Trading',
        FailTitle: 'copy_trading_fail_title',
    },
]

const TableObjectivesColumnTitle: THeadTitle[] = [
    { title: t('objectives') },
    { title: t('stats_current') },
    { title: t('stats_highest') },
    { title: t('status') },
]

const Objectives: Objective[] = [
    {
        title: 'Daily loss ',
        titleValue: 'MaxDailyLossPct',
        current: 'CurrentDailyLossPct',
        highest: 'LargestDailyLossPct',
        criteria: 'gt',
        percent: true,
    },
    {
        title: 'Max loss ',
        titleValue: 'MaxLossPct',
        current: 'DrawdownPct',
        highest: 'LargestDrawdownPct',
        criteria: 'gt',
        percent: true,
    },
    {
        title: 'Profit Target ',
        titleValue: 'ProfitTargetPct',
        current: 'OverallGainPct',
        highest: 'LargestOverallGainPct',
        criteria: 'lt',
        percent: true,
    },
    {
        title: 'Min Trading Days ',
        titleValue: 'MinTradingDays',
        current: 'DaysTraded',
        criteria: 'lt',
        percent: false,
    },
    {
        title: 'Max Days ',
        titleValue: 'MaxDays',
        current: 'CalendarDaysTraded',
        status: t( 'passed' ),
        criteria: 'gt',
        percent: false,
    },
    {
        title: 'All Positions Closed',
        titleValue: 'AllPositionsClosed',
        current: 'OpenTrades',
        status: t( 'in_progress' ),
        percent: false,
    },
]

function getObjectivesTableTitle() {
    let row: ReactElement[] = []
    let cell: ReactElement

    TableObjectivesColumnTitle.map((value: THeadTitle) => {
        cell = <S.TDataHeader>{value.title}</S.TDataHeader>
        row.push(cell)
    })
    return row
}

function getObjectivesTableRows(account: IAccountMetrix, metrixStats: IMetrixStats) {
    let rows: ReactElement[] = []
    let row: ReactElement

    Objectives.map((objective: Objective, index) => {
        switch (objective.titleValue) {
            case 'MaxDailyLossPct':
                objective.limit = metrixStats.MaxDailyLossPct
                objective.result = metrixStats.CurrentDailyLossPct || 0
                objective.result2 = metrixStats.LargestDailyLossPct || 0
                break

            case 'MaxLossPct':
                objective.limit = metrixStats.MaxLossPct
                objective.result = metrixStats.DrawdownPct || 0
                objective.result2 = metrixStats.LargestDrawdownPct || 0
                break

            case 'ProfitTargetPct':
                objective.limit = metrixStats.ProfitTargetPct
                objective.result = metrixStats.OverallGainPct || 0
                objective.result2 = metrixStats.LargestOverallGainPct || 0

                if (account.AccountType !== '3') {
                    if (account.Status === '2') {
                        objective.failedStatus = t( 'in_progress' )
                    } else {
                        objective.failedStatus = t( 'failed' )
                    }
                }
                break

            case 'MinTradingDays':
                objective.limit = metrixStats.MinTradingDays
                objective.result = Number(metrixStats.DaysTraded)

                if (account.Status === '2') {
                    objective.failedStatus = t( 'in_progress' )
                } else {
                    objective.failedStatus = t( 'failed' )
                }
                break

            case 'MaxDays':
                objective.limit = metrixStats.MaxDays
                objective.result = Number(metrixStats.CalendarDaysTraded)

                if (account.Status === '2') {
                    objective.passedStatus = t( 'in_progress' )
                } else {
                    objective.passedStatus = t( 'passed' )
                }
                break
            case 'AllPositionsClosed':
                objective.pass = Number(metrixStats.OpenTrades) !== 0
                objective.failedStatus = t( 'in_progress' )
                objective.result = null
                objective.result2 = null
                objective.criteria = null
                break

            default:
                objective.status = t( 'failed' )
                break
        }

        objective.limit = objective.limit ? objective.limit : ''
        const resultToCompare = Number(objective.result2 || objective.result || 0)
        let pass = objective.pass
        if (objective.criteria) {
            pass = resultToCompare >= Number(objective.limit)
            if (objective.criteria === 'lt') pass = resultToCompare < Number(objective.limit)
        }
        if (pass) {
            objective.status = objective.failedStatus || t( 'failed' )
        } else {
            objective.status = objective.passedStatus || t( 'passed' )
        }
    })

    Objectives.map((objective: Objective, index) => {
        let status: ReactElement
        switch (objective.status) {
            case t( 'failed' ):
                status = <S.Status className={'failed'}>{objective.status}</S.Status>
                break
            case t( 'in_progress' ):
                status = <S.Status className={'inProgress'}>{objective.status}</S.Status>
                break
            case t( 'passed' ):
                status = <S.Status>{objective.status}</S.Status>
                break
            default:
                status = <>{objective.status}</>
                break
        }

        let percentFormat = (number: number) => {
            return number || number === 0 ? format(number || 0, 2) + ' %' : ''
        }

        let numberFormat = (number: number) => {
            return number === 0 ? '0' : number
        }
        let getFormattedValue = (percent: boolean, number: number | null) => {
            if (number === null) return null
            // if (number === 0) return null
            return percent ? percentFormat(number || 0) : numberFormat(number || 0)
        }

        let current = metrixStats[objective.current! as keyof IMetrixStats]
            ? Number(metrixStats[objective.current! as keyof IMetrixStats])
            : 0
        let highest = metrixStats[objective.highest! as keyof IMetrixStats]
            ? Number(metrixStats[objective.highest! as keyof IMetrixStats])
            : 0

        let titleVal = metrixStats[objective.titleValue! as keyof IMetrixStats]
            ? metrixStats[objective.titleValue! as keyof IMetrixStats]
            : null
        if (titleVal === undefined) {
            titleVal = null
        }
        if (typeof titleVal === 'string') {
            titleVal = Number(titleVal)
        }
        row = (
            <S.TRow key={index}>
                <S.TData>
                    <pre>{objective.title}</pre>
                    <p>{getFormattedValue(objective.percent || false, titleVal)}</p>
                </S.TData>
                <S.TData>{getFormattedValue(objective.percent || false, current)}</S.TData>
                <S.TData>{getFormattedValue(objective.percent || false, highest)}</S.TData>
                <S.TData>{status}</S.TData>
            </S.TRow>
        )

        rows.push(row)
    })

    goals.map((a, index) => {
        if (!metrixStats[a.RuleAttribute]) return null
        if (a.FailReason === 9 && account.AccountType === '1') return null
        if (a.FailReason === 10 && account.AccountType === '1') return null
        if (a.FailReason === 11 && account.AccountType === '1') return null
        if (a.FailReason === 13 && account.AccountType === '1') return null
        if (a.FailReason === 14 && account.AccountType <= '2') return null

        let status = <S.Status className={'inProgress'}>{t( 'in_progress' )}</S.Status>

        if (Number(account.FailReason) === a.FailReason) {
            status = <S.Status className={'failed'}>{t( 'failed' )}</S.Status>
        } else {
            if (account.Status !== '0') {
                status = <S.Status>{t( 'passed' )}</S.Status>
            } else {
                status = <S.Status className={'inProgress'}>{t( 'in_progress' )}</S.Status>
            }
        }

        row = (
            <S.TRow key={index}>
                <S.TData>{a.Title}</S.TData>
                <S.TData></S.TData>
                <S.TData></S.TData>
                <S.TData>{status}</S.TData>
            </S.TRow>
        )

        rows.push(row)
    })
    return rows
}

const ObjectivesTable: FC = () => {
    const account = useSelector(selectAccount)
    const metrixStats = useSelector(selectMetrixStat)
    return (
        <>
            <S.ObjectivesTable key={1}>
                <S.GroupTitle>{t('objective')}</S.GroupTitle>
                <S.Table>
                    <S.THead>
                        <S.TRow>{getObjectivesTableTitle()}</S.TRow>
                    </S.THead>
                    <S.TBody>{getObjectivesTableRows(account[0], metrixStats[0])}</S.TBody>
                </S.Table>
            </S.ObjectivesTable>
        </>
    )
}
export default ObjectivesTable
