import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { devices } from '../../devices'

export const LogoMobile = styled.img`
    display: none;
    border: 0;
    width: 250px;
    margin: 0 auto;
    @media ${devices.md} {
        display: flex;
    }
`
export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.bgLoginPage};
    color: ${({ theme }) => theme.colors.textLogo};
`
export const PaymentMethodsWrapper = styled.div`
    max-width: 300px;
    margin: auto;
`
export const PageTitle = styled.div`
    margin: 0 auto;
    text-align: center;

    & > h3 {
        padding-top: 10px;
    }
`
export const WhiteBackForQR = styled.div`
    background: ${({ theme }) => theme.colors.bgWhiteForQR};
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    height: 300px;
    margin: 20px auto 0;

    & > svg {
        width: 100%;
        height: 100%;
    }
`
export const TextWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 20px;
`

export const Text = styled.p`
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.textGrey};

    &:nth-child(2) {
        flex-direction: column;

        & > text {
            padding: 0;
            margin: 0 auto;
            align-items: center;
        }
    }

    & > text {
        color: ${({ theme }) => theme.colors.textLogo};
        padding: 0 10px;
        font-weight: 600;
        display: flex;

        & > p {
            overflow: unset;
            max-width: 200px;
            overflow-wrap: anywhere;
        }

        &:after {
            background-size: 20px;
        }
    }
`

export const BackLink = styled(Link)`
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textLogo};
    margin: 0 auto;
    justify-self: center;
`
export const WireDetails = styled.div`
    display: flex;
    flex-direction: column;

    font-weight: 600;
    padding-bottom: 20px;

    & > text {
        padding-top: 10px;
        font-weight: normal;
        color: ${({ theme }) => theme.colors.textGrey};

        & > p {
            max-width: unset;
            width: 100%;
        }
    }
`
