import { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import iconSkrillNeteller from '../../img/svg/challenge/payment_methods/Skrill_Neteller.svg'
import iconBitcoin from '../../img/svg/challenge/payment_methods/btc.svg'
import iconHelp2pay from '../../img/svg/challenge/payment_methods/help2pay.webp'
import iconUtrust from '../../img/svg/challenge/payment_methods/utrust.svg'
import iconVisa from '../../img/svg/challenge/payment_methods/visa.svg'
import iconWeChat from '../../img/svg/challenge/payment_methods/weChatPayAndAliPay.svg'
import iconWireTransfer from '../../img/svg/challenge/payment_methods/wire.svg'
import * as S from './paymentMethods.styles'
// import { pspOrderChange } from '../../store/buildPspOrder/buildPspOrder.slice'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { devices } from '../../devices'
import { paymentMethodChange, selectAllChallenge } from '../../features/challengeSlice'
import { t } from '../../utils/translate/t'

const VisaPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-image: url(${iconVisa});
    }
`
const SkrillNetellerPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-image: url(${iconSkrillNeteller});
    }
`
const Help2payPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-image: url(${iconHelp2pay});
    }
`
const BitcoinPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-size: 50% 100%;
        background-image: url(${iconBitcoin});
        width: 79px;
        height: 79px;
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        border-radius: 50%;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
    }

    &:after {
        content: ${t('bitcoin')};
        position: absolute;
        opacity: 0.5;
        bottom: 15px;
        left: 75%;
        transform: translateX(-50%);
        width: 140px;
        font-weight: 600;
    }

    @media ${devices.md} {
        &:before {
            width: 56px;
            height: 56px;
            left: calc(50% - 27px);
        }
        &:after {
            width: 100px;
            font-size: 12px;
        }
    }
`
const WireTransferPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-size: 50% 100%;
        background-image: url(${iconWireTransfer});
        width: 79px;
        height: 79px;
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        border-radius: 50%;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        //transform: translate(-50%, -50%);
    }

    &:after {
        content: ${t('wire_transfer')};
        position: absolute;
        opacity: 0.5;
        bottom: 15px;
        left: 60%;
        transform: translateX(-50%);
        width: 140px;
        font-weight: 600;
    }

    @media ${devices.md} {
        &:before {
            width: 56px;
            height: 56px;
            left: calc(50% - 27px);
        }
        &:after {
            width: 100px;
            font-size: 12px;
        }
    }
`
const UtrustPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-image: url(${iconUtrust});
    }
`

const WeChatPaymentMethod = styled(S.StyledPaymentMethod)`
    &:before {
        background-image: url(${iconWeChat});
    }
`

interface IPaymentMethodsProps {
    submitOrder: any //UseFormHandleSubmit<any>
}

export enum PaymentMethodsEnum {
    Decta = 'Decta',
    Skrill = 'Skrill',
    Stripe = 'Stripe',
    Viva = 'Viva',
    Help2Pay = 'Help2Pay',
    BitcoinRemoteWallet = 'BitcoinRemoteWallet',
    Wire = 'Wire',
    Flutterwave = 'Flutterwave',
    Paystack = 'Paystack',
    uTrust = 'uTrust',
    weChat = 'weChat', // @todo discuss with Kiur
    Coinbase = 'Coinbase',
    ePayment = 'ePayment',
    Authorize = 'Authorize',
}

const PaymentMethods: FC<IPaymentMethodsProps> = ({ submitOrder }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const challenges = useSelector(selectAllChallenge)
    let onClick = async (
        e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
        type: PaymentMethodsEnum,
    ) => {
        console.log('Type:', type)
        dispatch(paymentMethodChange(type))
        submitOrder()
    }

    return (
        <S.StyledPaymentMethods>
            <VisaPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.Decta)} />
            <SkrillNetellerPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.Skrill)} />
            <Help2payPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.Help2Pay)} />
            <BitcoinPaymentMethod
                onClick={(e) => onClick(e, PaymentMethodsEnum.BitcoinRemoteWallet)}
            />
            <WireTransferPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.Wire)} />
            <UtrustPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.uTrust)} />
            <WeChatPaymentMethod onClick={(e) => onClick(e, PaymentMethodsEnum.weChat)} />
        </S.StyledPaymentMethods>
    )
}

export default PaymentMethods
