import {ITranslations} from "../t";

export const en: ITranslations = {
    "tous_not_checked_error_dialog":
        "<b>Please review</b><ul style='padding-left:20px'><li>Terms of use<li>General Terms and Conditions <li>Refund Policy</ul>",

    "payment_confirmation":
        "<b>Are you sure you don't want to add Insurance?</b> <br><br> This would provide you with another account should you fail in any phase",

    "usdt_address": 'USDT Address',

    "total": "Total",

    /*
    "ADDON_STATIC_DD": "Static dd",
    "ADDON_INSURANCE": "Insurance",
    "ADDON_RETRY": "Retry",
    "ADDON_DOUBLE_FUNDED_CAPITAL": "Double funded capital",
    "ADDON_SUPER_REFUND": "Super refund",
    "ADDON_LEVERAGE_200": "Leverage 200",
    */

    "prize": "Prize",

    "balance": "Balance",
    "equity": "Equity",

    "start_equity": "Start equity",
    "current_equity": "Curr. equity",

    "contact_support": "Contact support",
    "support_topic": "Topic",
    "support_subject": "Subject",
    "support_message": "Message",
    "support_send": "Send",
    "support_message_sent": "Message sent",

    "support_topic_refund": "Refund",
    "support_topic_review": "Review",
    "support_topic_payout": "Payout",
    "support_topic_technical_support": "Technical support",
    "support_topic_other": "Other",

    "risk_calculator": "Risk Calculator",
    "volatility_analysis": "Volatility Analysis",

    "webtrader": "Webtrader",

    "bf-2for1-copy": "Black Friday Offer 2for1: Use promo code BLACK-2FOR1",
    "bf-2for1-link-text": "Show More",
    "bf-2for1-link-url": "https://fidelcrest.com/news/black-nov",

    "stats_AverageProfit": "Average profit",
    "stats_CalendarDaysTraded": "Calendar days traded",
    "stats_current": "Current",
    "stats_CurrentDailyLossThreshold": "Daily loss threshold",
    "stats_CurrentDayResetsIn": "Day resets in",
    "stats_CurrentDayStartEquity": "Day start equity",
    "stats_CurrentMaximumLossThreshold": "Max loss threshold",
    "stats_DaysTraded": "Days traded",
    "stats_DrawdownPct": "Largest loss %",
    "stats_highest": "Highest",
    "stats_LargestDailyLossDate": "Largest daily loss date",
    "stats_LargestDailyLossPct": "Largest daily loss %",
    "stats_LastBalance": "Last balance",
    "stats_LastEquity": "Last equity",
    "stats_LosingRatePct": "Losing rate %",
    "stats_MaxBalance": "Highest balance",
    "stats_MaxDailyLossPct": "Max daily loss %",
    "stats_MaxLossPct": "Max loss %",
    "stats_MinTradingDays": "Min trading days",
    "stats_NegativeDays": "Negative days",
    "stats_NumberOfTrades": "Number of trades",
    "stats_OverallGainPct": "Overall gain %",
    "stats_PositiveDays": "Positive days",
    "stats_ProfitTargetPct": "Profit target %",
    "stats_StartingBalance": "Starting balance",
    "stats_WinningRatePct": "Winning rate %",

    "evaluation_type": "Evaluation Type",
    "evaluation_type_0_step": "Evaluation type: Instant fund",
    "evaluation_type_0_step_acctype_1": "Phase 1 (professional)",
    "evaluation_type_1_step": "Evaluation type: 1-step",
    "evaluation_type_1_step_acctype_1": "Phase 1 (challenge)",
    "evaluation_type_1_step_acctype_2": "Phase 2 (professional)",
    "evaluation_type_2_step": "Evaluation type: 2-step",

    "min_equity": "Min Equity",
    "receipt": "Receipt",
    "steps": "Steps",

    "trustpilot-review-teaser-later": "Later",
    "trustpilot-review-teaser-no": "No",
    "trustpilot-review-teaser-text": "Would you like to review us in TrustPilot?",
    "trustpilot-review-teaser-yes": "Yes",

    "contestants": "Contestants",

    "deel": "Deel",
    "deel_email": "Deel Email Address",

    "currency_eur": "EUR",

    "updated": "Updated",
    "account_metrix": "Account Metrix",
    "payment_method": "Payment Method",

    "btc": "BTC",
    "paypal": "Paypal",
    "skrill": "Skrill",
    "wire": "Wire",

    "aggressive": "Aggressive",
    "choose_your_payment_method": "Choose Your Payment Method",
    "documents": "Documents",
    "language": "Language",
    "normal": "Normal",
    "please_validate_promo_code": "Please validate the promo code!",
    "show_in_english": "Show in English",
    "trading_program": "Trading Program",

    "accepted_file_types": "Accepted file types are: JPEG, PNG and PDF.",
    "account": "Account",
    "account_name": "Account name",
    "account_reason_2": "Drawdown limit",
    "account_reason_4": "Daily loss",
    "account_reason_6": "Period exceeded",
    "account_status_-1": "Failed",
    "account_status_-2": "Cancelled",
    "account_status_-3": "Refunded",
    "account_status_0": "Awaiting payment",
    "account_status_1": "Pending",
    "account_status_2": "Active",
    "account_status_4": "Completed",
    "account_status_5": "In review",
    "account_status_6": "Agreement pending",
    "accounts": "Accounts",
    "acctype_1": "Phase 1 (challenge)",
    "acctype_2": "Phase 2 (verification)",
    "acctype_3": "Phase 3 (professional)",
    "address": "Address",
    "agree_general": "General Terms and Conditions",
    "agree_my_banking_details": "I agree that my saved banking details will be sent to the proprietary trading company after I have completed the evaluation process. The Proprietary Trading Company will process your profit split payouts automatically and send funds to your account",
    "agree_refund": "Refund Policy",
    "agree_to_processing_personal_data": "I agree to the processing of my personal data according to our",
    "agree_tos": "Terms of Use",
    "all_positions_closed": "All Positions Closed",
    "an_error_occurred": "An error occurred!",
    "back": "Back",
    "back_to_instruments": "Back to Instruments",
    "back_to_website": "Back to website",
    "bank_address": "Bank Address",
    "bank_name": "Bank Name",
    "banking_details": "Banking details",
    "before_start_info": "Before you start the challenge, we need to know some basic information about you. All fields are mandatory.",
    "benefits": "Benefits",
    "billing": "Billing",
    "billing_info": "Billing info",
    "bonus_option": "Bonus option",
    "bonus_option_1": "Double Your Capital",
    "bonus_option_2": "Second Chance For Free",
    "bonus_option_3": "Fast Track - No minimum trading days",
    "brokerage": "Provider",
    "btc_address": "BTC Address",
    "btc_pay_with_bitcoin": "Pay with Bitcoin",
    "btc_send": "Send",
    "btc_to": "BTC to",
    "cancel": "Cancel",
    "cancel_order": "Cancel Order",
    "capital": "Capital",
    "card_payment": "Card Payment",
    "categories": "Categories",
    "check_your_email": "Please check your mail",
    "choose_currency": "Choose currency",
    "city": "City",
    "close_full_screen": "Close full screen",
    "close_period": "Close Period",
    "comments": "Comments",
    "company_name": "Company Name",
    "company_reg_nr": "Company Registration Nr",
    "company_tax_nr": "Company VAT Number",
    "copy_to_clipboard": "Copy to clipboard",
    "copy_trading_fail_title": "Copy-trading",
    "corporate": "Corporate",
    "country": "Country",
    "country_of_residence": "Country of residence",
    "cryptocurrency_operator_commission": "Please be advised that your cryptocurrency operator might charge you a commission for this transaction.",
    "dashboard": "Dashboard",
    "date": "Date",
    "do_not_write_anything_else": "Do not write anything else in the reference/message field.",
    "doc_explain_1": "Photo of the front of your government-issued ID",
    "doc_explain_11": "Signed ToU agreement",
    "doc_explain_2": "Photo of the back of your government-issued ID",
    "doc_explain_3": "Photo of the photo page of your passport",
    "doc_explain_5": "Photo of you with your face fully visible",
    "doc_explain_9": "Photo of an recent utility bill that proves your place of residence",
    "doc_status_-1": "Rejected",
    "doc_status_0": "Reviewing",
    "doc_status_1": "Approved",
    "doc_type_1": "Proof of ID front",
    "doc_type_11": "Signed agreement",
    "doc_type_2": "Proof of ID back",
    "doc_type_3": "Passport photo page",
    "doc_type_5": "Selfie",
    "doc_type_9": "Proof of address",
    "docs_info": "In case of an ID card, upload both sides as separate files. In case of a passport, upload an photo of the passport page. The photos must be clear and without obstructions and strong reflections. Once your documents are reviewed, the system will send you an e-mail.",
    "docs_intro": "We will require copy of ID/passport, Proof of Address and Selfie only before entering the Verification phase 2.",
    "download_pdf": "Download PDF",
    "economic_calendar": "Economic Calendar",
    "email_address": "Email address",
    "email_in_use": "Email address already in use. Please log in or use another email address.", // @todo add error to sign in page
    "email_not_found": "Email not found!",
    "enter_details": "Enter your details to create your account",
    "fail_1_issue": "Overseas transaction blocked by the card issuer",
    "fail_1_resolution": "To resolve this, contact your card issuer to authorize the transaction to a merchant in Cyprus and try again.",
    "fail_2_issue": "3D-Secure check failed",
    "fail_2_resolution": "You failed an authorization challenge by your card issuer. Please complete the security check correctly next time.",
    "fail_3_issue": "Insufficient funds",
    "fail_3_resolution": "To resolve this, make sure that you have enough credit/money on the card.",
    "fail_problem": "There was a problem",
    "fail_resolve": "You can resolve the issue or try another card. Here are the most common issues and how to resolve them",
    "fail_with": "with your payment",
    "failed": "Failed",
    "fcm_cfds": "CFD's are only allowed to be traded with Fidelcrest Markets.",
    "fcm_instantly": "Fidelcrest Markets accounts are delivered instantly.",
    "feed": "Economic calendar",
    "fidelcrest_challenge": "Fidelcrest Challenge",
    "fidelcrest_trade": "Fidelcrest Trade",
    "fill_in_all": "Please fill in all the fields!",
    "fill_in_blank_fields": "Please fill in blank fields",
    "first_name": "First name",
    "footer_1": "Fidelcrest is the trading name of Fidelcrest Ltd, Cyprus.",
    "footer_2": "Fidelcrest.com and trade.fidelcrest.com is owned and operated by Fidelcrest Ltd, registered office at Arch. Makariou III & 1-7 Evagorou, MITSI 3, 1st floor, office 102 C, 1065 Nicosia, Cyprus (Company number HE413263)",
    "footer_3": "All content published and distributed by Fidelcrest Ltd and its affiliates (collectively, the “Company”) is to be treated as general information only. None of the information provided by the Company or contained herein is intended as (a) investment advice, (b) an offer or solicitation of an offer to buy or sell, or (c) a recommendation, endorsement, or sponsorship of any security, company, or fund. Testimonials appearing on the Company’s websites may not be representative of other clients or customers and is not a guarantee of future performance or success. Use of the information contained on the Company’s websites is at your own risk and the Company and its partners, representatives, agents, employees, and contractors assume no responsibility or liability for any use or misuse of such information. Trading in financial markets is a high-risk activity and it is advised not to risk more than one can afford to lose! Fidelcrest Ltd does not provide any of the investment services listed in the Capital Market Undertakings Act No. 256/2004 Coll. The information on this site is not directed at residents in any country or jurisdiction where such distribution or use would be contrary to local laws or regulations. Fidelcrest is not a broker and do not accept deposits. © 2018 - 2021 All Rights Reserved by Fidelcrest Group",
    "footer_risk_policy_title": "Risk Policy",
    "forgot_password": "Forgot password",
    "general_terms": "General Terms And Conditions",
    "i_agree_to": "I agree to",
    "iban": "IBAN",
    "if_you_change_your_address": "Please note, that if you change your address, you must upload a new Proof of address to My Documents",
    "in_progress": "In Progress",
    "including_vat": "Including VAT",
    "initial_capital": "Initial capital",
    "instruments": "Instruments",
    "last_name": "Last name",
    "last_update": "Last update",
    "leaderboard": "Leaderboard",
    "loading": "Loading",
    "log_in": "Log in",
    "login_failed": "Email and password didn't match!",
    "login_here": "Login here",
    "login_manage": "to the old trader area to manage my old Fidelcrest accounts.",
    "login_to_older": "to the old trader area to",
    "logotext": "FIDELCREST",
    "main_menu": "Main menu",
    "margin": "Margin",
    "max_daily_loss": "Max Daily Loss",
    "max_daily_loss_pct_fail_title": "Lot size range limit",
    "max_days": "Max Days",
    "max_loss": "Max Loss",
    "menu_billing": "Billing",
    "menu_challenge": "Fidelcrest Challenge",
    "menu_dashboard": "Dashboard",
    "menu_docs": "My Documents",
    "menu_password": "Change password",
    "menu_profile": "Profile",
    "messages": "Messages",
    "metrix": "Metrix",
    "metrix_balance": "Balance",
    "metrix_drawdown": "DrawDown",
    "metrix_equity": "Equity",
    "metrix_max_balance": "Max Balance",
    "min_trade_duration_fail_title": "Short trade duration",
    "min_trading_days": "Min Trading Days",
    "my_banking_details": "Profit Payout Details",
    "my_documents": "My Documents",
    "my_name_is_correct": "I confirm that my name is correct and matches my government issued ID.",
    "name": "Name",
    "network_error": "An error occurred. Please try again later.",
    "network_error_occurred": "Network error occurred. Please try again later.",
    "new_challenge": "New challenge",
    "new_password": "New password",
    "new_password_again": "New password again",
    "news_trading_fail_title": "News trading",
    "no_accounts_yet": "You don't have any accounts yet.",
    "no_messages": "You don't have any messages.",
    "objective": "Objective",
    "off": "off",
    "old_password": "Old password",
    "on": "on",
    "open_in_full_screen": "Open in full screen",
    "order_pay": "Order and pay",
    "passed": "Passed",
    "password": "Password",
    "password_must_be_6": "Password must be 6 characters or longer",
    "passwords_dont_match": "Passwords don't match",
    "pay_by_wire": "Pay by Wire",
    "pay_with_card": "Pay with Card",
    "payment": "Payment",
    "payment_amount": "Payment amount",
    "payments": "Payments",
    "paypal_account": "Paypal Account and Account Currency",
    "paypal_mobile": "Paypal Mobile",
    "phone_number": "Phone number",
    "platform": "Platform",
    "please_download_sign_and_upload": "Please download, sign and upload the signed agreement to My Documents.",
    "please_fill_in_blank_fields": "Please fill in blank fields",
    "please_wait": "Please wait...",
    "pressing_this_cancels": "Pressing this cancels the order and cannot be undone.",
    "privacy_policy": "privacy policy",
    "private": "Private",
    "profit": "Profit",
    "profit_target": "Profit Target",
    "prohibited_instruments": "Prohibited Instruments",
    "promo_code": "Promo code",
    "promo_code_not_valid": "Promo code is not valid!",
    "pw_reset_copy": "Your password has been reset. We have emailed you the new password. Please check your email inbox.",
    "pw_reset_title": "Password Reset",
    "read_more": "Read more",
    "reference_message": "Reference/message",
    "require_sl_fail_title": "Missing Stop Loss",
    "reset_pw_copy": "Please type here your email address and press Send. We will email you a link to reset your password.",
    "reset_pw_result": "We have emailed you a link to reset your password. Please check you email inbox.",
    "reset_pw_title": "Reset password",
    "result": "Result",
    "risk": "Risk",
    "risk_mode": "Risk mode",
    "save": "Save",
    "send": "Send",
    "sending": "Sending...",
    "share_account_metrix": "Share Account Metrix",
    "sharing_set": "Sharing Set",
    "show_previous_phase": "Show previous phase",
    "show_tos": "Show Terms of Use",
    "sign_out": "Sign out",
    "sign_up": "Sign up",
    "sign_up_confirm": "By signing up, you confirm that you agree to our",
    "signed_up": "You have signed up",
    "skrill_address": "Skrill Address and Account Currency",
    "spreads": "Spreads",
    "start_challenge": "Start Fidelcrest Challenge",
    "start_new_challenge": "Start a new challenge",
    "status": "Status",
    "successful": "Successful",
    "swift": "SWIFT",
    "trades_Buy": "Buy",
    "trades_CloseTime": "Time",
    "trades_EntryID": "Order",
    "trades_OpenPrice": "Price",
    "trades_OpenTime": "Time",
    "trades_Price": "Price",
    "trades_Profit": "Profit $",
    "trades_Sell": "Sell",
    "trades_StopLoss": "S/L",
    "trades_Symbol": "Symbol",
    "trades_TakeProfit": "T/P",
    "trades_Type": "Type",
    "trades_Volume": "Volume",
    "trading_instruments": "Trading  Instruments",
    "turn_sharing": "Turn Sharing",
    "upload": "Upload",
    "upload_here": "Upload here",
    "validate": "Validate",
    "want_newsletter": "I want to receive our newsletter.",
    "weekend_trading_fail_title": "Weekend trading",
    "wire_transfer": "Wire Transfer",
    "works_with": "Works with",
    "zipcode": "Zip / Postal Code",


    "broker": "Broker",
    "title": "Title",
    "required": "is required!",
    "type_here": "Type Here..",
    "error_file_required": "This field is required",
    "error_something_wrong": "Something went wrong!",
    "error_promo": "Promocode is not valid! ",
    "agree_general_message": "By Clicking Signing Up, you confirmed that you agree to our",
    "account_already": "Already have Account?",
    "error_email": "Email isn`t correct!",

    "usdt_address_required": 'USDT address is required',
    "support_message_required": "Message is required!",
    "support_topic_required": "Topic is required!",
    "support_subject_required": "Subject is required!",

    "statistic_statistic": "Statistic",
    "brokerage_brokerage": "Brokerage", // @todo have "brokerage": "Provider"
    "fidelcrest_ltd": "Fidelcrest Ltd",
    "back_to_dashboard": "Back to Dashboard",
    "create_an_account": "Create an Account",
    "no_account": "No Account?",
    "menu_my_banking_details": "My Banking Details",
    "personal_information": "Personal Information",
    "bad_request": "Bad request",
    "error_reload_page": "Please reload page and try again!",
    "error_not_found": "Not found",
    "error_not_found_text": "The requested URL was not found on this server",
    "metrix_history": "History",
    "bitcoin": "Bitcoin",
    //addOns
    "ADDON_INSURANCE": "Add Insurance (+30%)",
    "ADDON_DOUBLE_FUNDED_CAPITAL": "Double your capital (+50%)",
    "ADDON_LEVERAGE_200": "Double your leverage (+20%)",
    "ADDON_RETRY": "Unlimited retry (FREE)",
    "ADDON_SUPER_REFUND": "3X refund (FREE)",
    "D_ADDON_INSURANCE": "With this feature, traders have the opportunity to receive a new challenge account if they fail their account in Phase 1, Phase 2, or the funded phase.",
}
