import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import {
    IAuthResponse,
    IEmail,
    IEmailExist,
    ILoginPayload,
    IRegister,
} from '../../../store/user/user.interface'
import { saveToStorage } from '../auth/auth.helper'

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
    endpoints: (build) => ({
        signIn: build.mutation<IAuthResponse, ILoginPayload>({
            query: (body) => ({
                url: `/sign-in-2`,
                method: 'POST',
                body: { ...body },
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    saveToStorage(data)
                } catch (err) {
                    console.log('At the time on sign in save token went wrong!')
                }
            },
        }),
        signUp: build.mutation<IAuthResponse, IRegister>({
            query: (body) => ({
                url: '/sign-up-2',
                method: 'POST',
                body: { ...body },
            }),
        }),
        emailExist: build.mutation<IEmailExist, IEmail>({
            query: (body) => ({
                url: '/email-exists',
                method: 'POST',
                body: { ...body },
            }),
        }),
    }),
})
