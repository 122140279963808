import { FC, useState } from 'react'
import * as S from './footer-styles'
import { t } from '../../utils/translate/t'

const Footer: FC = () => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <>
            <S.FooterBox>
                <S.CopyrightText>
                    <S.TextTitle>{t('footer_1')}</S.TextTitle>
                    <S.TextBody>{t('footer_2')}</S.TextBody>
                    {/* @todo */}

                    <S.RiskTextWrapper>
                        <S.TextLink
                            onClick={() => {
                                setIsVisible(!isVisible)
                            }}
                        >
                            {t('footer_risk_policy_title')}
                        </S.TextLink>
                        <S.RiskPolicy className={isVisible ? 'visible' : ''}>
                            {t('footer_3')}
                        </S.RiskPolicy>
                    </S.RiskTextWrapper>
                </S.CopyrightText>
            </S.FooterBox>
        </>
    )
}

export default Footer
