import styled from 'styled-components'

export const FilterInstruments = styled.div`
    min-height: 132px;
    border-radius: 15px;
    background: ${({ theme }) => theme.colors.bgContent};
    margin: 30px 0;
    padding: 20px;
`

export const CategoriesList = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    row-gap: 10px;
`

export const Category = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 20px;
    gap: 10px;
    min-width: 97px;
    height: 42px;

    /* buttons */
    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};
    margin: 0 10px 0 0;
    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 10px;
    text-transform: capitalize;

    &.active {
        background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};
        //In my oppinion when the button is active the text on this btn must be a bold
        font-weight: 600;
    }

    &.dashboard {
        font-size: 13px;
        margin: 0 4px 0 0;
        padding: 0 8px;
        height: 33px;
        min-width: 0;
        font-weight: 500;
    }

    &:hover {
        cursor: pointer;
    }
`
