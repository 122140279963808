import { FC, MutableRefObject, ReactElement, useRef } from 'react'
import * as S from './accordionItem.styles'

interface IProps {
    active: number | null
    detail: ReactElement
    handleToggle: (id: number) => void
    header: ReactElement
    id: number
}

interface IDetailProps {
    active: number | null
    id: number
    contentEl: MutableRefObject<HTMLDivElement> // @todo Discuss MutableRefObject<HTMLDivElement | undefined> //RefObject<HTMLDivElement> // ForwardedRef<HTMLDivElement>
    children: ReactElement
}

const Detail: FC<IDetailProps> = ({ active, id, contentEl, children }) => {
    return (
        <>
            <S.Collapse
                ref={contentEl}
                className={`rc-collapse ${active === id ? 'show' : ''}`}
                style={
                    active === id ? { height: contentEl?.current?.scrollHeight } : { height: '0px' }
                }
            >
                {children}
            </S.Collapse>
        </>
    )
}

const AccordionItem: FC<IProps> = ({ handleToggle, active, detail, header, id }) => {
    const contentEl = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
    return (
        <>
            <S.AccordionToggle
                className={` ${active === id ? 'active' : ''}`}
                onClick={() => {
                    handleToggle(id)
                }}
            >
                {header}
            </S.AccordionToggle>
            <Detail contentEl={contentEl} active={active} id={id}>
                {detail}
            </Detail>
        </>
    )
}

export default AccordionItem
