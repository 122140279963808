import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { sharedAccountAPI } from '../../api/services/SharedService/SharedService'

const SharedDashboard: FC = () => {
    const { UUID } = useParams()
    const [accountID, setAccountID] = useState<string | null>(null)
    const {
        data: account,
        error: ErrorFetchSharedAccountID,
        isSuccess,
        isLoading,
    } = sharedAccountAPI.useFetchSharedAccountIDQuery(UUID || '')

    useEffect(() => {
        if (!account) {
            return
        }
        setAccountID(account.AccountID)
    }, [isSuccess])

    return (
        <>
            {isLoading && (
                <>
                    <div>Loading..</div>
                </>
            )}
            <div>Graph shared UUID by AccountID: {accountID}</div>
            {/* @todo show graph */}
        </>
    )
}
export default SharedDashboard
