import React from 'react'
import * as S from './guess.styles'
import LogoGroup from '../../components/LogoGroup/LogoGroup'
import { t } from '../../utils/translate/t'

const Guess: React.FC = (Props) => {
    return (
        <>
            <S.LoginPage>
                <S.PromoBanner>
                    Winter 2FOR1 offer enable: Promo code WIN-2FOR1 is valid until 29th of January
                </S.PromoBanner>
                <S.LoginPageWrapper>
                    <S.ImageWrapper>
                        <S.AwardsIcon>
                            Best
                            <br />
                            micro Forex
                            <br />
                            in Europe
                            <br />
                            2021
                        </S.AwardsIcon>
                        <S.AwardsIcon>
                            Best
                            <br />
                            proprietary
                            <br />
                            trading firm
                            <br />
                            UK 2022
                        </S.AwardsIcon>
                        <S.AwardsIcon>
                            Peoples's
                            <br />
                            choice
                            <br />
                            award
                            <br />
                            2021
                        </S.AwardsIcon>
                    </S.ImageWrapper>
                    <LogoGroup />
                    <S.ButtonsWrapper>
                        <S.StyledNavLink to={'/sign-up'}>
                            <S.BtnSignIn>{t('sign_up')}</S.BtnSignIn>
                        </S.StyledNavLink>
                        <S.StyledNavLink to={'/log-in'}>
                            <S.BtnLogIn>{t('log_in')}</S.BtnLogIn>
                        </S.StyledNavLink>
                    </S.ButtonsWrapper>
                    <S.RateWigetWrapper></S.RateWigetWrapper>
                </S.LoginPageWrapper>
            </S.LoginPage>
        </>
    )
}

export default Guess
