import { createSlice } from '@reduxjs/toolkit'

import { authAPI } from '../../api/services/auth/auth.service'
import { logout } from './user.actions'
import { IInitialState } from './user.interface'

const getLocalStore = (name: string) => {
    if (typeof localStorage !== 'undefined') {
        const ls = localStorage.getItem(name)
        return ls ? JSON.parse(ls) : null
    }
    return null
}

const initialState: IInitialState = {
    user: getLocalStore('user'),
    isLoading: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false
                state.user = null
            })

            .addMatcher(authAPI.endpoints.signUp.matchPending, (state, action) => {
                state.isLoading = true
            })
            .addMatcher(authAPI.endpoints.signUp.matchFulfilled, (state, action) => {
                state.isLoading = false
                state.user = action.payload
            })
            .addMatcher(authAPI.endpoints.signUp.matchRejected, (state, action) => {
                state.isLoading = false
                state.user = null
            })

            .addMatcher(authAPI.endpoints.signIn.matchPending, (state, action) => {
                state.isLoading = true
            })
            .addMatcher(authAPI.endpoints.signIn.matchFulfilled, (state, action) => {
                state.isLoading = false
                state.user = action.payload
            })
            .addMatcher(authAPI.endpoints.signIn.matchRejected, (state, action) => {
                state.isLoading = false
                state.user = null
            })
    },
})
