import { FC, ReactElement } from 'react'
import * as S from '../../../../pages/Metrix/metrix.styles'
import { IMetrixStats } from '../../../../DTO/IMetrix'
import { useSelector } from 'react-redux'
import { selectMetrixStat } from '../../../../features/metrixStatsSlice'
import { t } from '../../../../utils/translate/t'

type Rate = {
    title: string
    value?: number
    key?: string
}

const DaysRates: Rate[] = [
    { title: t('stats_PositiveDays'), key: 'PositiveDays' },
    { title: t('stats_NegativeDays'), key: 'NegativeDays' },
    { title: t('stats_NumberOfTrades'), key: 'NumberOfTrades' },
]
const Rates: Rate[] = [
    { title: t('stats_WinningRatePct'), key: 'WinningRatePct' },
    { title: t('stats_LosingRatePct'), key: 'LosingRatePct' },
    { title: t('stats_AverageProfit'), key: 'AverageProfit' },
]

const BalanceRates: Rate[] = [
    { title: t('stats_StartingBalance'), key: 'StartingBalance' },
    { title: t('stats_MaxBalance'), key: 'MaxBalance' },
    { title: t('stats_LastBalance'), key: 'LastBalance' },
    { title: t('stats_LastEquity'), key: 'LastEquity' },
]

const DailyRates: Rate[] = [
    { title: t('stats_CurrentDayStartEquity'), key: 'CurrentDayStartEquity' },
    { title: t('stats_CurrentDayResetsIn'), key: 'CurrentDayResetsIn' },
    { title: t('stats_CurrentDailyLossThreshold'), key: 'CurrentDailyLossThreshold' },
    { title: t('stats_CurrentMaximumLossThreshold'), key: 'CurrentMaximumLossThreshold' },
]

function getRateTableRows(stats?: IMetrixStats) {
    if (!stats) {
        return
    }
    let rows: ReactElement[] = []
    let row: ReactElement

    Rates.map((rate: Rate, index) => {
        row = (
            <S.TRow key={index}>
                <S.TData>{rate.title}</S.TData>
                <S.TData>{stats[rate.key! as keyof IMetrixStats] || 'XXX'}</S.TData>
            </S.TRow>
        )
        rows.push(row)
    })
    return rows
}

function getDaysTableRows(stats?: IMetrixStats) {
    if (!stats) {
        return
    }
    let rows: ReactElement[] = []
    let row: ReactElement

    DaysRates.map((rate: Rate, index) => {
        row = (
            <S.TRow key={index}>
                <S.TData>{rate.title}</S.TData>
                <S.TData>{stats[rate.key! as keyof IMetrixStats]}</S.TData>
            </S.TRow>
        )
        rows.push(row)
    })
    return rows
}

function getDailyTableRows(stats?: IMetrixStats) {
    if (!stats) {
        return
    }
    let rows: ReactElement[] = []
    let row: ReactElement

    DailyRates.map((rate: Rate, index) => {
        row = (
            <S.TRow key={index}>
                <S.TData>{rate.title}</S.TData>
                <S.TData>{stats[rate.key! as keyof IMetrixStats] || 'XXX'}</S.TData>
            </S.TRow>
        )
        rows.push(row)
    })
    return rows
}

function getBalanceTableRows(stats?: IMetrixStats) {
    if (!stats) {
        return
    }
    let rows: ReactElement[] = []
    let row: ReactElement

    BalanceRates.map((rate: Rate, index) => {
        row = (
            <S.TRow key={index}>
                <S.TData>{rate.title}</S.TData>
                <S.TData>{stats[rate.key! as keyof IMetrixStats] || 'XXX'}</S.TData>
            </S.TRow>
        )
        rows.push(row)
    })
    return rows
}

const Statistic: FC = () => {
    const statisticData = useSelector(selectMetrixStat)
    console.log('STAT', statisticData)
    // const { data: statisticData, isSuccess, error } = metrixAPI.useGetStatsQuery(props.accountID)
    // useEffect(() => {
    //     console.log(statisticData)
    // }, [isSuccess])

    return (
        <>
            <S.StatisticGroup>
                <S.GroupTitle>{t('statistic_statistic')}</S.GroupTitle>
                <S.TableGroupRow>
                    <S.StatisticTable>
                        <S.TBody>{getDailyTableRows(statisticData[0])}</S.TBody>
                    </S.StatisticTable>
                    <S.StatisticTable>
                        <S.TBody>{getBalanceTableRows(statisticData[0])}</S.TBody>
                    </S.StatisticTable>
                </S.TableGroupRow>
                <S.TableGroupRow>
                    <S.StatisticTable>
                        <S.TBody>{getDaysTableRows(statisticData[0])}</S.TBody>
                    </S.StatisticTable>
                    <S.StatisticTable>
                        <S.TBody>{getRateTableRows(statisticData[0])}</S.TBody>
                    </S.StatisticTable>
                </S.TableGroupRow>
            </S.StatisticGroup>
        </>
    )
}
export default Statistic
