import React, { ChangeEvent, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { t } from '../../utils/translate/t'
import { Input, InputButton } from './upload.styles'

interface IUpload {
    title: string
}

const Upload: React.FC<IUpload> = ({ title }) => {
    const [file, setFile] = useState<File>()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }

        const fileMb = e.target?.files[0]?.size / 1024 ** 2
        if (!fileMb || fileMb >= 40) {
            setIsSuccess(false)
            // @todo add some error message
            return
        }
        setFile(e.target?.files[0])
        setIsSuccess(true)
    }

    return (
        <div>
            <InputButton
                onClick={handleUploadClick}
                data-tooltip-id="input-tooltip"
                data-tooltip-content={t('accepted_file_types')}
                data-tooltip-place="bottom"
            >
                {title}
            </InputButton>

            <Input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                accept={t('accepted_file_types')}
                style={{ display: 'none' }}
            />
            <Tooltip id="input-tooltip" style={{ borderRadius: '10px' }} />
        </div>
    )
}

export default Upload
