import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.borderTable};
    padding: 27px 20px;
`
export const Table = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.bgContent};
    gap: 20px;

    & div div:first-child {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.textGrey};
    }

    & div div:last-child {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.bgMenu};
    }
`

export const PhaseTable = styled(Table)``

export const CredentialsTable = styled(Table)`
    margin-bottom: 20px;

    & text {
        white-space: nowrap;
    }
`
export const Tr = styled.div`
    display: flex;
`
export const Td = styled.div`
    display: flex;
    width: 50%;
`

export const TermUseBtn = styled(Link)`
    min-width: 279px;
    height: 48px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row;
    justify-content: center;

    text-decoration: none;
    color: ${({ theme }) => theme.colors.bgCurrentMenuItem};

    flex: none;
    order: 0;
    flex-grow: 0;

    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 10px;
    margin-bottom: 12px;
`

export const Metrix = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    min-width: 279px;
    height: 48px;

    color: ${({ theme }) => theme.colors.bgContent};
    text-decoration: none;
    background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    border-radius: 10px;
`
