import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'

export enum Status {
    Completed = 'Completed',
    Active = 'Active',
    Failed = 'Failed',
    AwaitingPayment = 'Awaiting Payment',
    Refunded = 'Refunded',
    Cancelled = 'Cancelled',
    Pending = 'Pending',
    InReview = 'In Review',
    AgreementPending = 'Agreement Pending',
}

export const StatusCode = {
    '-3': Status.Refunded,
    '-2': Status.Cancelled,
    '-1': Status.Failed,
    '0': Status.AwaitingPayment,
    '1': Status.Pending,
    '2': Status.Active,
    // '3': '— not used —',
    '4': Status.Completed,
    '5': Status.InReview,
    '6': Status.AgreementPending,
}

type StatusCodeType = typeof StatusCode
type StatusCodeKeyType = keyof StatusCodeType
export interface IAccountMetrix {
    AccountID: string
    Broker: string
    Platform: string
    Spreads: string
    Type: string
    Risk: string
    SalesPrice: string
    Ordered: string
    Status: StatusCodeKeyType
    AccountType: string
    FailReason: string | null
    BonusOption: string | null
    DerivedFrom: string | null
    ShareCode: string
    Shared: string
    MaximumLossThreshold: string
}

export interface IAccount {
    AccountID: string
    Broker: string
    Platform: string
    Spreads: string
    Type: string
    Risk: string
    SalesPrice: string
    Ordered: string
    Status: StatusCodeKeyType
    Payment: string
    Login: string
    Password: string
    Server: string
    Reported: string
    AgreementAccepted: string | null
    Phase: string | null
    AccountType: string
    BonusOption: string | null
    DerivedFrom: string | null
    MaximumLossThreshold: string
    Version: string
    Style: string
    PromoCode: string
    Country: string
    CompanyTaxNr: string | null
    cjevent: string | null
    Discount: string
    TrackersFired: string | null
    FireTrackersNow: string
    SalesNet: number
    FailReason?: string
    ShareCode?: string
    Shared?: string
}

export const accountServiceAPI = createApi({
    reducerPath: 'accountServiceAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getAccountsByUserID: build.query<IAccount[], number>({
            query: (accountID) => ({
                url: '/accounts/' + accountID,
            }),
        }),
    }),
})
