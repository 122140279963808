import React from 'react'
import Footer from '../../layouts/Footer/footer'
import Menu from '../../layouts/Menu/menu'
import { Outlet } from 'react-router-dom'
import Container from '../../layouts/container/container'
import Header, { IHeader } from '../../layouts/Header/header'
import { Main } from '../../layouts/MainElements/mainElements.styles'

const Layout: React.FC<IHeader> = (props, context) => {
    return (
        <>
            <Header {...props} />
            <Main>
                <Menu />
                <Container>
                    <Outlet />
                </Container>
            </Main>
            <Footer />
        </>
    )
}
export default Layout
