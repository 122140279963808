import { FC, ReactElement, useEffect, useState } from 'react'
import * as S from '../../../../pages/Metrix/metrix.styles'
import { metrixAPI } from '../../../../services/MetrixService/MetrixService'
import { HistoryTableTitle, IAccountID, IMetrixTrades } from '../../../../DTO/IMetrix'
import { t } from '../../../../utils/translate/t'

type THeadTitle = {
    title: string
}

const TableHistoryColumnTitle: HistoryTableTitle[] = [
    { title: t('trades_EntryID') },
    { title: t('trades_OpenTime') },
    { title: t('trades_Type') },
    { title: t('trades_Volume') },
    { title: t('trades_Symbol') },
    { title: t('trades_Price') },
    { title: t('trades_StopLoss') },
    { title: t('trades_TakeProfit') },
    { title: t('trades_CloseTime') },
    { title: t('trades_Price') },
    { title: t('trades_Profit') },
]
function getHistoryTableTitle() {
    let row: ReactElement[] = []
    let cell: ReactElement

    TableHistoryColumnTitle.map((value: THeadTitle) => {
        cell = <S.TDataHistoryHeader>{value.title}</S.TDataHistoryHeader>
        row.push(cell)
    })
    return row
}

function GetHistoryTableRows(orders: IMetrixTrades[]) {
    let rows: ReactElement[] = []
    let row: ReactElement

    orders?.map((order) => {
        var formatterNumber = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
        let formattedValue = (value: number) => {
            return value ? formatterNumber.format(value || 0) : '0.00'
        }

        let formatterDate = Intl.DateTimeFormat('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })

        let formattedDate = (date: Date) => {
            return formatterDate.format(date)
        }
        row = (
            <S.TRow key={order.EntryID}>
                <S.TDataHistory>{order.EntryID}</S.TDataHistory>
                <S.TDataHistory>{order.OpenTime}</S.TDataHistory>
                <S.TDataHistory>{order.Type}</S.TDataHistory>
                <S.TDataHistory>{formattedValue(order.Volume)}</S.TDataHistory>
                <S.TDataHistory>{order.Symbol}</S.TDataHistory>
                <S.TDataHistory>{formattedValue(order.OpenPrice)}</S.TDataHistory>
                <S.TDataHistory>{formattedValue(order.StopLoss)}</S.TDataHistory>
                <S.TDataHistory>{formattedValue(order.TakeProfit)}</S.TDataHistory>
                <S.TDataHistory>{order.CloseTime}</S.TDataHistory>
                <S.TDataHistory>{order.Price}</S.TDataHistory>
                <S.TDataHistory>{formattedValue(order.Profit)}</S.TDataHistory>
            </S.TRow>
        )

        rows.push(row)
    })
    return rows
}

const History: FC<IAccountID> = (props) => {
    const [tickets, setTickets] = useState<IMetrixTrades[]>([])
    const { data, error, isLoading, isSuccess } = metrixAPI.useGetTradesQuery(props.accountID)
    useEffect(() => {
        setTickets(data!)
        // console.log('TRADES DATA: ', tickets)
    }, [isSuccess])

    return (
        <>
            <S.HistoryTable>
                <S.GroupTitle>{t('metrix_history')}</S.GroupTitle>
                {isSuccess && (
                    <S.TableHistory>
                        <S.THead>
                            <S.TRow>{getHistoryTableTitle()}</S.TRow>
                        </S.THead>
                        <S.TBody>{GetHistoryTableRows(tickets)}</S.TBody>
                    </S.TableHistory>
                )}
            </S.HistoryTable>
        </>
    )
}
export default History
