import React from 'react'
import Tabs from '../../ui-kit/Tabs/Tabs'
import { t } from '../../utils/translate/t'
import CorpotateBillingInfo from '../CorporateBillingInfo/CorporateBillingInfo'
import PrivateBillingInfo from '../PrivateBillingInfo/PrivateBillingInfo'
import * as S from './billingInfo.styles'

export interface IBillingInfoAccount {
    companyName?: string
    companyRegNr?: string
    companyVatNr?: string
    firstName: string
    lastName: string
    email: string
    phone: string
    city: string
    address: string
    zipCode: string
    countryResidence: string
    promoCode: string
}

type Props = {
    billingInfo?: IBillingInfoAccount
}

const BillingInfo: React.FC<Props> = (props) => {
    // @todo slice dispatch PspOrder
    let tabProps = [
        {
            active: true,
            title: t('private'),
            component: <PrivateBillingInfo key={1} />,
        },
        {
            active: false,
            title: t('corporate'),
            component: <CorpotateBillingInfo key={2} />,
        },
    ]

    return (
        <S.StyledBillingInfo>
            <h3>{t('billing_info')}</h3>
            <S.StyledDescription>
                {t('before_start_info')}
            </S.StyledDescription>
            <Tabs tabs={tabProps} />
        </S.StyledBillingInfo>
    )
}

export default BillingInfo
