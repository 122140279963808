import styled from 'styled-components'
import { devices } from '../../devices'

export const FooterBox = styled.footer`
    display: flex;
    justify-self: flex-end;
    flex-direction: column;
    height: max-content;
    max-width: calc(100vw - 255px);
    margin: auto 0 0 240px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    &:after {
        content: ' ';
        background-color: ${({ theme }) => theme.colors.bgPage};
        width: 100%;
        height: 30px;
    }

    @media ${devices.md} {
        max-width: 100%;
        margin: auto 0 0;
    }
`
export const CopyrightText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    box-sizing: border-box;
`
export const TextTitle = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textStandart};
    padding-bottom: 15px;
`
export const TextBody = styled(TextTitle)`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textGrey};
    padding-bottom: 8px;
`
export const TextLink = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textLink};
    cursor: pointer;
`

export const RiskTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const RiskPolicy = styled.div`
    height: 1px;
    display: none;
    transition: all 0.3s ease 0s;

    &.visible {
        display: block;
        height: fit-content;
        margin-top: 15px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.textGrey};
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
    }
`
