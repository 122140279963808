import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { FLUSH, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants'
import storage from 'redux-persist/lib/storage'
import { leaderAPI } from '../api/services/LeaderService/LeaderService'
import { programAPI } from '../api/services/ProgramService/ProgramService'
import { pspOrderAPI } from '../api/services/PspOrderService/PspOrderService'
import { pspValidatePromocodeAPI } from '../api/services/PspValidatePromocodeService/PspValidatePromocodeService'
import { sharedAccountAPI } from '../api/services/SharedService/SharedService'
import { authAPI } from '../api/services/auth/auth.service'
import challengeSlice from '../features/challengeSlice'
import { accountServiceAPI } from '../services/AccountServise/AccountService'
import { bankingDetailsAPI } from '../services/BankingDetailsService/BankingDetailsService'
import { billingAPI } from '../services/BillingService/BillingService'
import { changePasswordAPI } from '../services/ChangePasswordService/ChangePasswordService'
import { competitionAPI } from '../services/CompetitionService/CompetitionService'
import { documentAPI } from '../services/DocumentsService/DocumentsService'
import { messageAPI } from '../services/MessageService/MessageService'
import { metrixAPI } from '../services/MetrixService/MetrixService'
import { profileAPI } from '../services/ProfileService/ProfileService'
import { shareMetrixAPI } from '../services/ShareMetrixService/ShareMetrixService'
import { menuSlice } from './menu/menu.slice'
import { userSlice } from './user/user.slice'
import { contactSupportApi } from '../services/ContactSupportService/ContactSupportService'
import metrixAccountSlice from '../features/metrixAccountSlice'
import metrixStatsSlice from '../features/metrixStatsSlice'

const persistConfig = {
    key: 'fidecrest',
    storage,
    whitelist: [],
}

const rootReducer = combineReducers({
    user: userSlice.reducer,
    menu: menuSlice.reducer,
    [leaderAPI.reducerPath]: leaderAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [bankingDetailsAPI.reducerPath]: bankingDetailsAPI.reducer,
    [changePasswordAPI.reducerPath]: changePasswordAPI.reducer,
    [competitionAPI.reducerPath]: competitionAPI.reducer,
    [shareMetrixAPI.reducerPath]: shareMetrixAPI.reducer,
    [accountServiceAPI.reducerPath]: accountServiceAPI.reducer,
    [documentAPI.reducerPath]: documentAPI.reducer,
    [messageAPI.reducerPath]: messageAPI.reducer,
    [programAPI.reducerPath]: programAPI.reducer,
    ['challenge']: challengeSlice,
    [pspOrderAPI.reducerPath]: pspOrderAPI.reducer,
    [pspValidatePromocodeAPI.reducerPath]: pspValidatePromocodeAPI.reducer,
    [sharedAccountAPI.reducerPath]: sharedAccountAPI.reducer,
    [billingAPI.reducerPath]: billingAPI.reducer,
    [metrixAPI.reducerPath]: metrixAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [contactSupportApi.reducerPath]: contactSupportApi.reducer,
    ['account']: metrixAccountSlice,
    ['metrixStats']: metrixStatsSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PERSIST, PURGE, REGISTER],
            },
            // serializableCheck: false,
        }).concat(
            leaderAPI.middleware,
            profileAPI.middleware,
            bankingDetailsAPI.middleware,
            changePasswordAPI.middleware,
            billingAPI.middleware,
            competitionAPI.middleware,
            shareMetrixAPI.middleware,
            accountServiceAPI.middleware,
            documentAPI.middleware,
            messageAPI.middleware,
            programAPI.middleware,
            pspOrderAPI.middleware,
            pspValidatePromocodeAPI.middleware,
            sharedAccountAPI.middleware,
            metrixAPI.middleware,
            authAPI.middleware,
            contactSupportApi.middleware,
        ),
})

export const persistor = persistStore(store)

export type TypeRootState = ReturnType<typeof rootReducer>
