import styled from 'styled-components'

export const ChoosePaymentCard = styled.div`
    max-width: 555px;
    height: auto;
    background: ${({ theme }) => theme.colors.bgContent};
    border-radius: 15px;
    padding: 20px;
`

export const ButtonWrapper = styled.div`
    margin-top: 30px;
    text-align: right;
`
