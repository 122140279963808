import styled from 'styled-components'

export const Description = styled.div`
    margin: 30px 30px 0 30px;
    max-width: 951px;
    & h3 {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.textStandart};
        padding-bottom: 20px;
    }
    & p {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.textGrey};
        font-size: 14px;
        padding-bottom: 5px;
    }
`
export const StartIdentityLinkButton = styled.a`
    background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    margin: 20px;
    padding: 14px 20px;
    border: none;
    border-radius: 10px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat, serif;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLogOutBtn};
    max-width: 250px;
`
export const IdentityCompletedHeader = styled.h3`
    text-align: center;
    margin: 20px;
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
`
