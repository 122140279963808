import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { IStyleTypeTrader } from '../../DTO/IProgram'
import { programAPI } from '../../api/services/ProgramService/ProgramService'
import { pspOrderAPI } from '../../api/services/PspOrderService/PspOrderService'
import {
    brokerChange,
    IChallState,
    initialCapitalChange,
    optionBrokers,
    optionPrices,
    optionsInitialCapital,
    optionsPlatforms,
    optionsRisks,
    optionsTraderTypes,
    platformChange,
    priceChange,
    riskChange,
    selectAllChallenge,
    spreadsChange,
    traderTypeChange,
} from '../../features/challengeSlice'
import { useAuth } from '../../hooks/useAuth'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import brokerageService from '../../services/BrokerageService/BrokerageService'
import ChallengeService, { IPrice } from '../../services/ChallengeService/ChallengeService'
import { t } from '../../utils/translate/t'
import ChallengeSelect from '../ChallengeSelect/ChallengeSelect'
import * as S from './startChallenge.styled'
import ChallengeAddOns from '../ChallengeAddOns/ChallengeAddOns'

const ChallengeSubHeader = styled(SubHeader)`
    margin-left: 0;
`

interface wlcfg {
    AddOns: any
}

declare global {
    interface Window {
        wlcfg: wlcfg
    }
}

const StartChallenge = () => {
    const dispatch = useDispatch()

    const {
        data: programs,
        isSuccess,
        error: fetchProgramsError,
    } = programAPI.useFetchProgramsQuery()

    const [pspOrder, response] = pspOrderAPI.usePspOrderMutation()

    const { user } = useAuth()
    const challenges = useSelector(selectAllChallenge)

    const getPrettierMoney = (num: number) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        return formatter.format(Number(num))
    }

    useEffect(() => {
        if (!programs) {
            return
        }
        let challengeService = ChallengeService(programs)
        if (undefined === challengeService) {
            return
        }

        // @see setReduxToolkitStore all needed values after success Server Response GET /programs
        dispatch(
            traderTypeChange({ traderType: challengeService.defaultValues.defaultStyleTraderType }),
        )
        dispatch(optionsTraderTypes({ optionsTraderTypes: challengeService.optionStyle }))
        dispatch(optionsInitialCapital({ optionsInitialCapital: challengeService.optionCapital }))
        dispatch(optionPrices({ optionsPrices: challengeService.optionPrices }))
        dispatch(spreadsChange({ spreads: challengeService.spreads }))
        dispatch(
            riskChange({
                risk: challengeService.defaultValues.risk,
            }),
        )
        dispatch(
            optionsRisks({
                optionsRisks: challengeService.optionRisks,
            }),
        )
        dispatch(
            platformChange({
                platform: challengeService.defaultValues.platform,
            }),
        )
        dispatch(
            optionsPlatforms({
                optionsPlatforms: challengeService.optionPlatforms,
            }),
        )
        dispatch(
            brokerChange({
                broker: challengeService.defaultValues.brokers,
            }),
        )
        // @todo rm after test
        // let st = structuredClone(challengeService.optionBrokers[0])
        // st.value = 'FXH_TEST'
        // st.name = 'FXH_TEST'
        dispatch(
            optionBrokers({
                optionsBrokers: challengeService.optionBrokers,
                // optionsBrokers: [challengeService.optionBrokers[0], st],
            }),
        )
    }, [isSuccess])

    useEffect(() => {
        let capitalByTraderType =
            challenges.challenge.optionsInitialCapital[
                Number(challenges.challenge.traderType.value)
            ]
        if (!capitalByTraderType) {
            return
        }
        dispatch(
            initialCapitalChange({
                initialCapital:
                    challenges.challenge.optionsInitialCapital[
                        Number(challenges.challenge.traderType.value)
                    ][0],
            }),
        )
    }, [challenges.challenge.traderType])

    useEffect(() => {
        let currentPrice: number = 0
        challenges.challenge.optionsPrices.forEach((priceItem: IPrice) => {
            if (
                priceItem.traderType.toString() === challenges.challenge.traderType.value &&
                priceItem.risk.toString() === challenges.challenge.risk.value &&
                priceItem.capital.toString() === challenges.challenge.initialCapital.value
            ) {
                currentPrice = priceItem.price
            }
        })
        dispatch(
            priceChange({
                price: currentPrice,
            }),
        )
    }, [
        challenges.challenge.traderType,
        challenges.challenge.risk,
        challenges.challenge.initialCapital,
    ])

    useEffect(() => {
        let riskTMP: number[] = []
        let riskElTMP
        challenges.challenge.optionsRisks[
            Number(challenges.challenge.initialCapital.value)
        ]?.forEach((riskItem) => {
            riskTMP.push(Number(riskItem.value))
            riskElTMP = riskItem
        })

        if (!riskTMP.includes(Number(challenges.challenge.risk.value))) {
            dispatch(
                riskChange({
                    risk: riskElTMP,
                }),
            )
        }
    }, [challenges.challenge.initialCapital])

    useEffect(() => {
        if (
            challenges.challenge.optionsInitialCapital.at(-1)?.at(-1)?.value ===
            challenges.challenge.initialCapital.value
        ) {
        }
    }, [challenges.challenge.initialCapital])

    const getInitialCapitalOptions = (challenges: IChallState) => {
        let items: ReactElement[] = []
        challenges.challenge.optionsInitialCapital[
            Number(challenges.challenge.traderType.value)
        ]?.forEach((capital, key) => {
            items.push(
                <S.StyledOption
                    key={key}
                    className={
                        challenges.challenge.initialCapital.name === capital.name ? 'active' : ''
                    }
                    onClick={() => {
                        dispatch(
                            initialCapitalChange({
                                initialCapital: {
                                    name: capital.name,
                                    value: capital.value,
                                },
                            }),
                        )
                    }}
                >
                    {capital.name}
                </S.StyledOption>,
            )
        })
        return items
    }

    const getRiskModeOptions = (challenges: IChallState): ReactElement[] => {
        let items: ReactElement[] = []

        let riskModeForCurrentCapital =
            challenges.challenge.optionsRisks[Number(challenges.challenge.initialCapital.value)]
        if (!riskModeForCurrentCapital) {
            return []
        }

        riskModeForCurrentCapital.forEach((risk, key) => {
            items.push(
                <S.StyledOption
                    key={key}
                    className={challenges.challenge.risk?.value == risk.value ? 'active' : ''}
                    onClick={() => {
                        dispatch(
                            riskChange({
                                risk: risk,
                            }),
                        )
                    }}
                >
                    {risk.name}
                </S.StyledOption>,
            )
        })
        return items
    }

    const getBrokerageOptions = (challenges: IChallState): ReactElement[] => {
        let items: ReactElement[] = []
        if (!challenges.challenge.optionsBrokers) {
            console.log('no bro opts')
            return []
        }
        challenges.challenge.optionsBrokers.forEach((broker, key) => {
            items.push(
                <S.StyledOption
                    key={key}
                    className={challenges.challenge.broker?.value === broker?.value ? 'active' : ''}
                    onClick={() => {
                        dispatch(
                            brokerChange({
                                broker: broker,
                            }),
                        )
                    }}
                >
                    {broker.name}
                </S.StyledOption>,
            )
        })

        return items
    }

    const getPlatformOptions = (challenges: IChallState): ReactElement[] => {
        let items: ReactElement[] = []
        if (!challenges.challenge.optionsPlatforms) {
            return []
        }
        challenges.challenge.optionsPlatforms.forEach((platform, key) => {
            items.push(
                <S.StyledOption
                    key={key}
                    className={
                        challenges.challenge.platform.value === platform.value ? 'active' : ''
                    }
                    onClick={() => {
                        dispatch(
                            platformChange({
                                platform: platform,
                            }),
                        )
                    }}
                >
                    {platform.name}
                </S.StyledOption>,
            )
        })
        return items
    }

    // @todo fetchProgramsError
    if (fetchProgramsError) {
        // console.log(fetchProgramsError)
    }

    console.log(brokerageService().brokerage)

    let order = {
        id: user?.id,
        capital: challenges.challenge.initialCapital,
        risk: challenges.challenge.risk,
        brokerage: 'FHX', //challenges.challenge.brokers[0]?.Symbol, //challenges.challenge.broker,
        platform: 0,
        spread: '',
        bonus: '',
        account_type: user?.company_name ? 'corporate' : 'private',
        country: user?.country, // both private / corp change user.country
        price: challenges.challenge.price,
        method: '',
        currency: null, // @TODO discuss WTF ? challenges.challenge.privateField.country,
        steps: 0,
        addons: [],
    }

    const getTraderTypeOptions = (challenges: IChallState): ReactElement[] => {
        let optinsMap = new Map<number, Map<number, IStyleTypeTrader>>()
        const optionsTraderTypes: any[] = JSON.parse(challenges.challenge.optionsTraderTypes)
        if (!optionsTraderTypes) {
            return []
        }
        optionsTraderTypes.forEach((value: any[], key) => {
            let optionsMapMap = new Map<number, IStyleTypeTrader>()
            optionsMapMap.set(value[0] as number, value[1] as IStyleTypeTrader)
            optinsMap.set(key, optionsMapMap)
        })
        let items: ReactElement[] = []
        for (let [key, value] of optinsMap) {
            value.forEach((traderType) => {
                items.push(
                    <S.StyledOption
                        key={key}
                        className={
                            challenges.challenge.traderType.key === traderType?.Symbol
                                ? 'active'
                                : ''
                        }
                        onClick={() => {
                            dispatch(
                                traderTypeChange({
                                    traderType: {
                                        key: traderType?.Symbol,
                                        name: traderType.Name,
                                        value: traderType.Style,
                                    },
                                }),
                            )
                        }}
                    >
                        {traderType.Name}
                    </S.StyledOption>,
                )
            })
        }
        return items
    }

    // console.log('bro: ', challenges.challenge.broker)
    // console.log('optionsBrokers: ', challenges.challenge.optionsBrokers)
    return (
        <>
            <S.StyledStartChallenge>
                <ChallengeSubHeader>{t('start_challenge')}</ChallengeSubHeader>

                <ChallengeSelect
                    title={t('trading_program')}
                    options={getTraderTypeOptions(challenges)}
                />

                <ChallengeSelect
                    title={t('initial_capital')}
                    options={getInitialCapitalOptions(challenges)}
                />

                <ChallengeSelect title={t('risk_mode')} options={getRiskModeOptions(challenges)} />
                <ChallengeSelect
                    title={t('brokerage_brokerage')}
                    options={getBrokerageOptions(challenges)}
                />

                <ChallengeSelect title={t('platform')} options={getPlatformOptions(challenges)} />

                <ChallengeAddOns />
            </S.StyledStartChallenge>
        </>
    )
}
export default StartChallenge
