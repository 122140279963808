import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import eyeIconCross from '../../img/svg/passwordInput/eye-crossed.svg'
import eyeIcon from '../../img/svg/passwordInput/eye_icon.svg'
import * as S from '../Guess/guess.styles'

export const LoginPage = styled(S.LoginPage)`
    @media screen and (${({ theme }) => theme.media.medium}) {
        height: 100%;
    }
`
export const PromoBanner = styled(S.PromoBanner)``
export const LoginPageWrapper = styled(S.LoginPageWrapper)``
export const ImageWrapper = styled(S.ImageWrapper)`
    @media screen and (${({ theme }) => theme.media.medium}) {
        width: 95vw;
        margin: 0 auto;
    }
`

export const AwardsIcon = styled(S.AwardsIcon)`
    text-transform: uppercase;
    padding-top: 45px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        padding-top: 40px;
        text-transform: unset;
    }
    @media screen and (${({ theme }) => theme.media.mobile}) {
        padding-top: 20px;
    }
`
export const TitleTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        margin-top: 0;
    }
`
export const GreetingText = styled.span`
    color: ${({ theme }) => theme.colors.textLogo};
    font-weight: 600;
    font-size: 32px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        font-size: 28px;
    }
`
export const PageTitle = styled(GreetingText)`
    font-size: 20px;
    padding-top: 20px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        font-size: 18px;
        padding-top: 10px;
    }
`
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    @media screen and (${({ theme }) => theme.media.medium}) {
        margin: 15px auto;
    }
`
interface IPageSwitcher {
    page?: string
}
export const InputWrapper = styled.div<IPageSwitcher>`
    position: relative;
    margin: 0 auto;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: ${(props) => (props?.page === 'loginPage' ? '80vw' : '100%')};
    }
`
export const Label = styled.label`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textLogo};
    position: relative;
    padding-bottom: 5px;
    width: 350px;
    margin: 0 auto;
    font-size: 12px;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 80vw;
    }
`
export const InputPassword = styled.input<IPageSwitcher>`
    color: ${(props) =>
        props?.page === 'loginPage' ? '#999999' : props.theme.colors.textStandart};

    background-color: ${(props) =>
        props?.page === 'loginPage' ? '#212121' : props.theme.colors.bgPage};

    width: 350px;
    border-radius: 10px;
    font-size: 12px;
    font-style: italic;
    padding: 15px 30px 15px 12px;
    margin: 0 auto 30px;
    border: 0;

    &::placeholder {
        color: ${({ theme }) => theme.colors.textGrey};
    }

    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`
export const ShowButton = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 14px;
`
export const EyeIcon = styled.div`
    width: 100%;
    background-image: url(${eyeIcon});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
`
export const EyeIconCross = styled(EyeIcon)`
    background-image: url(${eyeIconCross});
`
export const InputMail = styled(InputPassword)`
    background: ${({ theme }) => theme.colors.bgMenu};
    color: ${({ theme }) => theme.colors.textGrey};
    margin-bottom: 0;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 80vw;
    }
`
export const BtnLogIn = styled.button`
    background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    margin: 0 auto;
    width: 350px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 80vw;
    }
`
export const TextGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.textLogo};
    width: 340px;
`
export const ForgotLink = styled(NavLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLogo};
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin: 10px auto;
`
export const OldText = styled.div`
    color: ${({ theme }) => theme.colors.textGrey};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    @media screen and (${({ theme }) => theme.media.medium}) {
        padding: 0 20px;
    }
`
export const OldLink = styled(Link).attrs({
    target: '_blank',
    rel: 'noreferrer',
})`
    margin-right: 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLogo};
`
export const SignIn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-top: 30px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        padding-bottom: 20px;
        margin-top: 20px;
    }
`
export const SignInLabel = styled.div`
    margin-right: 15px;
`
export const SignInLink = styled(NavLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLink};
`
export const Error = styled.span<IPageSwitcher>`
    position: relative;
    color: ${({ theme }) => theme.colors.bgMsgCounter};
    font-size: 12px;
    text-align: left;
    width: 350px;
    margin: 0 auto 30px;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 80vw;
    }
`

export const PasswordInputError = styled.span<IPageSwitcher>`
    position: absolute;
    color: ${({ theme }) => theme.colors.bgMsgCounter};
    font-size: 12px;
    text-align: left;
    bottom: -15px;
    left: 0;

    margin: ${(props) => (props?.page === 'loginPage' ? '0px auto 30px' : '')};
`
