import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import { FrameWrapper } from '../EconomicCalendar/Calendar.styles'
import { t } from '../../utils/translate/t'

const RiskCalculator: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('risk_calculator'))
    }, [])
    return (
        <>
            <FrameWrapper>
                <iframe
                    width={'100%'}
                    height={'620px'}
                    style={{ border: 'none' }}
                    src="https://component.autochartist.com/rc/?broker_id=833&amp;token=9476b4099257ed55ab4d2ddce318e1a8&amp;expire=1717192800&amp;user=Fidelcrest&amp;locale=en&amp;account_type=LIVE&amp;css=https://broker-resources.autochartist.com/css/components/833-rc-app.css#!/ "
                ></iframe>
            </FrameWrapper>
        </>
    )
}
export default RiskCalculator
