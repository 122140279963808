import styled from 'styled-components'

export const InstrumentsBlock = styled.div`
    margin: 0 0 30px 0;
    background: ${({ theme }) => theme.colors.bgContent};
    border-radius: 15px;
    padding: 20px;
`

export const InstrumentsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`
export const Instrument = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 20px;
    gap: 10px;
    width: 115px;
    height: 42px;

    /* buttons */
    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};

    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 10px;

    &.active {
        background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    }

    &:hover {
        cursor: pointer;
    }
`

export const Back = styled.h3`
    position: relative;
    margin: 10px 0 20px 0;
    text-align: left;
    cursor: pointer;
    > div {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 12px;
    }
`
