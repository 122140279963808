import styled from 'styled-components'

import { devices } from '../../../devices'
import logo from '../../../img/svg/billing/billingLogo.svg'

export const TableWrapper = styled.div`
    max-width: 575px;
    border-radius: 15px;
    margin: 30px 30px 30px 0;
    padding: 20px;
    background: ${({ theme }) => theme.colors.bgContent};

    display: flex;
    flex-flow: column;
    align-items: flex-start;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));

    @media ${devices.xxl} {
        margin: 0;
        max-width: 555px;
        margin-bottom: 20px;
    }
`

export const Line = styled.hr`
    width: 575px;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.borderTable};
    margin-left: -20px;
    margin-top: 10px;
    margin-bottom: 20px;

    @media ${devices.xxl} {
        width: calc(100% + 40px);
    }
`
export const Logo = styled.div`
    width: 39px;
    height: 39px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    background-image: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
`

export const TitleData = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
    min-width: 97px;
    height: 44px;
    justify-content: space-between;

    color: ${({ theme }) => theme.colors.textStandart};

    & p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.textStandart};

        @media ${devices.md} {
            max-width: 92px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`
export const ItemCost = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    & p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.textLink};
    }
`

export const Title = styled.h3`
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 20px;

    color: ${({ theme }) => theme.colors.textStandart};
`
export const Body = styled.div`
    display: flex;
    flex-direction: column;
`
export const TableRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding-right: 10px;
    margin-bottom: 21px;
    max-width: 535px;
    & span {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;

        width: 280px;
        color: ${({ theme }) => theme.colors.textGrey};
        @media ${devices.md} {
            width: 180px;
            font-size: 12px;
        }
    }

    & p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.textStandart};

        @media ${devices.md} {
            font-size: 12px;
        }
    }
`
export const TableButton = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`
