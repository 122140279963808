import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import Layout from './components/Layout/Layout'
import BankingDetails from './pages/BankingDetails/BankingDetails'
import Billing from './pages/Billing/Billing'
import Challenge from './pages/Challenge/Challenge'
import ContactSupport from './pages/ContactSupport/ContactSupport'
import DashboardGET from './pages/Dashboard GET/Dashboard'
import Dashboard from './pages/Dashboard/Dashboard'
import Calendar from './pages/EconomicCalendar/Calendar'
import Guess from './pages/Guess/Guess'
import Instruments from './pages/Instruments/Instruments'
import Leaderboard from './pages/Leaderboard/Leaderboard'
import Login from './pages/Login/Login'
import Messages from './pages/Messages/Messages'
import Metrix from './pages/Metrix/Metrix'
import MyDocuments from './pages/MyDocuments/MyDocuments'
import { Pay } from './pages/Pay/Pay'
import Profile from './pages/Profile/Profile'
import RiskCalculator from './pages/RiskCalculator/RiskCalculator'
import SharedDashboard from './pages/SharedDashboard/SharedDashboard'
import Signup from './pages/Signup/Signup'
import VolatilityAnalysis from './pages/VolatilityAnalysis/VolatilityAnalysis'
import PrivateRoutes from './providers/PrivateRoutes'
import GlobalStyle from './styles/global'
import { darkTheme, lightTheme } from './theme'
import { t } from './utils/translate/t'

export interface IAppProps {
    setTitle: any
}

export enum Theme {
    light = 'light',
    dark = 'dark',
}

const App: React.FC = () => {
    const [theme, setTheme] = useState(Theme.light)
    const [open, setOpen] = useState(false)
    const [msgRoot, setMsgRoot] = useState(0)
    const [lang, setLang] = useState('en')
    const [tmp, setTmp] = useState(0)
    const dashboard = t('dashboard') 
    const [title, setTitle] = useState(dashboard)
    const refresh = () => {
        setTmp(tmp + 1)
    }
    const appProps = {
        open,
        setOpen,
        msgRoot,
        setMsgRoot,
        refresh,
        tmp,
        title,
        setTitle,
        theme,
        setTheme,
    }

    return (
        <ThemeContext.Provider value={theme === Theme.dark ? darkTheme : lightTheme}>
            <GlobalStyle />
            <BrowserRouter>
                <GlobalStyle />
                <Routes>
                    <Route path="sign-up" element={<Signup {...appProps} />} />
                    <Route path="log-in" element={<Login />} />
                    <Route path="/" element={<Guess />} />
                    <Route path="/" element={<Layout {...appProps} />}>
                        <Route element={<PrivateRoutes />}>
                            <Route index path="dashboard" element={<Dashboard {...appProps} />} />
                            <Route
                                index
                                path="dashboard-get"
                                element={<DashboardGET {...appProps} />}
                            />
                            <Route path="challenge" element={<Challenge {...appProps} />} />
                            <Route path="instruments" element={<Instruments {...appProps} />} />
                            <Route
                                path="leaderboard"
                                element={<Leaderboard {...appProps} />}
                            ></Route>
                            <Route path="documents" element={<MyDocuments {...appProps} />}></Route>
                            <Route path="messages" element={<Messages {...appProps} />}></Route>
                            <Route path="metrix/:id" element={<Metrix {...appProps} />} />
                            <Route path="shared/:UUID" element={<SharedDashboard />} />

                            {/* <Route path="testpage" element={<Test />}></Route> */}

                            <Route path="profile" element={<Profile {...appProps} />}></Route>
                            <Route
                                path="banking"
                                element={<BankingDetails {...appProps} />}
                            ></Route>
                            <Route path="billing" element={<Billing {...appProps} />}></Route>
                            <Route
                                path="/pay/:paymentMethod/:wallet/:amount"
                                element={<Pay />}
                            ></Route>
                            <Route path="calendar" element={<Calendar {...appProps} />}></Route>
                            <Route
                                path="calculator"
                                element={<RiskCalculator {...appProps} />}
                            ></Route>
                            <Route
                                path="analysis"
                                element={<VolatilityAnalysis {...appProps} />}
                            ></Route>
                            <Route path="support" element={<ContactSupport {...appProps} />}></Route>
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate replace to="/log-in" />} />
                </Routes>
            </BrowserRouter>
        </ThemeContext.Provider>
    )
}

export default App
