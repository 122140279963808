import React from 'react'
import * as S from './logoGroup.styles'

export default function LogoGroup() {
    return (
        <S.LogoGroup>
            <S.Logo src={'/svg/logo.svg'} alt="logo" />
            <S.Company>Fidelcrest</S.Company>
        </S.LogoGroup>
    )
}
