import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: max-content;
    min-width: 100%;
    background-color: ${({ theme }) => theme.colors.bgPage};
  }

  :root {
    --current-orange: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    --menu-color: ${({ theme }) => theme.colors.textMenu};
  }

  .Container {
    width: 1200px
  }

  [class*="__container"] {
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
  }

  html, body {
    height: 100%;
  }

  button {
    cursor: pointer;
  }
`
