import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'
import { IMetrixGraph, IMetrixStats, IMetrixTrades } from '../../DTO/IMetrix'

export const metrixAPI = createApi({
    reducerPath: 'metrixAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getTrades: build.query<IMetrixTrades[], string | undefined>({
            query: (accountID) => ({
                url: '/trades/' + accountID,
            }),
        }),
        getStats: build.query<IMetrixStats, string>({
            query: (accountID) => ({
                url: '/stats/' + accountID,
            }),
        }),
        getMetrix: build.query<IMetrixGraph[], string>({
            query: (accountID) => ({
                url: '/metrix/' + accountID,
            }),
        }),
    }),
})
