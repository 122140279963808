import { DevTool } from '@hookform/devtools'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateUserStorage } from '../../../api/services/auth/auth.helper'
import { useAuth } from '../../../hooks/useAuth'
import { profileAPI } from '../../../services/ProfileService/ProfileService'
import { ToastConfig } from '../../../ui-kit/Toast/Toast.config'
import { t } from '../../../utils/translate/t'
import CountrySelect from '../../CountrySelect/CountrySelect'
import * as S from '../../PrivateBillingInfo/privateBillingInfo.styles'
import { Error, ErrorBillingInfo } from '../../PrivateBillingInfo/privateBillingInfo.styles'

export type FormValues = {
    firstName: string
    lastName: string
    email: string
    phone: string
    city: string
    address: string
    zipCode: string
    countryResidence: string
    promoCode: string
}

const defaultValues = {
    firstName: 'Alex',
    lastName: 'Kim',
    email: 'superherokim2020@gmail.com',
    phone: '+37256173321',
    city: 'Tallinn',
    address: 'Narva mnt 1',
    zipCode: '10110',
    countryResidence: 'Germany',
    promoCode: '',
}

const PersonalInformationForm: React.FC = () => {
    const [canSubmit, setCanSubmit] = useState(false)
    const [changeProfile, response] = profileAPI.useChangeProfilePrivateMutation()
    const { user } = useAuth()
    const defaultCountryResidence = user?.country ? user?.country : 'UA' // EE, DK, DE, UA
    const form = useForm<FormValues>({
        defaultValues: {
            firstName: user?.first_name ? user?.first_name : defaultValues.firstName,
            lastName: user?.last_name ? user?.last_name : defaultValues.lastName,
            email: user?.email ? user?.email : defaultValues.email,
            phone: user?.phone ? user?.phone : defaultValues.phone,
            city: user?.city ? user?.city : defaultValues.city,
            address: user?.legal_address ? user?.legal_address : defaultValues.address,
            zipCode: user?.zip_code ? user?.zip_code : defaultValues.zipCode,
            countryResidence: user?.country ? user?.country : defaultValues.countryResidence,
            promoCode: '',
        },
    })
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = form

    useEffect(() => {
        if (response.isSuccess) {
            setCanSubmit(false)
            toast.success('Changes saved!', ToastConfig)
        }
    }, [response])

    const onSubmit = (data: FormValues) => {
        if (!user?.id) {
            console.log('User id not found')
            toast.error(t('error_something_wrong'), ToastConfig)
            return
        }
        const profilePayload = {
            id: +user?.id,
            account_type: 'private',
            country: data.countryResidence,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            city: data.city,
            legal_address: data.address,
            zip_code: data.zipCode,
        }
        try {
            changeProfile(profilePayload).unwrap()
            updateUserStorage('country', data.countryResidence)
            setCanSubmit(true)
        } catch (error) {
            console.log(error)
            toast.error(t('error_something_wrong'), ToastConfig)
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="FirstName">{t('first_name')}</S.StyledLabel>
                            <S.StyledInput
                                id="First Name"
                                type="text"
                                placeholder={t('type_here')}
                                {...register('firstName', {
                                    required: `${t('first_name')} ${t('required')}`,
                                })}
                            />
                            <Error>{!!errors.firstName}</Error>
                            <Error>{errors.firstName?.message}</Error>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="email">{t('email_address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'email'}
                                placeholder={t('type_here')}
                                {...register('email', {
                                    required: `${t('email_address')} ${t('required')}`,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: `${t('error_email')}`,
                                    },
                                    validate: (fieldValue) => {
                                        return (
                                            fieldValue !== 'admin@example.com' ||
                                            'Enter a diff email address'
                                        )
                                    },
                                })}
                            />
                            <Error>{!!errors.email}</Error>
                            <Error>{errors.email?.message}</Error>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="city">{t('city')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'city'}
                                {...register('city', {
                                    required: `${t('city')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <Error>{!!errors.city}</Error>
                            <Error>{errors.city?.message}</Error>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="zipCode">{t('zipcode')}</S.StyledLabel>
                            <S.StyledInput
                                type="number"
                                {...register('zipCode', {
                                    required: `${t('zipcode')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <Error>{!!errors.zipCode}</Error>
                            <Error>{errors.zipCode?.message}</Error>
                        </S.FormInputWrapper>
                    </S.Column>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="lastName">{t('last_name')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'lastName'}
                                {...register('lastName', {
                                    required: `${t('lastName')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <Error>{!!errors.lastName}</Error>
                            <Error>{errors.lastName?.message}</Error>
                        </S.FormInputWrapper>

                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="phone">{t('phone_number')}</S.StyledLabel>
                            <S.StyledInput
                                type="tel"
                                id={'phone'}
                                {...register('phone', {
                                    required: `${t('phone_number')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <Error>{!!errors.phone}</Error>
                            <Error>{errors.phone?.message}</Error>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="address">{t('address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'address'}
                                {...register('address', {
                                    required: `${t('address')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <Error>{!!errors.address}</Error>
                            <Error>{errors.address?.message}</Error>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="countryResidence">
                            {t('country_of_residence')}</S.StyledLabel>
                            <S.BillingInfoCountySelectWrap>
                                <CountrySelect
                                    register={register}
                                    required={true}
                                    setValue={form.setValue}
                                    defaultValue={
                                        user?.country
                                            ? user?.country
                                            : defaultValues.countryResidence
                                    }
                                />
                            </S.BillingInfoCountySelectWrap>
                            <ErrorBillingInfo>{errors.countryResidence?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                    </S.Column>
                </S.Wrapper>

                <DevTool control={control} />

                <S.ButtonWrapper>
                    <S.ColumnReverse>
                        <S.ResetBtn type={'reset'}>Reset</S.ResetBtn>
                    </S.ColumnReverse>

                    <S.ColumnReverse>
                        <S.SubmitBtn disabled={canSubmit} type={'submit'}>
                            {t('save')}
                        </S.SubmitBtn>
                    </S.ColumnReverse>
                </S.ButtonWrapper>
            </form>
            <ToastContainer />
        </>
    )
}

export default PersonalInformationForm
