import { FC } from 'react'
import * as S from '../AverageChallenge/startChallenge.styled'

export interface ChallengeOption {}

interface IChallengeSelect {
    title: string
    options: any[] //ReactElement<any, string | JSXElementConstructor<any>>[] | Element
}

const ChallengeSelect: FC<IChallengeSelect> = ({ options, title }) => {
    return (
        <>
            {options?.length > 1 ? (
                <>
                    <S.StyledOptionHeader>{title}</S.StyledOptionHeader>
                    <S.StyledOptionWrap>{options}</S.StyledOptionWrap>
                </>
            ) : null}
        </>
    )
}
export default ChallengeSelect
