import { IPspOrderPayload } from '../../DTO/IPspOrder'
import { IBillingField, IChallState } from '../../features/challengeSlice'
import { store } from '../../store/store'

const user = store.getState().user?.user

const pspOrderService = (challenges: IChallState) => {
    let json: IPspOrderPayload = {
        id: user?.id || '81269', // Number(user?.id || 81269)
        capital: challenges.challenge.initialCapital.value,
        risk: challenges.challenge.risk.value || '2',
        brokerage: challenges.challenge.broker.value || 'FXH',
        platform: Number(challenges.challenge.platform.value),
        spread: challenges.challenge.spreads.value,
        bonus: '',
        account_type: challenges.challenge.activeBillingInfo.toLowerCase() || 'private',
        country: user?.country || '',
        price: challenges.challenge.price,
        method: challenges.challenge.paymentMethod,
        currency: null,
        steps: 2, // @todo from config
        addons: [],
        first_name: setField(challenges, 'first_name')!,
        last_name: setField(challenges, 'first_name')!,
        email: setField(challenges, 'email')!,
        phone: setField(challenges, 'phone')!,
        city: setField(challenges, 'city')!,
        legal_address: setField(challenges, 'legal_address')!,
        zip_code: setField(challenges, 'legal_address')!,
        promo_code:
            challenges.challenge.activeBillingInfo === 'Private'
                ? challenges.challenge.privatePromo.valid
                    ? challenges.challenge.privatePromo.code
                    : ''
                : challenges.challenge.corporatePromo.valid
                ? challenges.challenge.corporatePromo.code
                : '',
    }

    if (challenges.challenge.activeBillingInfo === 'Corporate') {
        json.company_name = challenges.challenge.corporateField.company_name
        json.company_reg_nr = challenges.challenge.corporateField.company_reg_nr
        json.company_tax_nr = challenges.challenge.corporateField.company_tax_nr
    }

    return json
}

const setField = (challenges: IChallState, key: keyof IBillingField) => {
    return challenges.challenge.activeBillingInfo === 'Private'
        ? challenges.challenge.privateField[key]
        : challenges.challenge.corporateField[key]
}

export default pspOrderService
