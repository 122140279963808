import { useEffect, useState } from 'react'
import { IAppProps } from '../../App'
import DocsItem from '../../components/DocsItem/DocumentRow'
import { useAuth } from '../../hooks/useAuth'
import { documentAPI } from '../../services/DocumentsService/DocumentsService'
import { t } from '../../utils/translate/t'
import { docsSections } from './DocumentSections'
import * as S from './MyDocuments.styles'

const MyDocuments: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('documents'))
    }, [])
    const [dataSet, setDataSet] = useState(
        Array.from(docsSections).map(([key, value], index) => {
            return value
        }),
    )
    const [KYCStatus, setKYCStatus] = useState('0')
    const { user } = useAuth()

    const {
        data: KYCStatusData,
        isError: isKYCStatusError,
        isLoading: isKYCStatusLoading,
        isSuccess: isKYCStatusSuccess,
        error: KYCStatusError,
    } = documentAPI.useGetKYCStatusQuery()

    const {
        data: documents,
        isError,
        isLoading,
        isSuccess,
        error,
    } = documentAPI.useGetDocumentsQuery()

    useEffect(() => {
        if (!isError && documents) {
            for (const [key, value] of docsSections) {
                docsSections.get(key).documentData = []
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].Type === key) {
                        docsSections.get(key).documentData.push(documents[i])
                    }
                }
            }
            setDataSet(
                Array.from(docsSections).map(([key, value], index) => {
                    return value
                }),
            )
        }
    }, [isSuccess])

    useEffect(() => {
        if (!isKYCStatusError && KYCStatusData) {
            setKYCStatus(KYCStatusData?.KYCStatus)
        }
    }, [isKYCStatusSuccess])

    return (
        <>
            {KYCStatus != '9' && KYCStatus != '2' && (
                <S.Description>
                    <h3>{t('docs_intro')}</h3>
                    <p>{t('docs_info')}</p>
                </S.Description>
            )}

            {KYCStatus === '9' && (
                <S.ButtonWrap>
                    <S.StartIdentityLinkButton
                        href={
                            'https://' +
                            window.document.domain +
                            '/api3/sumsub.php?token=' +
                            user?.token
                        }
                        target="_blank"
                    >
                        Start identity verification
                    </S.StartIdentityLinkButton>
                </S.ButtonWrap>
            )}
            {KYCStatus === '2' && (
                <S.IdentityCompletedHeader>
                    Identity verification completed{' '}
                </S.IdentityCompletedHeader>
            )}
            {KYCStatus === '0' &&
                dataSet.map((value, index) => (
                    <DocsItem key={index} title={value.title} documentData={value.documentData} />
                ))}
        </>
    )
}

export default MyDocuments
