import styled, { css } from 'styled-components'
import iconMail from '../../img/svg/messages/icon_mail.svg'
import iconOpenMail from '../../img/svg/messages/icon_openMail.svg'
import iconArrow from '../../img/svg/status/dropDownArrow.svg'

const getArrowDown = (props: { rotate?: string; left?: string }) => css`
    ::after {
        content: ' ';
        padding-right: 10px;
        display: inline-flex;
        width: 13px;
        height: 8px;
        margin-left: ${props.left || 'auto'};
        background-image: url(${iconArrow});
        transform: ${props.rotate || ''};
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        justify-self: flex-end;
    }
`
const getMail = (props: { icon?: any }) => css`
    ::before {
        content: ' ';
        background-image: url(${props.icon || iconMail});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 26px;
        background-color: #fff;
        min-width: 30px;
        min-height: 30px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        padding: 3px;
        margin-right: 50px;
    }
`
const getOpenMail = getMail({ icon: iconOpenMail })
const getArrowUp = getArrowDown({ rotate: 'rotate(180deg)' })

export const MessagesListWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.bgContent};
    min-width: 190px;
    width: 100%;
    min-height: calc(100vh - 315px);
    height: max-content;
    margin: 30px auto;
    padding: 15px 30px;
    border-radius: 15px;
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const MessageDate = styled.div`
    margin: 10px 0;
`
export const MessageBody = styled.div``
export const MessageDetail = styled.div`
    margin: 0 30px 30px;
`

export const MessageTitle = styled.div`
    height: 50px;
    color: ${({ theme }) => theme.colors.textStandart};
    display: flex;
    padding: 30px 10px;
    align-items: center;
    border-bottom: 2px solid ${({ theme }) => theme.colors.borderTable};

    ${getArrowDown({})}
    ${getMail({})}
  .active > & {
        ${getArrowUp}
        ${getOpenMail}
    border-bottom: none;
    }
`
export const ErrorMessage = styled.div`
    padding: 50px;
    color: ${({ theme }) => theme.colors.bgMsgCounter};

    & p {
        font-size: 24px;
        font-weight: 600;
    }

    & span {
        font-size: 14px;
        font-weight: 500;
    }
`
