import { FC, ReactElement, useEffect, useState } from 'react'
import { IAppProps } from '../../App'
import Accordion from '../../components/Accordion/Accordion'
import PhaseDetail from '../../components/PhaseDetail GET/PhaseDetail'
import { useAuth } from '../../hooks/useAuth'
import {
    AwaitingPaymentStatus,
    CompletedStatus,
    FailedStatus,
    StatusDropDown,
    SubHeader,
} from '../../layouts/MainElements/mainElements.styles'
import {
    IAccount,
    Status,
    StatusCode,
    accountServiceAPI,
} from '../../services/AccountServise/AccountService'
import { t } from '../../utils/translate/t'
import DashboardStatusFilter, {
    DashboardStatus,
} from './DashboardStatusFilter/DashboardStatusFilter'
import * as S from './dashboard.styles'

enum Phases {
    Phase1 = '1',
    Phase2 = '2',
    Phase3 = '3',
}

interface IPhase {
    userId: number
    date: string
    status: Status
}

interface IGetPhasesProps {
    phaseNumber: number
    userId: number
    userToken: string
    phase1Items: IAccount[]
    phase2Items: IAccount[]
    phase3Items: IAccount[]
}

const GetPhases: FC<IGetPhasesProps> = ({
    phaseNumber,
    userId,
    userToken,
    phase1Items,
    phase2Items,
    phase3Items,
}) => {
    let phases: ReactElement[] = []
    let phasesDetails: IAccount[] = []
    let phase: ReactElement

    const [activeStatus, setActiveStatus] = useState<DashboardStatus>(DashboardStatus.Active)

    const getStatusElement = (status: Status) => {
        let statusElement: ReactElement
        let statusIcon: ReactElement

        switch (status) {
            case Status.Active:
            case Status.Completed:
                statusElement = (
                    <CompletedStatus>
                        <p>{t('account_status_4')}</p>
                    </CompletedStatus>
                )
                statusIcon = <S.CompletedIcon></S.CompletedIcon>
                break
            case Status.Failed:
                statusElement = (
                    <FailedStatus>
                        <p>{t('account_status_-1')}</p>
                    </FailedStatus>
                )
                statusIcon = <S.FailedIcon></S.FailedIcon>
                break
            case Status.AwaitingPayment:
                statusIcon = <S.AwaitingPaymentIcon></S.AwaitingPaymentIcon>
                statusElement = (
                    <AwaitingPaymentStatus>
                        <p>{t('account_status_0')}</p>
                    </AwaitingPaymentStatus>
                )
                break
            case Status.Pending:
                statusIcon = <S.PendingIcon></S.PendingIcon>
                statusElement = (
                    <AwaitingPaymentStatus>
                        <p>{t('account_status_1')}</p>
                    </AwaitingPaymentStatus>
                )
                break
            default:
                statusIcon = <S.FailedIcon></S.FailedIcon>
                statusElement = (
                    <FailedStatus>
                        <p>{t('account_status_-1')}</p>
                    </FailedStatus>
                )
                break
        }
        return [statusIcon, statusElement]
    }

    let items: IAccount[] = []
    switch (phaseNumber) {
        case 1:
            items = phase1Items
            break
        case 2:
            items = phase2Items
            break
        case 3:
            items = phase3Items
            break
    }

    let accordion: ReactElement

    items.forEach((phaseItem, key) => {
        const status = StatusCode[`${phaseItem.Status}`]
        const [statusIcon, statusElement] = getStatusElement(status)

        if ((activeStatus as unknown as Status) !== status) return
        phase = (
            <S.Phase>
                    {statusIcon}
                    <S.Columns>
                        <S.UserId>{phaseItem.AccountID}</S.UserId>
                        <S.PhaseDate>{phaseItem.Ordered}</S.PhaseDate>
                    </S.Columns>
                <StatusDropDown>{statusElement}</StatusDropDown>
            </S.Phase>
        )
        phases.push(phase)
        phasesDetails.push(phaseItem)
    })

    return (
        <S.Phases>
            {!!phases.length && (
                <DashboardStatusFilter status={activeStatus} setStatus={setActiveStatus} />
            )}
            <Accordion
                headerData={phases}
                detailData={phases.map((phase, index) => {
                    return (
                        <PhaseDetail
                            key={index}
                            accountData={phasesDetails[index]}
                            token={userToken}
                        />
                    )
                })}
            />
        </S.Phases>
    )
}

const Dashboard: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('dashboard'))
    }, [])

    const { user } = useAuth()
    const userId = Number(user?.id || 0)
    const userToken = user?.token || ''
    const [phase1Items, setPhase1Items] = useState<IAccount[]>([])
    const [phase2Items, setPhase2Items] = useState<IAccount[]>([])
    const [phase3Items, setPhase3Items] = useState<IAccount[]>([])
    const {
        data: accountsData,
        error: accountsDataError,
        isError,
        isLoading,
        isSuccess,
    } = accountServiceAPI.useGetAccountsByUserIDQuery(userId)
    useEffect(() => {
        if (!(isError || isLoading)) {
            if (accountsData) {
                let phase1: IAccount[] | never = []
                let phase2: IAccount[] | never = []
                let phase3: IAccount[] | never = []
                accountsData.forEach((item) => {
                    if (item.AccountType === Phases.Phase1) phase1.push(item)
                    if (item.AccountType === Phases.Phase2) phase2.push(item)
                    if (item.AccountType === Phases.Phase3) phase3.push(item)
                })
                setPhase1Items(phase1)
                setPhase2Items(phase2)
                setPhase3Items(phase3)
            }
        }
    }, [isSuccess])

    return (
        <>
            <S.Row>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 1 (Challenge)</SubHeader>
                        <GetPhases
                            phaseNumber={1}
                            userId={userId}
                            userToken={userToken}
                            phase1Items={phase1Items}
                            phase2Items={phase2Items}
                            phase3Items={phase3Items}
                        />
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 2 (Verification)</SubHeader>
                        <GetPhases
                            phaseNumber={2}
                            userId={userId}
                            userToken={userToken}
                            phase1Items={phase1Items}
                            phase2Items={phase2Items}
                            phase3Items={phase3Items}
                        />
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 3 (Professional)</SubHeader>
                        <GetPhases
                            phaseNumber={3}
                            userId={userId}
                            userToken={userToken}
                            phase1Items={phase1Items}
                            phase2Items={phase2Items}
                            phase3Items={phase3Items}
                        />
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
            </S.Row>
        </>
    )
}

export default Dashboard
