import styled from 'styled-components'
import { StyledInput } from '../../components/PrivateBillingInfo/privateBillingInfo.styles'

export const SupportForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    padding: 30px;
    min-height: 620px;
    width: 650px;
    background: ${({ theme }) => theme.colors.bgContent};
    border-radius: 15px;
`
export const Label = styled.label`
    display: flex;
    position: relative;
    line-height: 2;
    padding-top: 20px;
    flex-direction: column;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textStandart};
    width: 100%;

    &:nth-child(1) {
        padding-top: 0;
    }

    & > span {
        position: absolute;
        bottom: -25px;
    }
`
export const TopicSelect = styled.select``
export const SubjectInput = styled(StyledInput)`
    width: 100%;
    max-width: unset;
    background-color: ${({ theme }) => theme.colors.bgPage};
    color: ${({ theme }) => theme.colors.textStandart};
`
export const MessageTextArea = styled.textarea`
    height: 250px;
    border-radius: 15px;
    padding: 15px;
    border: none;
    resize: none;
    background-color: ${({ theme }) => theme.colors.bgPage};
    color: ${({ theme }) => theme.colors.textStandart};
`
export const BtnSend = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    border: none;
    border-radius: 15px;
    margin-top: 30px;
    width: 150px;
    height: 50px;
    color: ${({ theme }) => theme.colors.textLogo};
    background: ${({ theme }) => theme.colors.bgBtnSignIn};
`
