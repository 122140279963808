import styled from 'styled-components'
import closeIcon from '../../img/svg/challenge/close.svg'
import helpIcon from '../../img/svg/challenge/help.svg'

export const Close = styled.div`
    &:before {
        display: block;
        content: ' ';
        background-image: url(${closeIcon});
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        padding: 20px;
    }
`

export const Help = styled.span`
    position: relative;

    &:before {
        position: absolute;
        display: block;
        content: ' ';
        background-size: contain;
        background-image: url(${helpIcon});
        background-repeat: no-repeat;
        width: 20px;
        height: 24px;
        padding: 0 10px;
        top: -2px;
        left: 0;
    }
`
