import { FC } from 'react'
import QRCode from 'react-qr-code'
import { Tooltip } from 'react-tooltip'
import * as S from '../../../pages/Pay/Pay.styles'
import CopyToClipboard from '../../../ui-kit/CopyToClipboard/CopyToClipboard'
import { t } from '../../../utils/translate/t'

interface IBitcoinPayment {
    wallet: string
    amount: string
}

const Bitcoin: FC<IBitcoinPayment> = (props) => {
    return (
        <>
            <S.PageTitle>
                <h3>{t('btc_pay_with_bitcoin')}</h3>
            </S.PageTitle>
            <S.WhiteBackForQR>
                <QRCode value={'bitcoin:' + props.wallet + '?amount=' + props.amount} />
            </S.WhiteBackForQR>
            <S.TextWrapper>
                <S.Text>
                    {t('btc_send')}
                    <CopyToClipboard
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content={t('copy_to_clipboard')}
                        data-tooltip-place="bottom"
                    >
                        {props.amount}
                    </CopyToClipboard>
                    {t('btc_to')}
                </S.Text>
                <S.Text>
                    {t('btc_address')}:
                    <br />
                    <br />
                    <CopyToClipboard
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content={t('copy_to_clipboard')}
                        data-tooltip-place="bottom"
                    >
                        {props.wallet}
                    </CopyToClipboard>
                </S.Text>
                <Tooltip id="copy-tooltip" style={{ borderRadius: '10px' }} />
                <S.Text>
                    {t('cryptocurrency_operator_commission')}
                </S.Text>
            </S.TextWrapper>
        </>
    )
}
export default Bitcoin
