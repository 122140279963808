import styled from 'styled-components'

export const FrameWrapper = styled.div`
    margin: 30px 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: ${({ theme }) => theme.colors.textLogo};
    border-radius: 15px;
    overflow: hidden;
`
