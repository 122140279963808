export const docsSections = new Map()
docsSections.set('1', {
    title: 'Proof of ID Front',
    warning: true,
    documentData: [],
})
docsSections.set('2', {
    title: 'Proof of ID Back',
    warning: true,
    documentData: [],
})
docsSections.set('3', {
    title: 'Passport Photo Page',
    warning: true,
    documentData: [],
})
docsSections.set('5', {
    title: 'Selfie',
    warning: true,
    documentData: [],
})
docsSections.set('9', {
    title: 'Proof of address',
    warning: true,
    documentData: [],
})
docsSections.set('11', {
    title: 'Signed Assignment',
    warning: true,
    documentData: [],
})
