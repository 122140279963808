import { FC } from 'react'
import { LinkButton } from '../../../ui-kit/LinkButton/LinkButton.styles'
import { currencyFormat } from '../../../utils/currencyFormat'
import { t } from '../../../utils/translate/t'
import {
    Body,
    Header,
    ItemCost,
    Line,
    Logo,
    TableButton,
    TableRow,
    TableWrapper,
    Title,
    TitleData,
} from './billingTable.styles'

export interface IBillingTable {
    data: string
    price: number
    accountId: number
    capital: number
    risk: string
    broker: string
    platform: string
    spreads: string
    paymentMethod: string
}

const BillingTable: FC<IBillingTable> = ({
    data,
    price,
    accountId,
    capital,
    risk,
    broker,
    platform,
    spreads,
    paymentMethod,
}) => {
    const Risk = new Map()
    Risk.set('2', t('normal'))
    Risk.set('3', t('aggressive'))

    const Spreads = new Map()
    Spreads.set('r', 'Raw')
    Spreads.set('s', 'Standard')

    const Platform = new Map()
    Platform.set('4', 'Metatrader4')
    Platform.set('5', 'Metatrader5')

    const Brokerage = new Map()
    Brokerage.set('FXH', 'Foreign Exchange Clearing House')
    Brokerage.set('FCM', 'FC Markets')
    Brokerage.set('ICM', 'IC Markets')
    Brokerage.set('TRD', 'Tradeview')

    return (
        <TableWrapper>
            <Header>
                <TitleData>
                    <Logo />
                    <p>{data}</p>
                </TitleData>
                <ItemCost>
                    <p>{`${price} ${t('currency_eur')}`}</p>
                </ItemCost>
            </Header>
            <Line />
            <Title>
                {t('account')} {accountId}
            </Title>
            <Body>
                <Row title={t('capital')} text={currencyFormat(capital, 'USD')} />
                <Row title={t('risk')} text={Risk.get(risk)} />
                <Row title={t('broker')} text={Brokerage.get(broker)} />
                <Row title={t('platform')} text={Platform.get(platform)} />
                <Row title={t('spreads')} text={Spreads.get(spreads)} />
                <Row title={t('payment_method')} text={paymentMethod} />
            </Body>

            <TableButton>
                <LinkButton href="#">{t('receipt')}</LinkButton>
            </TableButton>
        </TableWrapper>
    )
}

export default BillingTable

interface IRow {
    title: string
    text: string
}

const Row: FC<IRow> = ({ title, text }) => {
    return (
        <TableRow>
            <span>{title}</span>
            <p>{text}</p>
        </TableRow>
    )
}
