import styled from 'styled-components'

import { breakpoints } from '../../devices'
import iconArrow from '../../img/svg/status/dropDownArrow.svg'

export const SubHeader = styled.h3`
    display: flex;
    align-items: center;
    /* Categories */
    height: 30px;
    margin: 0 0 20px 0;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    //line-height: 30px;
    letter-spacing: 0;
    text-align: left;

    /* identical to box height, or 30px */

    /* black */
    color: ${({ theme }) => theme.colors.textStandart};
`

export const CapitalizeSubHeader = styled(SubHeader)`
    text-transform: capitalize;
`

export const StatusDropDown = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    box-sizing: border-box;
    min-width: max-content;

    /* Auto layout */

    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    gap: 10px;
    //width: 134px;
    min-height: 31px;

    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 57px;
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 21px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    &:after {
        content: ' ';
        display: flex;
        width: 8px;
        height: 13px;

        background-image: url(${iconArrow});
        background-position: center;
        background-repeat: no-repeat;
    }

    @media (max-width: ${breakpoints.xs}) {
        > p {
            word-wrap: break-word;
            max-width: 85px;
            text-align: end;
        }
    }
`
export const CompletedStatus = styled(Status)`
    color: ${({ theme }) => theme.colors.bgCompletedSwitch};
`
export const FailedStatus = styled(Status)`
    color: ${({ theme }) => theme.colors.bgFailedStatus};
`

// @todo mb need. Let it be.
export const InProgressStatus = styled(Status)`
    color: ${({ theme }) => theme.colors.bgInProgressStatus};
`
export const AwaitingPaymentStatus = styled(Status)`
    color: ${({ theme }) => theme.colors.bgAwaitingPaymentStatus};
`

export const Main = styled.main`
    //max-width: calc(100vw - 300px); // @todo check on diff screen size , i check o n 1700px+
    overflow-x: overlay;
`
