import { FC, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const PrivateRoutes: FC = () => {
    const location = useLocation()
    const { user } = useAuth()
    useEffect(() => {}, [user])
    return user ? <Outlet /> : <Navigate to="/log-in" state={{ from: location }} />
}

export default PrivateRoutes
