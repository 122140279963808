import React, { ReactElement, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { IAppProps, Theme } from '../../App'
import { leaderAPI } from '../../api/services/LeaderService/LeaderService'
import { AccountSizeSelect } from '../../components/AccountSizeSelect/AccountSizeSelect'
import LeaderBoardService, {
    getSliceTop3Leaders,
    getSortedTopLeaders,
    ILeader,
} from '../../services/LeaderBoardService/LeaderBoardService'
import { t } from '../../utils/translate/t'
import * as S from './leaderboard.styles'
import { PaymentMethodsEnum } from '../../components/PaymentMethods/PaymentMethods'

function getLeadersForTable(leaders: Map<number, ILeader>): ReactElement[] {
    let rows: ReactElement[] = []
    let row: ReactElement
    let tdCurrentUser: ReactElement

    let sortedLeaders = [...leaders].sort((a, b) => a[1].place - b[1].place)
    const mapLeaders: Map<number, ILeader> = new Map(sortedLeaders)

    mapLeaders.forEach((leader: ILeader, order: number) => {
        let imgCountryFlag: ReactElement
        let countryFlag: string = leader.country.toLowerCase()
        imgCountryFlag = (
            <S.ImgWrapper>
                <S.ImgCountryFlag
                    src={require(`../../img/svg/flags/${countryFlag}.svg`)}
                    alt={leader.country}
                    title={leader.country}
                />
            </S.ImgWrapper>
        )

        if (leader.place <= 3) {
            return
        }
        tdCurrentUser = <S.StyledTd className={'userLabel'} />
        if (leader.isCurrentUser) {
            tdCurrentUser = (
                <S.StyledTd className={'userLabel'}>
                    <S.StyledTdLabel>You</S.StyledTdLabel>
                </S.StyledTd>
            )
        }
        row = (
            <S.StyledRow key={order} className={leader.isCurrentUser ? 'current' : ''}>
                {tdCurrentUser}
                <S.StyledTd className={'userPlace'}>{leader.place}</S.StyledTd>
                <S.StyledTd className={'userName'}>
                    <S.StyledTableCellText>{leader.name}</S.StyledTableCellText>
                </S.StyledTd>
                <S.StyledTd className={'userStartEquity'}>
                    ${leader.competitionStartEquity}
                </S.StyledTd>
                <S.StyledTd className={'userCurrEquity'}>${leader.equity}</S.StyledTd>
                <S.StyledTd className={'userProfit'}>{leader.profit}%</S.StyledTd>
                <S.StyledTd className={'userCountry'}>{imgCountryFlag}</S.StyledTd>
            </S.StyledRow>
        )
        rows.push(row)
    })
    return rows
}

function getLeadersForPodium(leaders: Map<number, ILeader>): ReactElement[] {
    let leaderPodiums: ReactElement[] = []
    let leaderPodium: ReactElement
    leaders.forEach((leader: ILeader, order: number) => {
        let imgCountryFlag: ReactElement
        let countryFlag: string = leader.country.toLowerCase()
        imgCountryFlag = (
            <S.ImgWrapper>
                <S.ImgCountryFlag
                    src={require(`../../img/svg/flags/${countryFlag}.svg`)}
                    alt={leader.country}
                    title={leader.country}
                />
            </S.ImgWrapper>
        )

        leaderPodium = (
            <S.StyledLeaderWrap key={order}>
                <S.StyledLeaderInfo className={'leaderPedestal'}>
                    <S.StyledLeaderName>
                        <span>{leader.name}</span>
                    </S.StyledLeaderName>
                    <S.StyledLeaderOtherInfo>
                        <S.StyledLeaderTextWrap>
                            <S.StyledLeaderTextInfo>{leader.profit}</S.StyledLeaderTextInfo>
                            <S.StyledLeaderTextTag>{t('profit')}</S.StyledLeaderTextTag>
                        </S.StyledLeaderTextWrap>
                        <S.StyledLeaderTextWrap>
                            {imgCountryFlag}
                            <S.StyledLeaderTextTag>{t('country')}</S.StyledLeaderTextTag>
                        </S.StyledLeaderTextWrap>
                    </S.StyledLeaderOtherInfo>
                    <S.StyledLeaderMetrixLink to={`/metrix/${leader.id}`}>
                        {t('metrix')}
                    </S.StyledLeaderMetrixLink>
                </S.StyledLeaderInfo>
            </S.StyledLeaderWrap>
        )
        leaderPodiums.push(leaderPodium)
    })
    return leaderPodiums
}

const Leaderboard: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('leaderboard'))
    }, [])

    const [accountSize, setAccountSize] = useState<number>(0)
    const {
        data: leaders,
        error,
        isLoading,
    } = accountSize === 0
        ? leaderAPI.useFetchAllLeadersQuery('')
        : leaderAPI.useFetchSizedLeadersQuery(accountSize)
    let errorPlaced = error && ('status' in error || 'data' in error)

    if (!leaders && errorPlaced) {
        return (
            <>
                {error && (
                    <>
                        {'status' in error && error.status === 401 ? (
                            <Navigate replace to="/log-in" />
                        ) : (
                            <></>
                        )}
                        {'data' in error ? <>{error.data}</> : <></>}
                    </>
                )}

                {error && (
                    <>
                        {'status' in error && error.status === 400 ? (
                            <S.ErrorMessage>
                                <p>
                                    ERROR {error.status}: {t('bad_request')}
                                </p>
                                <span>{t('error_reload_page')}</span>
                            </S.ErrorMessage>
                        ) : (
                            <></>
                        )}
                        {'data' in error ? <>{error.data}</> : <></>}
                    </>
                )}

                {error && (
                    <>
                        {'status' in error && error.status === 404 ? (
                            <S.ErrorMessage>
                                <p>
                                    ERROR {error.status}: {t('error_not_found')}
                                </p>
                                <span>{t('error_not_found_text')}</span>
                            </S.ErrorMessage>
                        ) : (
                            <></>
                        )}
                        {'data' in error ? <>{error.data}</> : <></>}
                    </>
                )}
            </>
        )
    }
    return (
        <>
            <span>{t(Theme.light)}</span>
            <span>{typeof Theme}</span>
            <span>{t('request_payout')}</span>
            <span>{PaymentMethodsEnum.ePayment}</span>
            <span>{t(PaymentMethodsEnum.Authorize.toLowerCase() + '_payment')}</span>
            <S.StyledTableWraper>
                <S.SelectSizeWrapper>
                    {isLoading ? (
                        <S.IsLoading>{t('please_wait')}</S.IsLoading>
                    ) : (
                        <AccountSizeSelect {...{ accountSize, setAccountSize }} />
                    )}
                </S.SelectSizeWrapper>
                <S.StyledLeaderPodium>
                    {leaders &&
                        getLeadersForPodium(
                            getSortedTopLeaders(getSliceTop3Leaders(LeaderBoardService(leaders))),
                        )}
                </S.StyledLeaderPodium>
                {leaders && leaders?.length > 0 && (
                    <S.TableWrapper>
                        <S.StyledTable>
                            <S.StyledTableHead>
                                <S.StyledRow>
                                    <S.StyledTh />
                                    <S.StyledTh>#</S.StyledTh>
                                    <S.StyledTh>{t('name')}</S.StyledTh>
                                    <S.StyledTh>{t('start_equity')}</S.StyledTh>
                                    <S.StyledTh>{t('current_equity')}</S.StyledTh>
                                    <S.StyledTh>{t('profit')}</S.StyledTh>
                                    <S.StyledTh>{t('country')}</S.StyledTh>
                                </S.StyledRow>
                            </S.StyledTableHead>
                            <S.StyledTableBody>
                                {getLeadersForTable(LeaderBoardService(leaders))}
                            </S.StyledTableBody>
                        </S.StyledTable>
                        <S.LastUpdate>
                            {t('last_update')}: {leaders[0]?.Reported}
                        </S.LastUpdate>
                    </S.TableWrapper>
                )}
            </S.StyledTableWraper>
        </>
    )
}

export default Leaderboard
