import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'
import { IShareMetrixPayload, IShareMetrixResponse } from '../../DTO/IMetrix'

export interface IShareMetrixDTO {
    AccountID: string
    Broker: string
    Platform: string
    Spreads: string
    Type: string
    Risk: string
    SalesPrice: string
    Ordered: string
    Status: string
    AccountType: string
    FailReason: string
    BonusOption: string
    DerivedFrom: string
    ShareCode: string
    Shared: string
    MaximumLossThreshold: string
}

export const shareMetrixAPI = createApi({
    reducerPath: 'sharedMetrixAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        toggleShareMetrix: build.mutation<IShareMetrixResponse, IShareMetrixPayload>({
            query: (payload) => ({
                url: '/share-metrix',
                method: 'POST',
                body: { ...payload },
            }),
        }),
        getAccountByID: build.query<IShareMetrixDTO, number>({
            query: (accountID) => ({
                url: '/account/' + accountID,
            }),
        }),
    }),
})
