import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import { FrameWrapper } from './Calendar.styles'
import { t } from '../../utils/translate/t'

const Calendar: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('economic_calendar'))
    }, [])

    return (
        <>
            <FrameWrapper>
                <iframe
                    width={'100%'}
                    height={'4500px'}
                    src={'calendar/calendar.html'}
                    style={{ border: 'none' }}
                ></iframe>
            </FrameWrapper>
        </>
    )
}
export default Calendar
