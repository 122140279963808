import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
    corporatePromoChange,
    privatePromoChange,
    selectAllChallenge,
} from '../../features/challengeSlice'

import { pspOrderAPI } from '../../api/services/PspOrderService/PspOrderService'
import checkRedirectService from '../../services/CheckRedirectService/CheckRedirectService'
import pspOrderService from '../../services/pspOrderService/pspOrderService'
import { Error } from '../../ui-kit/Error/Error.styles'
import PaymentMethods, { PaymentMethodsEnum } from '../PaymentMethods/PaymentMethods'
import * as S from './orderAndPay.styles'
import { t } from '../../utils/translate/t'

let choices = {
    capital: [
        { symbol: '10000', title: '$10,000', amount: 10000, price: 99, aggressive: 149 },
        { symbol: '15000', title: '$15,000', amount: 15000, price: 149, aggressive: 249 },
        { symbol: '25000', title: '$25,000', amount: 25000, price: 199, aggressive: 349 },
        { symbol: '75000', title: '$75,000', amount: 75000, price: 349, aggressive: 549 },
        { symbol: '150000', title: '$150,000', amount: 150000, price: 549, aggressive: 799 },
        { symbol: '250000', title: '$250,000', amount: 250000, price: 849, aggressive: 1399 },
        { symbol: '500000', title: '$500,000', amount: 500000, price: 1499, aggressive: 250000 },
    ],
    risk: [
        { symbol: '2', title: t('normal') },
        { symbol: '3', title: t('aggressive') },
    ],
    brokerage: [
        { symbol: 'FXH', title: 'Foreign Exchange Clearing House' },
        { symbol: 'FCM', title: 'FC Markets', fontSize: '12px' },
        { symbol: 'ICM', title: 'IC Markets' },
        { symbol: 'TRD', title: 'Tradeview' },
    ],
    platform: [
        { symbol: '4', title: 'Metatrader4' },
        { symbol: '5', title: 'Metatrader5' },
    ],
    spread: [
        { symbol: 's', title: 'Standard' },
        { symbol: 'r', title: 'Raw' },
    ],
    byKey: {},
}

interface IFormValues {
    agreeTerms: boolean
    agreeGeneral: boolean
    agreePolicy: boolean
    type: PaymentMethodsEnum
}

const OrderAndPay: FC = () => {
    const [isSubmit, setIsSubmit] = useState(false)
    const challenges = useSelector(selectAllChallenge)
    const dispatch = useDispatch()
    const [sendPspOrder, responsePspOrder] = pspOrderAPI.usePspOrderMutation()
    const navigate = useNavigate()

    const Total = (
        <S.StyledTotal>
            {t('currency_eur')} {challenges.challenge.price}
        </S.StyledTotal>
    )

    const form = useForm<IFormValues>({
        defaultValues: {
            agreeTerms: false,
            agreeGeneral: false,
            agreePolicy: false,
            type: PaymentMethodsEnum.Decta,
        },
    })
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isDirty, isValid },
    } = form

    const onSubmit: SubmitHandler<IFormValues> = async (data, event) => {
        // event?.preventDefault()
        // event.stopPropagation()
        switch (challenges.challenge.activeBillingInfo) {
            case 'Private':
                setIsSubmit(true)
                if (
                    !challenges.challenge.privatePromo.valid &&
                    challenges.challenge.privatePromo.code !== ''
                ) {
                    console.log('Error validate promocode')
                    dispatch(
                        privatePromoChange({
                            ...challenges.challenge.privatePromo,
                            error: true,
                            isEmpty: challenges.challenge.privatePromo.code.length === 0,
                        }),
                    )

                    setIsSubmit(false)
                }

                break
            case 'Corporate':
                setIsSubmit(true)
                if (
                    !challenges.challenge.corporatePromo.valid &&
                    challenges.challenge.corporatePromo.code !== ''
                ) {
                    console.log('Error validate promocode')
                    dispatch(
                        corporatePromoChange({
                            ...challenges.challenge.corporatePromo,
                            error: true,
                            isEmpty: challenges.challenge.corporatePromo.code.length === 0,
                        }),
                    )

                    setIsSubmit(false)
                }
                break

            default:
                console.log(
                    `Something went wrong, redux cant get 2 tabs please contact support and show this Store error msg`,
                )
                break
        }
    }

    // need rerender to update state variable in render
    useEffect(() => {
        if (isSubmit) {
            // console.log('payment method', challenges.challenge.paymentMethod)
            const pspOrderPayload = pspOrderService(challenges)
            console.log('pspOrderPayload', pspOrderPayload)
            try {
                sendPspOrder(pspOrderPayload).unwrap()
            } catch (error) {
                console.log(error)
            }
            setIsSubmit(false)
        }
    }, [isSubmit])

    useEffect(() => {
        if (responsePspOrder.isSuccess) {
            console.log('responsePspOrder', responsePspOrder)
            if (!responsePspOrder.data.checkout) {
                console.log('Something went wrong with redirect! ')
                return
            }
            console.log('responsePspOrder data', responsePspOrder.data.checkout)

            let URL = checkRedirectService(
                responsePspOrder.data,
                challenges.challenge.price,
                challenges.challenge.paymentMethod,
            )
            if (URL) navigate(URL)
        }
    }, [responsePspOrder.isSuccess])

    return (
        <S.OrderAndPayBlock>
            <S.StyledSubHeader>{t('order_pay')}</S.StyledSubHeader>
            <S.StyledTotalBlock>
                {Total}
                <S.StyledLabel>{t('including_vat')}</S.StyledLabel>
            </S.StyledTotalBlock>

            <S.OrderAndPayForm action="">
                <S.Label htmlFor="terms">
                    <S.StyledCheckbox
                        id="terms"
                        {...register('agreeTerms', {
                            required: `${t('agree_tos')} ${t('required')}`,
                        })}
                    />
                    <S.Wrap>
                        <p>{t('i_agree_to')}</p>
                        <Link to={'https://trade.fidelcrest.com/api3/tos6.php'} rel="noreferrer">
                            {t('agree_tos')}
                        </Link>
                    </S.Wrap>
                </S.Label>
                {errors.agreeTerms && <Error>{`${t('agree_tos')} ${t('required')}`}</Error>}
                <S.Label htmlFor="General_Terms">
                    <S.StyledCheckbox
                        id="General_Terms"
                        {...register('agreeGeneral', {
                            required: `${t('agree_tos')} ${t('required')}`,
                        })}
                    />
                    <S.Wrap>
                        <p>{t('i_agree_to')}</p>
                        <Link
                            target="_blank"
                            rel="noreferrer"
                            to={'https://fidelcrest.com/general-terms-and-conditions'}
                        >
                            {t('agree_general')}
                        </Link>
                    </S.Wrap>
                </S.Label>
                {errors.agreeGeneral && <Error>{t('general_terms')}</Error>}
                <S.Label htmlFor="Refund">
                    <S.StyledCheckbox
                        id="Refund"
                        {...register('agreePolicy', {
                            required: `${t('agree_refund')} ${t('required')}`,
                        })}
                    />
                    <S.Wrap>
                        <p>{t('i_agree_to')}</p>
                        <Link
                            target="_blank"
                            rel="noreferrer"
                            to={'https://fidelcrest.com/refund-and-cancellation-policy'}
                        >
                            {t('agree_refund')}
                        </Link>
                    </S.Wrap>
                </S.Label>
                {errors.agreePolicy && <Error>{`${t('agree_refund')} ${t('required')}`}</Error>}
                {/* {promoError && <Error>Promocode is not valid!</Error>} */}
                {/* <S.StyledButton>Pay Now</S.StyledButton> */}
                <PaymentMethods submitOrder={handleSubmit(onSubmit)} />
            </S.OrderAndPayForm>
        </S.OrderAndPayBlock>
    )
}
export default OrderAndPay
