import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'

interface IProfilePayload {
    id: number
    account_type: string
    country: string
    first_name: string
    last_name: string
    email: string
    phone: string
    city: string
    legal_address: string
    zip_code: string
}

interface IProfileResponse {
    ok: number
    updated: number
}

interface ProfileCorporatePayload extends IProfilePayload {
    company_name: string
    company_reg_nr: string
    company_tax_nr: string
}

export const profileAPI = createApi({
    reducerPath: 'profileAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        changeProfilePrivate: build.mutation<IProfileResponse, IProfilePayload>({
            query: (payload) => ({
                url: '/profile',
                method: 'POST',
                body: { ...payload },
            }),
        }),
        changeProfileCorporate: build.mutation<IProfileResponse, ProfileCorporatePayload>({
            query: (payload) => ({
                url: '/profile',
                method: 'POST',
                body: { ...payload },
            }),
        }),
    }),
})
