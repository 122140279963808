import styled from 'styled-components'
import { devices } from '../../devices'

export const CheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`
export const CheckBoxInput = styled.input`
    width: 20px;
    height: 20px;
`
export const CheckBoxLabel = styled.label`
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat, serif;
    color: #999999;
    @media ${devices.lg} {
        font-size: 14px;
    }
`
