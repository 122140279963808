import { DevTool } from '@hookform/devtools'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { updateUserStorage } from '../../api/services/auth/auth.helper'
import { IBillingField, privateFieldChange } from '../../features/challengeSlice'
import { useAuth } from '../../hooks/useAuth'
import { t } from '../../utils/translate/t'
import CountrySelect from '../CountrySelect/CountrySelect'
import Promocode from '../Promocode/Promocode'
import * as S from './privateBillingInfo.styles'
import { ErrorBillingInfo } from './privateBillingInfo.styles'

export type FormValues = {
    firstName: string
    lastName: string
    email: string
    phone: string
    city: string
    address: string
    zipCode: string
    countryResidence: string
    promoCode: string
}

const defaultValues = {
    firstName: 'Alex',
    lastName: 'Kim',
    email: 'superherokim2020@gmail.com',
    phone: '+37256173321',
    city: 'Tallinn',
    address: 'Narva mnt 1',
    zipCode: '10110',
    countryResidence: 'Germany',
    promoCode: '',
}

const PrivateBillingInfo: React.FC = () => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const defaultCountryResidence = user?.country ? user?.country : 'UA' // EE, DK, DE, UA
    const form = useForm<FormValues>({
        mode: 'onBlur',
        defaultValues: {
            firstName: user?.first_name ? user?.first_name : defaultValues.firstName,
            lastName: user?.last_name ? user?.last_name : defaultValues.lastName,
            email: user?.email ? user?.email : defaultValues.email,
            phone: user?.phone ? user?.phone : defaultValues.phone,
            city: user?.city ? user?.city : defaultValues.city,
            address: user?.legal_address ? user?.legal_address : defaultValues.address,
            zipCode: user?.zip_code ? user?.zip_code : defaultValues.zipCode,
            countryResidence: user?.country ? user?.country : defaultValues.countryResidence,
            promoCode: '',
        },
    })
    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid, isDirty },
    } = form

    const options = []

    useEffect(() => {
        const data = getValues()
        console.log('save private form in state')
        handleBlur(data)
    }, [])

    const handleBlur = (data: FormValues) => {
        if (!isValid) return
        console.log('form submit', data)
        updateUserStorage('country', data.countryResidence)
        const privateField: IBillingField = {
            country: data.countryResidence,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            city: data.city,
            legal_address: data.address,
            zip_code: data.zipCode,
            promo: data.promoCode,
        }
        dispatch(privateFieldChange({ privateField }))
    }

    return (
        <>
            <form onBlur={handleSubmit(handleBlur)}>
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="FirstName">{t('first_name')}</S.StyledLabel>

                            <S.StyledInput
                                id="First Name"
                                type="text"
                                placeholder={t('type_here')}
                                {...register('firstName', {
                                    required: `${t('first_name')} ${t('required')}`,
                                })}
                            />
                            <ErrorBillingInfo>{errors.firstName?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="email">{t('email_address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'email'}
                                placeholder={t('type_here')}
                                {...register('email', {
                                    required: `${t('email_address')} ${t('required')}`,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: `${t('error_email')}`,
                                    },
                                    validate: (fieldValue) => {
                                        return (
                                            fieldValue !== 'admin@example.com' ||
                                            'Enter a diff email address'
                                        )
                                    },
                                })}
                            />
                            <S.ErrorBillingInfo>{errors.email?.message}</S.ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="city">{t('city')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'city'}
                                {...register('city', {
                                    required: `${t('city')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorBillingInfo>{errors.city?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="zipCode">{t('zipcode')}</S.StyledLabel>
                            <S.StyledInput
                                type="number"
                                {...register('zipCode', {
                                    required: `${t('zipcode')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorBillingInfo>{errors.zipCode?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                    </S.Column>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="lastName">{t('last_name')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'lastName'}
                                {...register('lastName', {
                                    required: `${t('last_name')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorBillingInfo>{errors.lastName?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="phone">{t('phone_number')}</S.StyledLabel>
                            <S.StyledInput
                                type="tel"
                                id={'phone'}
                                {...register('phone', {
                                    required: `${t('phone_number')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorBillingInfo>{errors.phone?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="address">{t('address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'address'}
                                {...register('address', {
                                    required: `${t('address')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorBillingInfo>{errors.address?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="countryResidence">
                                {t('country_of_residence')}
                            </S.StyledLabel>
                            <S.BillingInfoCountySelectWrap>
                                <CountrySelect
                                    register={register}
                                    required
                                    setValue={form.setValue}
                                    defaultValue={defaultCountryResidence}
                                />
                            </S.BillingInfoCountySelectWrap>
                            <ErrorBillingInfo>{errors.countryResidence?.message}</ErrorBillingInfo>
                        </S.FormInputWrapper>
                    </S.Column>
                </S.Wrapper>

                <DevTool control={control} />
            </form>

            <Promocode
                registerParent={register}
                promoCodeError={errors.promoCode}
                setValue={setValue}
            />
        </>
    )
}

export default PrivateBillingInfo
