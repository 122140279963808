import { forwardRef, InputHTMLAttributes } from 'react'
import { Input, SpanWrap } from './textInput.styles'

export interface IField extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    placeholder: string
    Icon?: string //IconType;
    error?: string
}

const TextInput = forwardRef<HTMLInputElement, IField>(
    ({ label, placeholder, error, className, type = 'text', style, Icon, ...rest }, ref) => {
        return (
            <div className={className} style={style}>
                <label className="">
                    <SpanWrap>
                        {/* {Icon && <Icon className="" />} */}
                        {label}
                    </SpanWrap>
                    <Input
                        ref={ref}
                        type={type}
                        placeholder={placeholder}
                        className={!!error ? 'border-red' : ''}
                        {...rest}
                    ></Input>
                </label>
                {error && <div className={'text-red mt-1 text-sm'}>{error}</div>}
            </div>
        )
    },
)

export default TextInput
