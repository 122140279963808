import { FC, ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useAuth } from '../../hooks/useAuth'
import { openMenu } from '../../store/menu/menu.slice'
import { t } from '../../utils/translate/t'
import {
    Logout,
    LogoutWrap,
    MainMenu,
    Menu as StyledMenu,
    MenuItem,
    MenuItemLink,
    MenuItemText,
    MsgCounter,
} from './menu.styles'

interface IMenuItem {
    active: boolean
    text: string
    type: string
}

const dashboard: IMenuItem = {
    active: false,
    text: t('menu_dashboard'),
    type: 'dashboard',
}

const challenge: IMenuItem = {
    active: false,
    text: t('menu_challenge'),
    type: 'challenge',
}
const instruments: IMenuItem = {
    active: false,
    text: t('instruments'),
    type: 'instruments',
}
const leaderBoard: IMenuItem = {
    active: false,
    text: t('leaderboard'),
    type: 'leaderBoard',
}
const messages: IMenuItem = {
    active: false,
    text: t('messages'),
    type: 'messages',
}
const calendar: IMenuItem = {
    active: false,
    text: t('economic_calendar'),
    type: 'calendar',
}
const webtrader: IMenuItem = {
    active: false,
    text: t('webtrader'),
    type: 'webtrader',
}
const myBank: IMenuItem = {
    active: false,
    text: t('menu_my_banking_details'),
    type: 'myBank',
}
const myDocs: IMenuItem = {
    active: false,
    text: t('menu_docs'),
    type: 'myDocs',
}
const billing: IMenuItem = {
    active: false,
    text: t('billing'),
    type: 'billing',
}
const fidelCrest: IMenuItem = {
    active: false,
    text: 'Fidelcrest.com',
    type: 'fidelCrest',
}
const profile: IMenuItem = {
    active: false,
    text: t('menu_profile'),
    type: 'profile',
}
const calculator: IMenuItem = {
    active: false,
    text: t('risk_calculator'),
    type: 'profile',
}
const analysis: IMenuItem = {
    active: false,
    text: t('volatility_analysis'),
    type: 'analysis',
}
const support: IMenuItem = {
    active: false,
    text: t('contact_support'),
    type: 'support',
}

// we are on Home page by default
// dashboard.active = true;
// challenge.active = true;
// messages.active = true;

let menuItems = new Map<string, IMenuItem>([
    ['dashboard', dashboard],
    ['challenge', challenge],
    ['instruments', instruments],
    ['leaderBoard', leaderBoard],
    ['messages', messages],
    ['calendar', calendar],
    ['calculator', calculator],
    ['analysis', analysis],
    ['webtrader', webtrader],
    ['banking', myBank],
    ['documents', myDocs],
    ['billing', billing],
    ['fidelCrest', fidelCrest],
    ['profile', profile],
    ['support', support],
])

interface LocationState {
    pathname: string
}

function getMenuItems(
    map: Map<string, IMenuItem>,
    location: LocationState,
    userId: number,
    calc: string,
    clouseMenuEvent: () => void,
) {
    let items: ReactElement[] = []
    let item: ReactElement

    map.forEach((menuItem: IMenuItem, key: string) => {
        // @todo nested obj for MenuItem parent for MenuItemLink (common props)
        let metrixPage = location.pathname == '/metrix/' + userId && menuItem.type == 'dashboard'
        let messagesCount: ReactElement =
            // key == 'messages' && calc !== '' ? <MsgCounter>{calc}</MsgCounter> : <></>
            key == 'messages' ? <MsgCounter>3</MsgCounter> : <></>
        item = (
            <MenuItem
                className={menuItem.active || metrixPage ? 'active' : ''}
                icon={key}
                key={key}
            >
                <MenuItemLink icon={key} to={'/' + key} onClick={clouseMenuEvent}>
                    <MenuItemText>
                        {menuItem.text}
                        {messagesCount}
                    </MenuItemText>
                </MenuItemLink>
            </MenuItem>
        )
        items.push(item)
    })
    return items
}

const Menu: FC = () => {
    const dispatch = useDispatch()
    const { isMenuOpen } = useSelector((state: any) => state.menu)
    const [isActive, setIsActive] = useState(false)

    const location = useLocation()
    const { user } = useAuth()
    const userId = Number(user?.id || 0)
    const { logout } = useActions()
    const navigate = useNavigate()

    const [messageCount, setMessageCount] = useState<string>('')
    // const { data: counter, isSuccess, error } = messageAPI.useGetUnseenMessageCounterQuery()
    // useEffect(() => {
    //     if (!counter) {
    //         return
    //     }
    //     setMessageCount(counter.count)
    // }, [isSuccess])

    const logoutEvent = () => {
        logout()
        navigate('/')
    }
    const clouseMenuEvent = () => {
        dispatch(openMenu(!isMenuOpen))
        setIsActive(!isActive)
    }

    return (
        <>
            <StyledMenu isMenuOpen={isMenuOpen}>
                <MainMenu>
                    {getMenuItems(menuItems, location, userId, messageCount, clouseMenuEvent)}
                </MainMenu>
            </StyledMenu>
            <LogoutWrap isMenuOpen={isMenuOpen}>
                <Logout onClick={logoutEvent} href="#logout">
                    Log Out
                </Logout>
            </LogoutWrap>
        </>
    )
}

export default Menu
