import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IAppProps } from '../../App'
import * as S from '../../components/BillingInfo/billingInfo.styles'
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm/ChangePasswordForm'
import CorporateInformationForm from '../../components/Forms/CorporateInformationForm/CorporateInformationForm'
import PersonalInformationForm from '../../components/Forms/PersonalInformationForm/PersonalInformationForm'
import { devices } from '../../devices'
import Tabs from '../../ui-kit/Tabs/Tabs'
import { t } from '../../utils/translate/t'
import { StyledChallengeColumnWrap, StyledChallengeRowWrap } from '../Challenge/challenge.styles'

const StyledH3 = styled.h3`
    margin: 0 auto 20px auto;
`

const StyledChallengeColumnWrapProfile = styled(StyledChallengeColumnWrap)`
    @media ${devices.xs} {
        margin-top: 0px;
        margin-bottom: 30px;
    }
`

const Profile: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('menu_profile'))
    }, [])
    let tabProps = [
        {
            active: true,
            title: t('private'),
            component: <PersonalInformationForm />,
        },
        {
            active: false,
            title: t('corporate'),
            component: <CorporateInformationForm />,
        },
    ]
    return (
        <>
            <StyledChallengeRowWrap>
                <StyledChallengeColumnWrapProfile>
                    <S.StyledBillingInfo>
                        <StyledH3>{t('personal_information')}</StyledH3>
                        <Tabs tabs={tabProps} />
                    </S.StyledBillingInfo>
                </StyledChallengeColumnWrapProfile>
                <StyledChallengeColumnWrapProfile>
                    <S.StyledBillingInfo>
                        <StyledH3>{t('menu_password')}</StyledH3>
                        <ChangePasswordForm />
                    </S.StyledBillingInfo>
                </StyledChallengeColumnWrapProfile>
            </StyledChallengeRowWrap>
        </>
    )
}
export default Profile
