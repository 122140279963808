import React, { FC } from 'react'
import LogoGroup from '../../components/LogoGroup/LogoGroup'
import * as S from './Pay.styles'
import Bitcoin from '../../components/PaymentMethods/Bitcoin/Bitcoin'
import Wire from '../../components/PaymentMethods/Wire/Wire'
import { useParams } from 'react-router-dom'
import { t } from '../../utils/translate/t'

export const Pay: FC = () => {
    const { paymentMethod, wallet, amount } = useParams()
    return (
        <>
            <S.PageWrapper>
                <S.PaymentMethodsWrapper>
                    <LogoGroup />
                    <S.LogoMobile src={'/svg/logo-mobile.svg'} alt="logo" />
                    {paymentMethod === 'bitcoin' && wallet && amount && (
                        <Bitcoin wallet={wallet} amount={amount} />
                    )}
                    {paymentMethod === 'wire' && wallet && amount && (
                        <Wire wallet={wallet} amount={amount} />
                    )}
                    <S.BackLink to={'/dashboard'}>{t('back')}</S.BackLink>
                </S.PaymentMethodsWrapper>
            </S.PageWrapper>
        </>
    )
}
