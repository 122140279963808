import { FC, ReactElement } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'
import { CopyLink, CopyWrapper, Text } from './copyToClipboard.styles'

type IProps = {
    children: string
    isLink?: boolean
}
const CopyToClipboard: FC<IProps> = (props) => {
    const [value, copy] = useCopyToClipboard()
    let handleCopy = () => {
        copy(props.children)
    }

    let copyText: ReactElement | string
    if (props.isLink) {
        copyText = (
            <CopyLink to={props.children} target="_blank" rel="noreferrer">
                {props.children}
            </CopyLink>
        )
    } else {
        copyText = props.children
    }

    return (
        <>
            <CopyWrapper onClick={handleCopy}>
                <Text>{copyText}</Text>
            </CopyWrapper>
        </>
    )
}
export default CopyToClipboard
