import { IBroker, IPlatforms, IProgramsDTO, IRisks, IStyleTypeTrader } from '../../DTO/IProgram'
import { IBrokers, IInitialCapital, IRiskState } from '../../features/challengeSlice'

function getOptionsOfStyle(styles: number[], styleDefinitions: IStyleTypeTrader[]) {
    if (styles.length < 1) {
        return
    }
    let styleMap = new Map<number, IStyleTypeTrader>()
    styles.forEach((style, key) => {
        styleDefinitions.forEach((styleDefinition) => {
            if (style === styleDefinition.Style) {
                styleMap.set(Number(style), styleDefinition)
            }
        })
    })

    return styleMap
}

export const getPrettierMoney = (num: number) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
    return formatter.format(Number(num))
}

function getOptionsInitialCapital(
    optionsTraderType: any[] | Map<number, IStyleTypeTrader>,
    capitalStyleSet: Set<ICapitalTraderType>,
) {
    let resultCapital: IInitialCapital[][] = []

    capitalStyleSet.forEach((item) => {
        optionsTraderType.forEach((traderType: IStyleTypeTrader, index: number) => {
            if (traderType.Style === item.type) {
                if (!resultCapital[index]) resultCapital[index] = []
                let alreadyInResultCapital = false
                resultCapital[index].forEach((resCapital) => {
                    if (resCapital.value === item.capital.toString()) {
                        alreadyInResultCapital = true
                    }
                })
                if (!alreadyInResultCapital)
                    resultCapital[index].push({
                        value: item.capital.toString(),
                        name: getPrettierMoney(item.capital),
                    })
            }
        })
    })
    return resultCapital
}

function getOptionsOfRisks(
    riskDefenition: IRisks,
    capitalRiskSet: Set<ICapitalRisk>,
): IRiskState[][] {
    let resultRisk: IRiskState[][] = []

    capitalRiskSet.forEach((capitalRiskSet) => {
        if (!resultRisk[capitalRiskSet.capital]) resultRisk[capitalRiskSet.capital] = []
        resultRisk[capitalRiskSet.capital].push({
            value: capitalRiskSet.risk.toString(),
            name: riskDefenition[Number(capitalRiskSet.risk)],
        })
    })
    return resultRisk
}

function getOptionsOfBrokers(brokers: IBroker[]): IBrokers[] {
    let resultBroker: IBrokers[] = []

    brokers.forEach((broker) => {
        resultBroker.push({
            value: broker.Symbol,
            name: broker.Name,
        })
    })
    return resultBroker
}

function getOptionsOfPlatforms(brokers: IBroker[], platforms: IPlatforms) {
    let plats: IPlatforms[] = []
    brokers.forEach((broker) => {
        broker.Platforms.forEach((platformItem) => {
            plats.push({
                value: platformItem.toString(),
                name: platforms[platformItem],
            })
        })
    })
    return plats
}

function getBrokers() {}

export interface ICapitalTraderType {
    capital: number
    type: number
}

export interface ICapitalRisk {
    capital: number
    risk: number
}

export interface IPrice {
    price: number
    capital: number
    traderType: number
    risk: number
}

const ChallengeService = (program: IProgramsDTO) => {
    let StyleSet = new Set<number>()
    let CapitalStyleSet = new Set<ICapitalTraderType>()
    let CapitalRiskSet = new Set<ICapitalRisk>()
    let Prices: IPrice[] = []

    program.programs.forEach((program) => {
        Prices.push({
            price: program.Price,
            capital: program.Capital,
            traderType: program.Style,
            risk: program.Risk,
        })
        CapitalStyleSet.add({
            capital: program.Capital,
            type: program.Style,
        })
        CapitalRiskSet.add({
            capital: program.Capital,
            risk: program.Risk,
        })
        StyleSet.add(program.Style)
    })

    let spreads: any[] = []

    program.brokers.forEach((broker) => {
        broker.Spreads.forEach((spread) => {
            spreads.push({
                value: spread.toString(),
                name: program.spreads[spread],
            })
        })
    })

    let styles = Array.from(StyleSet)

    let defaultStyleTraderType = {
        key: '',
        name: '',
        value: program.styles[0].Style,
    }
    program.styles.forEach((styleTraderType) => {
        if (styles[0] === styleTraderType.Style) {
            defaultStyleTraderType.key = styleTraderType.Symbol
            defaultStyleTraderType.name = styleTraderType.Name
            defaultStyleTraderType.value = styleTraderType.Style
        }
    })

    const optionsOfStyleMap = getOptionsOfStyle(styles, program.styles)
    if (!optionsOfStyleMap && optionsOfStyleMap === undefined) {
        return
    }
    const optionsOfStyle = JSON.stringify(Array.from(optionsOfStyleMap))
    const FIRST_INDEX = 0
    let firstTraderTypeIndex = styles[0]
    let optionsInitialCapital = getOptionsInitialCapital(optionsOfStyleMap, CapitalStyleSet)
    let firstCapital = optionsInitialCapital[firstTraderTypeIndex]
    let firstCapitalValue: number = Number(firstCapital[FIRST_INDEX].value)

    let challenge = {
        optionStyle: optionsOfStyle,
        optionCapital: optionsInitialCapital,
        optionRisks: getOptionsOfRisks(program.risks, CapitalRiskSet),
        optionBrokers: getOptionsOfBrokers(program.brokers),
        optionPlatforms: getOptionsOfPlatforms(program.brokers, program.platforms),
        optionPrices: Prices,
        spreads: spreads,
        defaultValues: {
            brokers: { name: program.brokers[0].Name, value: program.brokers[0].Symbol },
            style: firstTraderTypeIndex,
            defaultStyleTraderType: defaultStyleTraderType,
            platform: getOptionsOfPlatforms(program.brokers, program.platforms)[FIRST_INDEX],
            risk: getOptionsOfRisks(program.risks, CapitalRiskSet)[firstCapitalValue][FIRST_INDEX],
        },
    }

    return challenge
}

export default ChallengeService
