import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IAppProps } from '../../App'
import History from '../../components/Table/Metrix/Histrory/History'
import ObjectivesTable from '../../components/Table/Metrix/Objectives/Objectives'
import Statistic from '../../components/Table/Metrix/Statistic/Statistic'
import { useAuth } from '../../hooks/useAuth'
import { accountServiceAPI } from '../../services/AccountServise/AccountService'
import {
    IShareMetrixDTO,
    shareMetrixAPI,
} from '../../services/ShareMetrixService/ShareMetrixService'
import CopyToClipboard from '../../ui-kit/CopyToClipboard/CopyToClipboard'
import * as S from './metrix.styles'
import { metrixAPI } from '../../services/MetrixService/MetrixService'
import { t } from '../../utils/translate/t'
import { useDispatch } from 'react-redux'
import { accountDataChange, rmPrevAccData } from '../../features/metrixAccountSlice'
import { metrixStatsDataChange, rmPrevpMetrixStatsData } from '../../features/metrixStatsSlice'

const Metrix: FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle(t('metrix'))
    }, [])

    const [accountData, setAccountData] = useState<IShareMetrixDTO>()
    // @todo Do I need useState there?
    const { user } = useAuth()
    const { id } = useParams()
    console.log('ID --->', id)
    const [isVisble, setIsVisble] = useState(false)
    const metrixShareLink: string = 'https://trade.fidelcrest.com/shared/57375' // @todo rm this and add link with nanoid
    const { data: accountsByUserID, error: accountsByUserIDError } =
        accountServiceAPI.useGetAccountsByUserIDQuery(Number(user?.id) || 0)
    const {
        data: statisticData,
        isSuccess: isStatSucces,
        error: statError,
    } = metrixAPI.useGetStatsQuery(id || '0')

    const [trigger, { isLoading, isError, data: account, error }] =
        shareMetrixAPI.useLazyGetAccountByIDQuery()
    const [toggleShareMetrix, response] = shareMetrixAPI.useToggleShareMetrixMutation()

    const [metrixTrigger, { data: metrixGraph, isError: errorGraphData, isSuccess }] =
        metrixAPI.useLazyGetMetrixQuery()
    const dispatch = useDispatch()

    // @todo add toggleShareMetrix to toggleBtn
    let sharedUrl: string
    useEffect(() => {
        if (!accountsByUserID) {
            return
        }
        if (accountsByUserID && accountsByUserID.length > 0) {
            trigger(Number(accountsByUserID[0].AccountID))
            metrixTrigger(accountsByUserID[0].AccountID)
        }
    }, [accountsByUserID])
    useEffect(() => {
        console.log('metrix', metrixGraph)
    }, [isSuccess])

    useEffect(() => {
        if (isLoading || isError) {
            return
        }
        sharedUrl = `${process.env.REACT_APP_BASE_SERVER_URL}/shared/${account?.ShareCode}`
        // console.log(sharedUrl)
    }, [isLoading])
    useEffect(() => {
        if (!account) return
        // setAccountData(account!)
        dispatch(rmPrevAccData())
        dispatch(accountDataChange(account))
    }, [account])

    useEffect(() => {
        if (!statisticData) return

        // console.log('EFFECT', statisticData)
        dispatch(rmPrevpMetrixStatsData())
        dispatch(metrixStatsDataChange(statisticData))
    }, [statisticData])

    return (
        <>
            <S.MetrixWrapper>
                <S.BackToDashboard to={'/dashboard'}>
                    <S.BackText>{t('back_to_dashboard')}</S.BackText>
                </S.BackToDashboard>
                {isLoading && (
                    <>
                        <S.ToogleGroup>{t('loading')} ..</S.ToogleGroup>
                    </>
                )}
                {isSuccess && (
                    <>
                        <S.ToogleGroup>
                            <S.TitleWrapper>
                                <S.AccountTag>{t('account_metrix')}</S.AccountTag>
                                <S.AccountNumber>{id}</S.AccountNumber>
                            </S.TitleWrapper>
                            <S.ToggleButtonGroup>
                                <S.ShareLink className={isVisble ? 'visible' : ''}>
                                    <CopyToClipboard isLink>{metrixShareLink}</CopyToClipboard>
                                </S.ShareLink>
                                <S.BtnLabel className={!isVisble ? 'visible' : ''}>
                                    {t('share_account_metrix')} {id}
                                </S.BtnLabel>
                                <S.ShareLinkToggle
                                    onClick={() => setIsVisble(!isVisble)}
                                    className={isVisble ? 'active' : ''}
                                />
                            </S.ToggleButtonGroup>
                        </S.ToogleGroup>
                        <ObjectivesTable key={'objectives'} />
                        <Statistic key={'statistic'} />
                        <History accountID={id!} key={'history'} />
                    </>
                )}
            </S.MetrixWrapper>
        </>
    )
}

export default Metrix
