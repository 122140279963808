import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    IPaymentMethodsDTO,
    PaymentStatus,
    bankingDetailsAPI,
} from '../../../services/BankingDetailsService/BankingDetailsService'
import { Button } from '../../../ui-kit/Button/Button.styles'
import {
    CheckBoxInput,
    CheckBoxLabel,
    CheckBoxWrapper,
} from '../../../ui-kit/CheckBox/CheckBox.styles'
import { Error } from '../../../ui-kit/Error/Error.styles'
import TextInput from '../../../ui-kit/TextInput/TextInput'
import { ToastConfig } from '../../../ui-kit/Toast/Toast.config'
import { t } from '../../../utils/translate/t'
import { IPayments } from '../ChoosePayment'
import { ButtonWrapper } from '../ChoosePayment.styles'
interface IFormValues {
    IBAN: string
    SWIFT: string
    BankName: string
    BankAddress: string
    agree: boolean
}

const WirePayment: FC<IPayments> = ({ payments, updatePayments }) => {
    const [canSubmit, setCanSubmit] = useState(true)
    const [sendPayments, response] = bankingDetailsAPI.useSendPaymentMutation()

    const previousIBAN = payments?.IBAN ? payments?.IBAN : ''
    const previousSWIFT = payments?.SWIFT ? payments?.SWIFT : ''
    const previousBankName = payments?.BankName ? payments?.BankName : ''
    const previousBankAddress = payments?.BankAddress ? payments?.BankAddress : ''

    const form = useForm<IFormValues>({
        defaultValues: {
            IBAN: previousIBAN,
            SWIFT: previousSWIFT,
            BankName: previousBankName,
            BankAddress: previousBankAddress,
            agree: false,
        },
    })
    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = form
    useEffect(() => {
        if (response.isSuccess) {
            if (response.isSuccess) {
                if (response.data.result === PaymentStatus.SUCCESS_PAYMENT) {
                    setCanSubmit(false)
                    toast.success('Changes saved!', ToastConfig)
                    updatePayments()
                }
                if (response.data.result === PaymentStatus.FAIL_PAYMENT) {
                    setCanSubmit(false)
                    toast.error(t('error_something_wrong'), ToastConfig)
                }
            }
        }
    }, [response])
    useEffect(() => {
        if (isDirty) {
            setCanSubmit(true)
        }
    }, [isDirty])
    useEffect(() => {
        isValid ? setCanSubmit(false) : setCanSubmit(true)
    }, [isValid])

    const onSubmit = (data: IFormValues, e: any) => {
        e.preventDefault()
        setCanSubmit(false)
        try {
            if (
                payments &&
                payments.hasOwnProperty('IBAN') &&
                payments.hasOwnProperty('SWIFT') &&
                payments.hasOwnProperty('BankName') &&
                payments.hasOwnProperty('BankAddress')
            ) {
                let _payments: IPaymentMethodsDTO = JSON.parse(JSON.stringify(payments))

                _payments.IBAN = data.IBAN
                _payments.SWIFT = data.SWIFT
                _payments.BankName = data.BankName
                _payments.BankAddress = data.BankAddress

                _payments.PaymentMethod = 'WEIR'
                console.log(_payments)
                sendPayments(_payments).unwrap()
                setCanSubmit(true)
            } else {
                setCanSubmit(true)
                // @ todo error payment doesn't exist
            }
        } catch (error) {
            console.log(error)
            setCanSubmit(true)
            toast.error(t('error_something_wrong'), ToastConfig)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    label={t('iban')}
                    placeholder={t('type_here')}
                    {...register('IBAN', {
                        required: `${t('usdt_address')} ${t('required')}`,
                    })}
                />
                {errors.IBAN && <Error>{t('error_file_required')}</Error>}
                <TextInput
                    label={t('swift')}
                    placeholder={t('type_here')}
                    {...register('SWIFT', {
                        required: `${t('swift')} ${t('required')}`,
                    })}
                />
                {errors.SWIFT && <Error>{t('error_file_required')}</Error>}
                <TextInput
                    label={t('bank_name')}
                    placeholder={t('type_here')}
                    {...register('BankName', {
                        required: `${t('bank_name')} ${t('required')}`,
                    })}
                />
                {errors.BankName && <Error>{t('error_file_required')}</Error>}
                <TextInput
                    label={t('bank_address')}
                    placeholder={t('type_here')}
                    {...register('BankAddress', {
                        required: `${t('bank_address')} ${t('required')}`,
                    })}
                />
                {errors.BankAddress && <Error>{t('error_file_required')}</Error>}
                <CheckBoxWrapper>
                    <CheckBoxInput
                        type="checkbox"
                        id="checkbox"
                        {...register('agree', {
                            required: `${t('agree_my_banking_details')} ${t('required')}`,
                        })}
                    />
                    <CheckBoxLabel htmlFor="checkbox">
                        {t('agree_my_banking_details')}
                    </CheckBoxLabel>
                </CheckBoxWrapper>
                {errors.agree && <Error>{t('error_file_required')}</Error>}
                <ButtonWrapper>
                    <Button type="submit" disabled={canSubmit}>
                        {t('save')}
                    </Button>
                </ButtonWrapper>
            </form>
            <ToastContainer />
        </>
    )
}

export default WirePayment
