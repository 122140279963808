import React from 'react'
import { ILeaderboardDTO } from '../../DTO/ILeader'

export interface ILeader {
    place: number
    id: string
    isCurrentUser?: boolean
    name: string
    profit: number
    country: string // @todo show flags
    equity?: string // @todo add column
    competitionStartEquity?: string // @todo add column
}

const LeaderBoardService: (leaders: ILeaderboardDTO[]) => Map<number, ILeader> = (
    leaders: ILeaderboardDTO[],
) => {
    const currentUserId = String(95727) // @todo after realise change to read UserId
    let formattedLeaders: Map<number, ILeader> = new Map()
    leaders.forEach((leader, key) => {
        let formattedLeader: ILeader = {
            place: key,
            id: leader.AccountID,
            name: leader.Name,
            profit: leader.Profit,
            country: leader.Country,
            isCurrentUser: leader.AccountID === currentUserId,
            equity: leader.Equity,
            competitionStartEquity: leader.CompetitionStartEquity,
        }
        formattedLeaders.set(key, formattedLeader)
    })
    return formattedLeaders
}

export function getSliceTop3Leaders(leaders: Map<number, ILeader>): Map<number, ILeader> {
    let topLeadersSplice = new Map<number, ILeader>()
    leaders.forEach((leader: ILeader, order: number) => {
        if (topLeadersSplice.size >= 3) {
            return topLeadersSplice
        }
        switch (leader.place) {
            case 1:
                topLeadersSplice.set(order, leader)
                break
            case 2:
                topLeadersSplice.set(order, leader)
                break
            case 3:
                topLeadersSplice.set(order, leader)
                break
        }
    })
    return topLeadersSplice
}

export function getSortedTopLeaders(leaders: Map<number, ILeader>): Map<number, ILeader> {
    if (leaders.size !== 3) {
        return leaders
    }
    let topLeaders = new Map<number, ILeader>()
    leaders.forEach((leader: ILeader) => {
        if (topLeaders.size === 3) {
            return
        }
        switch (leader.place) {
            case 3:
                topLeaders.set(1, leader)
                break
            case 1:
                topLeaders.set(2, leader)
                break
            case 2:
                topLeaders.set(3, leader)
                break
        }
    })
    let sortedTopLeaders: Map<number, ILeader>
    sortedTopLeaders = new Map([...topLeaders].sort())
    return sortedTopLeaders
}

export default LeaderBoardService
