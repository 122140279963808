import styled from 'styled-components'

export const Collapse = styled.div`
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
    clip-path: inset(0px -15px -15px -15px);
    border-radius: 0 0 15px 15px;
`
export const AccordionToggle = styled.div`
    cursor: pointer;
    transition: 0.3s;

    &.active > div {
        box-shadow: rgba(0, 0, 0, 0.07) 0 0 20px 0;
        clip-path: inset(-15px -15px 0 -15px);
        border-radius: 15px 15px 0 0;
    }
`
