import styled from 'styled-components'
import iconDarkTheme from '../../img/svg/header/theme-dark-toogle.svg'
import iconLightTheme from '../../img/svg/header/theme-light-toogle.svg'

export const StyledThemeToggle = styled.div`
    background-image: url(${iconLightTheme});
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    margin: 0 10px;
    background-position: center;
    background-repeat: no-repeat;
    //background-size: cover;
`

export const StyledDarkThemeToggle = styled(StyledThemeToggle)`
    background-image: url(${iconDarkTheme});
`
