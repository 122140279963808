import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Kostyl from '../../img/kostyl.png'
import AwardsLaurel from '../../img/svg/laurel.svg'

export const LoginPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    background: ${({ theme }) => theme.colors.bgLoginPage};
    @media screen and ${({ theme }) => theme.media.medium} {
        overflow-y: scroll;
    }
`
export const PromoBanner = styled.div`
    width: 100%;
    height: 85px;
    background: ${({ theme }) => theme.colors.bgPromoBanner};
    color: ${({ theme }) => theme.colors.textStandart};
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 800;
    align-self: start;
    padding: 0 10px;
    position: fixed;
    top: 0;
    z-index: 5;
    @media screen and (${({ theme }) => theme.media.medium}) {
        position: unset;
        width: 100vw;
        height: max-content;
        padding: 10px;
    }
    @media screen and (${({ theme }) => theme.media.mobile}) {
        font-size: 16px;
    }
`
export const LoginPageWrapper = styled.div`
    align-self: center;
    margin: 80px auto 0;
    @media screen and (${({ theme }) => theme.media.medium}) {
        align-items: unset;
        margin: 0;
    }
`
export const ButtonsWrapper = styled.div``
export const RateWigetWrapper = styled.div`
    width: 200px;
    height: 115px;
    background-image: url(${Kostyl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 50px auto;
    @media screen and (${({ theme }) => theme.media.medium}) {
        margin: 25px auto;
    }
`
export const ImageWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
`
export const AwardsIcon = styled.div`
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${AwardsLaurel});
    opacity: 0.3;
    color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    display: flex;
    margin: 0 5px;
    padding: 35px 0 0 0;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        font-size: 18px;
        padding-top: 45px;
        text-transform: capitalize;
    }
    @media screen and (${({ theme }) => theme.media.mobile}) {
        font-size: 10px;
        width: 100px;
        height: 100px;
        padding-top: 20px;
    }
`
export const BtnSignIn = styled.div`
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.bgBtnSignIn};
    display: flex;
    width: 350px;
    height: 54px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    margin: 25px auto;
    padding: 25px 0;
    @media screen and (${({ theme }) => theme.media.mobile}) {
        width: 80vw;
    }
`
export const BtnLogIn = styled(BtnSignIn)`
    background-color: ${({ theme }) => theme.colors.bgBtnLogIn};
    color: ${({ theme }) => theme.colors.textStandart};
    margin: 0 auto;
`

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`
