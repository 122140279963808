import styled, { css } from 'styled-components'

import { devices } from '../../../devices'
import logo from '../../../img/svg/billing/billingLogo.svg'
import iconArrow from '../../../img/svg/status/dropDownArrow.svg'

interface IProps {
    isActive?: boolean
}

export const ItemWrapper = styled.div<IProps>`
    width: 515px;
    height: 69px;
    border-radius: 15px;
    background: ${({ theme }) => theme.colors.bgItems};
    margin-bottom: 15px;
    padding: 20px 10px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        `}

    @media ${devices.lg} {
        max-width: 100%;
    }
`

export const Logo = styled.div`
    width: 39px;
    height: 39px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    background-image: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
`

export const Arrow = styled.span<IProps>`
    width: 13px;
    height: 44px;
    margin-left: 10px;
    background-image: url(${iconArrow});

    background-position: center;
    background-repeat: no-repeat;

    ${({ isActive }) =>
        isActive &&
        css`
            transform: rotate(-90deg);
        `}
`
export const ItemTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
    min-width: 97px;
    height: 44px;

    color: ${({ theme }) => theme.colors.textStandart};

    & p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.textStandart};

        @media ${devices.md} {
            max-width: 92px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`
export const ItemCost = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    & p {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;

        color: ${({ theme }) => theme.colors.textStandart};
    }
`
