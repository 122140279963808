import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { authAPI } from '../../api/services/auth/auth.service'
import LogoGroup from '../../components/LogoGroup/LogoGroup'
import { useAuth } from '../../hooks/useAuth'
import Password from '../../ui-kit/Password/Password'
import { t } from '../../utils/translate/t'
import * as S from '../Login/login.styles'

export type FormValues = {
    email: string
    password: string
}

const Login: FC = () => {
    const [passwordInput, setPasswordInput] = useState<string>('')

    const [signIn, signInResponse] = authAPI.useSignInMutation()

    const { user } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const form = useForm<FormValues>({
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const {
        register,
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = form

    const onSubmit = (formValues: FormValues) => {
        const loginPayload = {
            email: formValues.email,
            password: formValues.password,
            PAPVisitorId: '',
            cje: '',
        }
        signIn(loginPayload)
    }
    useEffect(() => {
        if (signInResponse.isSuccess) {
            console.log('login data >', signInResponse.data)
            if (Number(user?.active_accounts) > 0) navigate('/dashboard')
            navigate('/challenge')
        }
        if (signInResponse.isError) {
            setError('email', {
                type: 'manual',
                message: `${t('error_email')}`,
            })
            setError('password', {
                type: 'manual',
                message: 'Password isn`t correct!',
            })
        }
    }, [signInResponse])

    return (
        <>
            <S.LoginPage>
                <S.PromoBanner>
                    Winter 2FOR1 offer enable: Promo code WIN-2FOR1 is valid until 29th of January
                </S.PromoBanner>
                <S.LoginPageWrapper>
                    <S.ImageWrapper>
                        <S.AwardsIcon>
                            Best
                            <br />
                            micro Forex
                            <br />
                            in Europe
                            <br />
                            2021
                        </S.AwardsIcon>
                        <S.AwardsIcon>
                            Best
                            <br />
                            proprietary
                            <br />
                            trading firm
                            <br />
                            UK 2022
                        </S.AwardsIcon>
                        <S.AwardsIcon>
                            Peoples's
                            <br />
                            choice
                            <br />
                            award
                            <br />
                            2021
                        </S.AwardsIcon>
                    </S.ImageWrapper>
                    <LogoGroup />
                    <S.TitleTextWrapper>
                        <S.GreetingText>{t('log_in')}</S.GreetingText>
                    </S.TitleTextWrapper>
                    <S.Form onSubmit={handleSubmit(onSubmit)}>
                        <S.Label htmlFor="loginEmail">{t('email_address')}</S.Label>
                        <S.InputMail
                            type="text"
                            placeholder={t('type_here')}
                            {...register('email', {
                                required: `${t('email_address')} ${t('required')}`,
                                pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: `${t('error_email')}`,
                                },
                                validate: (fieldValue: string) => {
                                    return (
                                        fieldValue !== 'admin@example.com' ||
                                        'Enter a diff email address'
                                    )
                                },
                            })}
                        />
                        <S.Error>{errors.email?.message}</S.Error>
                        <Password
                            passwordInput={passwordInput}
                            setPasswordInput={setPasswordInput}
                            register={register}
                            name={'password'}
                            loginPage
                            errors={errors}
                            label={'Password'}
                        />
                        <S.BtnLogIn type="submit">Login</S.BtnLogIn>
                    </S.Form>

                    <S.TextGroup>
                        <S.ForgotLink to={'/reset-password'}>{t('forgot_password')}?</S.ForgotLink>
                        <S.OldText>
                            <S.OldLink
                                to={
                                    'https://my.challenge.fidelcrest.com/index.php?p=login&amp;_ga=2.194972004.872043371.1683350690-53341409.1682190785&amp;_gac=1.185987291.1682190785.Cj0KCQjwi46iBhDyARIsAE3nVrbkEQqrlQRTu5l3tv4WPapN5evs-t8P1C8OMHiWBBLJ2AJj9WM9WmsaAhLaEALw_wcB'
                                }
                            >
                                {t('login_here')}
                            </S.OldLink>
                            {t('login_manage')}
                        </S.OldText>
                        <S.SignIn>
                            <S.SignInLabel>{t('no_account')}</S.SignInLabel>
                            <S.SignInLink to={'/sign-up'}>{t('create_an_account')}</S.SignInLink>
                        </S.SignIn>
                    </S.TextGroup>
                </S.LoginPageWrapper>
            </S.LoginPage>
        </>
    )
}

export default Login
