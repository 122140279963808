import styled from 'styled-components'

export const Button = styled.button`
    background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    margin: 0 10px 0 0;
    padding: 14px 20px 14px 20px;
    border: none;
    border-radius: 10px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat, serif;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textLogOutBtn};
    &:disabled {
        pointer-events: none;
        background-color: ${({ theme }) => theme.colors.borderTable};
    }
`
