import { IPspOrderResponse } from '../../api/services/PspOrderService/PspOrderService'
import { PaymentMethodsEnum } from '../../components/PaymentMethods/PaymentMethods'

const checkRedirectService = (
    data: IPspOrderResponse,
    price: number,
    paymentMethod: PaymentMethodsEnum,
) => {
    const getOuterRedirectURL = (checkout: string, paymentMethod: PaymentMethodsEnum) => {
        let URL = ''
        Object.keys(PaymentMethodsEnum).forEach((key, index) => {
            if (checkout && paymentMethod === key) URL = checkout
        })

        return URL
    }
    const getInnerRedirectURL = (account_id: number, price: number) => {
        return '/pay/wire/' + account_id + '/' + price
    }

    if (paymentMethod === PaymentMethodsEnum.Wire) {
        return getInnerRedirectURL(data.account_id, price)
    }

    let URL = getOuterRedirectURL(data.checkout, paymentMethod)
    if (URL !== '') window.location.href = URL

    return null
}

export default checkRedirectService
