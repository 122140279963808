import styled from 'styled-components'
import iconCalendar from '../../img/svg/menu/calendar.svg'
import iconChallenge from '../../img/svg/menu/challenge.svg'
import iconFidelCrest from '../../img/svg/menu/fidelcrest_com.svg'
import iconHome from '../../img/svg/menu/home.svg'
import iconInstuments from '../../img/svg/menu/instuments.svg'
import iconLeaderBoard from '../../img/svg/menu/leaderboard.svg'
import iconMail from '../../img/svg/menu/mail.svg'
import iconMyBank from '../../img/svg/menu/my_bank.svg'
import iconMyDocs from '../../img/svg/menu/my_docs.svg'
import iconProfile from '../../img/svg/menu/profile.svg'
import iconReceiptBilling from '../../img/svg/menu/receipt_billing.svg'
import iconWebtrader from '../../img/svg/menu/webtrader.svg'
import iconRiskCalculator from '../../img/svg/menu/calculator.svg'
import iconVolatilityAnalysis from '../../img/svg/menu/analysis.svg'
import iconSupport from '../../img/svg/menu/support.svg'

import { NavLink } from 'react-router-dom'
import { devices } from '../../devices'
import iconLogout from '../../img/svg/menu/logout.svg'
import iconCalendarWhite from '../../img/svg/menu/white/calendar_white.svg'
import iconChallengeWhite from '../../img/svg/menu/white/challenge_white.svg'
import iconFidelCrestWhite from '../../img/svg/menu/white/fidelcrest_com_white.svg'
import iconHomeWhite from '../../img/svg/menu/white/home_white.svg'
import iconInstrumentsWhite from '../../img/svg/menu/white/instruments_white.svg'
import iconLeaderBoardWhite from '../../img/svg/menu/white/leaderboard_white.svg'
import iconMailWhite from '../../img/svg/menu/white/mail_white.svg'
import iconMyBankWhite from '../../img/svg/menu/white/my_bank_white.svg'
import iconMyDocsWhite from '../../img/svg/menu/white/my_docs_white.svg'
import iconProfileWhite from '../../img/svg/menu/white/profile_white.svg'
import iconReceiptBillingWhite from '../../img/svg/menu/white/receipt_billing_white.svg'
import iconWebtraderWhite from '../../img/svg/menu/white/webtrader_white.svg'
import iconSupportWhite from '../../img/svg/menu/white/support_white.svg'
import iconRiskCalculatorWhite from '../../img/svg/menu/white/calculator_white.svg'
import iconVolatilityAnalysisWhite from '../../img/svg/menu/white/analysis_white.svg'

export interface IOpenMenuProps {
    isMenuOpen: boolean
}

export const LogoutWrap = styled.div<IOpenMenuProps>`
    z-index: 21;
    width: 240px;
    height: 50px;
    position: fixed;
    bottom: 0;
    color: ${({ theme }) => theme.colors.textLogOutBtn};

    @media ${devices.md} {
        display: none; // @todo replace logout btn
    }
`

export const Logout = styled.a`
    min-width: 60px;
    display: inline-block;
    top: 12px;
    left: 90px;
    position: absolute;
    color: ${({ theme }) => theme.colors.textLogOutBtn};
    font-weight: 600;
    text-decoration: none;

    &:before {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: -25px;
        top: 0;
        border-radius: 0;
        background-image: url(${iconLogout});
    }
`

interface IconProps {
    icon: string
}

export const MainMenu = styled.ul`
    flex: 1 1 auto;
`

export const Menu = styled.nav<IOpenMenuProps>`
    max-width: 240px;
    min-height: calc(100vh - 80px);
    position: fixed;
    background: ${({ theme }) => theme.colors.bgMenu};
    display: flex;
    z-index: 20;

    @media ${devices.md} {
        display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
    }
    @media ${devices.sm} {
        min-width: 75%;
    }
    @media ${devices.xs} {
        min-width: 100%;
    }
`

function getWhiteIconUrl(type: string): string {
    switch (type) {
        case 'dashboard': {
            return iconHomeWhite
        }
        case 'challenge': {
            return iconChallengeWhite
        }
        case 'instruments': {
            return iconInstrumentsWhite
        }
        case 'leaderBoard': {
            return iconLeaderBoardWhite
        }
        case 'messages': {
            return iconMailWhite
        }
        case 'calendar': {
            return iconCalendarWhite
        }
        case 'webtrader': {
            return iconWebtraderWhite
        }
        case 'banking': {
            return iconMyBankWhite
        }
        case 'documents': {
            return iconMyDocsWhite
        }
        case 'billing': {
            return iconReceiptBillingWhite
        }
        case 'fidelCrest': {
            return iconFidelCrestWhite
        }
        case 'profile': {
            return iconProfileWhite
        }
        case 'calculator': {
            return iconRiskCalculatorWhite
        }
        case 'analysis': {
            return iconVolatilityAnalysisWhite
        }
        case 'support': {
            return iconSupportWhite
        }
    }
    return iconHome
}

function getIconUrl(type: string): string {
    switch (type) {
        case 'dashboard': {
            return iconHome
        }
        case 'challenge': {
            return iconChallenge
        }
        case 'instruments': {
            return iconInstuments
        }
        case 'leaderBoard': {
            return iconLeaderBoard
        }
        case 'messages': {
            return iconMail
        }
        case 'calendar': {
            return iconCalendar
        }
        case 'webtrader': {
            return iconWebtrader
        }
        case 'banking': {
            return iconMyBank
        }
        case 'documents': {
            return iconMyDocs
        }
        case 'billing': {
            return iconReceiptBilling
        }
        case 'fidelCrest': {
            return iconFidelCrest
        }
        case 'profile': {
            return iconProfile
        }
        case 'calculator': {
            return iconRiskCalculator
        }
        case 'analysis': {
            return iconVolatilityAnalysis
        }
        case 'support': {
            return iconSupport
        }
    }
    return iconHome
}

export const MenuItemText = styled.span`
    margin-left: 30px;
    font-family: 'Montserrat 700', sans-serif;
    font-size: 17px;
    color: ${({ theme }) => theme.colors.textMenu};
    @media ${devices.md} {
        font-size: 20px;
    }
`

export const MenuItemLink = styled(NavLink).attrs((props: IconProps) => ({
    icon: props.icon,
}))<IconProps>`
    position: relative;
    text-decoration: none;
    display: block;
    width: 240px;
    height: 100%;
    padding: 15px;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 15px;
        top: 15px;
        border-radius: 0;
        background-image: url(${(props) => getIconUrl(props.icon)});
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};

        &:before {
            content: ' ';
            background: ${(props) => `url(${getWhiteIconUrl(props.icon)}) no-repeat top center`};
            background-size: contain;
        }
    }

    &:hover ${MenuItemText} {
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    }

    @media ${devices.md} {
        min-width: 100%;
    }
`

export const MenuItem = styled.li.attrs((props: IconProps) => ({
    icon: props.icon,
}))<IconProps>`
    box-sizing: border-box;
    height: 50px;
    width: 240px;
    left: 0;
    border-radius: 0;
    position: relative;

    & a.active ${MenuItemText}, &.active ${MenuItemText} {
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    }

    & a.active:before,
    &.active ${MenuItemLink}:before {
        content: ' ';
        background: ${(props) => `url(${getWhiteIconUrl(props.icon)}) no-repeat top center`};
        background-size: contain;
    }

    & a.active,
    &.active {
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    }

    @media ${devices.md} {
        min-width: 100%;
    }
`

export const MsgCounter = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    gap: 10px;

    position: absolute;
    width: 17px;
    height: 19px;
    left: 206px;
    top: 15px;
    color: ${({ theme }) => theme.colors.textLogOutBtn};

    /* red' */
    background: ${({ theme }) => theme.colors.bgMsgCounter};
    border-radius: 50%;
    @media ${devices.md} {
        left: unset;
        right: 30px;
        width: 25px;
        height: 25px;
        padding: unset;
    }
`
