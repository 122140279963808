import styled from 'styled-components'
import { Error } from '../../components/PrivateBillingInfo/privateBillingInfo.styles'
import * as L from '../Login/login.styles'

export const ErrorSelect = styled(Error)`
    bottom: -15px;
    font-weight: 600;
    font-size: 14px;
`
export const ErrorCheckbox = styled(ErrorSelect)`
    bottom: 3px;
    margin-left: 30px;
`
export const ErrorInputText = styled(ErrorSelect)`
    white-space: nowrap;
    bottom: 3px;
`

export const SignUpPage = styled(L.LoginPage)`
    padding-top: 90px;
    height: max-content;
    @media screen and ${({ theme }) => theme.media.medium} {
        padding-top: unset;
    }
`
export const PromoBanner = styled(L.PromoBanner)``
export const SignInPageWrapper = styled(L.LoginPageWrapper)`
    all: unset;
    max-width: 350px;
    margin: 0 auto;
`
export const TitleTextWrapper = styled(L.TitleTextWrapper)``
export const GreetingText = styled(L.GreetingText)``
export const Form = styled(L.Form)`
    max-width: 350px;

    & :nth-child(4) {
        padding-top: 0;
    }
`
export const InputText = styled(L.InputMail)`
    width: 100%;
    margin: 5px auto 18px;

    background: ${({ theme }) => theme.colors.bgMenu};
    color: ${({ theme }) => theme.colors.textStandart};
`

export const Subtitle = styled(L.PageTitle)`
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textGrey};
`
export const Label = styled(L.Label)`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
`
export const InputRow = styled(L.PageTitle)`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    & > ${Label} {
        margin: 0;
        width: 48%;
    }
`

/* checkbox import style */
export const CheckBoxWrapper = styled.div`
    & ${Label} {
        padding-bottom: 25px;
    }
`
export const TextWrap = styled.div`
    color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    font-size: 16px;

    & > a {
        padding-left: 5px;
        display: unset !important;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.textLink};
        font-weight: 500;
    }
`

export const BtnLogIn = styled(L.BtnLogIn)``
export const TextGroup = styled.div`
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    & :first-child {
        padding-bottom: 20px;
        line-height: 150%;
    }
`
export const CountrySelectWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-top: 5px;
    & > * {
        width: 100%;
    }
`
export const CheckWrapper = styled.div`
    position: relative;
`
