import styled from 'styled-components'

export const CheckBoxLabel = styled.label`
    font-family: 'Montserrat', serif;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    padding: 0 0 20px 30px;
    display: flex;
    gap: 10px;
    flex: none;
    flex-grow: 0;
    cursor: pointer;

    &:before {
        content: ' ';
        position: absolute;
        left: 0;
        display: flex;
        width: 20px;
        height: 20px;
        border: 1px solid ${({ theme }) => theme.colors.textCurrentItemMenu};
        border-radius: 5px;
    }
`
const checkSymbol: string = '\u{2714}'
export const CheckedCheckBoxLabel = styled(CheckBoxLabel)`
    &:before {
        content: '${checkSymbol}';
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.textLink};
    }
`

export const TextWrap = styled.div`
    color: ${({ theme }) => theme.colors.textCurrentItemMenu};
    font-size: 16px;

    & > a {
        padding-left: 5px;
        display: unset !important;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.textLink};
        font-weight: 500;
    }
`

export const StyledCheckbox = styled.input`
    opacity: 0;
    position: absolute;
    z-index: -50;
`
