import React, { ReactElement, useEffect } from 'react'
import { IAppProps } from '../../App'
import Accordion from '../../components/Accordion/Accordion'
import PhaseDetail from '../../components/PhaseDetail/PhaseDetail'
import { useAuth } from '../../hooks/useAuth'
import {
    AwaitingPaymentStatus,
    CompletedStatus,
    FailedStatus,
    StatusDropDown,
    SubHeader,
} from '../../layouts/MainElements/mainElements.styles'
import * as S from './dashboard.styles'

enum Status {
    Completed = 'Completed',
    Active = 'Active',
    Failed = 'Failed',
    AwaitingPayment = 'AwaitingPayment',
}

interface IPhase {
    userId: number
    date: string
    status: Status
}

const phase1Items: IPhase[] = [
    { userId: 28516, date: '2022-02-16', status: Status.Completed },
    { userId: 28516, date: '2022-02-16', status: Status.AwaitingPayment },
    { userId: 28516, date: '2022-02-16', status: Status.Failed },
]
const phase2Items: IPhase[] = [{ userId: 28516, date: '2022-02-16', status: Status.Completed }]
const phase3Items: IPhase[] = [{ userId: 28516, date: '2022-02-16', status: Status.Failed }]

const getPhases = (phaseNumber: number, userId: number, userToken: string) => {
    let phases: ReactElement[] = []
    let phase: ReactElement
    const getStatusElement = (status: Status) => {
        let statusElement: ReactElement
        let statusIcon: ReactElement

        switch (status) {
            case Status.Active:
            case Status.Completed:
                statusElement = (
                    <CompletedStatus>
                        <p>Completed</p>
                    </CompletedStatus>
                )
                statusIcon = <S.CompletedIcon></S.CompletedIcon>
                break
            case Status.Failed:
                statusElement = (
                    <FailedStatus>
                        <p>Failed</p>
                    </FailedStatus>
                )
                statusIcon = <S.FailedIcon></S.FailedIcon>
                break
            case Status.AwaitingPayment:
                statusIcon = <S.AwaitingPaymentIcon></S.AwaitingPaymentIcon>
                statusElement = (
                    <AwaitingPaymentStatus>
                        <p>Awaiting Payment</p>
                    </AwaitingPaymentStatus>
                )
                break
        }
        return [statusIcon, statusElement]
    }

    let items: IPhase[] = []
    switch (phaseNumber) {
        case 1:
            items = phase1Items
            break
        case 2:
            items = phase2Items
            break
        case 3:
            items = phase3Items
            break
    }

    let accordion: ReactElement

    items.forEach((phaseItem, key) => {
        const [statusIcon, statusElement] = getStatusElement(phaseItem.status)
        phase = (
            <S.Phase>
                {statusIcon}
                <S.Columns>
                    <S.UserId>{phaseItem.userId}</S.UserId>
                    <S.PhaseDate>{phaseItem.date}</S.PhaseDate>
                </S.Columns>
                <StatusDropDown>{statusElement}</StatusDropDown>
            </S.Phase>
        )
        phases.push(phase)
    })
    return (
        <S.Phases>
            <Accordion
                headerData={phases}
                detailData={phases.map((phase, index) => {
                    return <PhaseDetail key={index} id={userId} token={userToken} />
                })}
            />
        </S.Phases>
    )
}

const DashboardGET: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        props.setTitle('Dashboard')
    }, [])

    const { user } = useAuth()
    const userId = Number(user?.id || 0)
    const userToken = user?.token || ''
    return (
        <>
            <S.Row>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 1 (Challenge)</SubHeader>
                        {getPhases(1, userId, userToken)}
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 2 (Verification)</SubHeader>
                        {getPhases(2, userId, userToken)}
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
                <S.ColumnsWithGap20>
                    <S.PhasesBlock>
                        <SubHeader>Phase 3 (Professional)</SubHeader>
                        {getPhases(3, userId, userToken)}
                    </S.PhasesBlock>
                </S.ColumnsWithGap20>
            </S.Row>
        </>
    )
}

export default DashboardGET
