import styled from 'styled-components'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import { StyledBlock } from '../../ui-kit/Block/block.styles'

export const OrderAndPayForm = styled.form.attrs({
    method: 'post',
})`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
export const StyledTotalBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 111px;
    background: ${({ theme }) => theme.colors.bgTotal};
    border-radius: 15px;
    margin: 0 auto 30px;
`
export const StyledLabel = styled.div``
export const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    width: 100%;
    height: 48px;

    background: #ffaa06;
    border-radius: 10px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;

    border: none;
    cursor: pointer;
    outline: inherit;
`

export const StyledTotal = styled.div`
    min-width: 132px;
    height: 53px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 150%;
    /* identical to box height, or 52px */

    display: flex;
    align-items: center;

    /* buttons */

    color: #ffaa06;
`

export const OrderAndPayBlock = styled(StyledBlock)`
    flex-direction: column;
    max-width: 555px;
`

export const StyledSubHeader = styled(SubHeader)`
    margin-bottom: 24px;
`

export const StyledCheckbox = styled.input.attrs({
    type: 'checkbox',
})`
    display: flex;
    width: 20px;
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
`
export const Wrap = styled.div`
    display: inline;
    gap: 5px;
    & p {
        white-space: nowrap;
        display: inline;
        margin-right: 5px;
    }
`
export const Label = styled.label`
    gap: 10px;
    color: #999999;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    display: flex;
    align-items: center;

    flex: none;
    flex-grow: 0;
    margin-right: 15px;

    & a {
        height: 24px;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        display: inline;
        align-items: center;
        color: #3a4246;
        flex: none;
        order: 1;
        flex-grow: 0;
    }
`
