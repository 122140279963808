import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../api/baseQuery'
import { IProgramsDTO } from '../../../DTO/IProgram'

export const programAPI = createApi({
    reducerPath: 'programAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        fetchPrograms: build.query<IProgramsDTO, void>({
            query: () => ({
                url: '/programs',
                method: 'GET',
            }),
        }),
    }),
})
