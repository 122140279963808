import React, { useEffect, useState } from 'react'
import StartChallenge from '../../components/AverageChallenge/StartChallenge'
import BillingInfo from '../../components/BillingInfo/BillingInfo'
import OrderAndPay from '../../components/OrderAndPay/OrderAndPay'
import * as S from './challenge.styles'

let user = {
    company_name: false,
}

// const [ accountType, setAccountType ] = useState( user.company_name ? 'corporate' : 'private' )

interface IChallengeProps {
    setTitle: any
}

const Challenge: React.FC<IChallengeProps> = (props) => {
    useEffect(() => {
        props.setTitle('Fieldcrest Challenge')
    }, [])
    const [accountType, setAccountType] = useState(!user.company_name ? 'private' : 'corporate')

    const viewProps = {
        accountType,
        setAccountType,
    }

    return (
        <>
            {/*<S.StyledAverageRow>*/}
            {/*    <AverageItem*/}
            {/*        icon={'traders'}*/}
            {/*        averageTitle={'Average Attempts/Traders'}*/}
            {/*        value={'12.5'}*/}
            {/*    />*/}
            {/*    <AverageItem icon={'size'} averageTitle={'Average account size'} value={'$2,344'} />*/}
            {/*    <AverageItem icon={'pass'} averageTitle={'Average Days to Pass'} value={'7.2'} />*/}
            {/*    <AverageItem*/}
            {/*        icon={'calendar'}*/}
            {/*        averageTitle={'Average Time needed to reach Phase'}*/}
            {/*        value={'-7.2 Days'}*/}
            {/*    />*/}
            {/*</S.StyledAverageRow>*/}

            <S.StyledChallengeRowWrap>
                <S.StyledChallengeColumnWrap>
                    <StartChallenge></StartChallenge>
                    <BillingInfo />
                </S.StyledChallengeColumnWrap>
                <S.StyledChallengeColumnWrap>
                    <OrderAndPay />
                </S.StyledChallengeColumnWrap>
            </S.StyledChallengeRowWrap>
        </>
    )
}

export default Challenge
