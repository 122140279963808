import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import CountrySelect from '../../components/CountrySelect/CountrySelect'
import LogoGroup from '../../components/LogoGroup/LogoGroup'
import TitleSelect from '../../components/TitleSelect/TitleSelect'
import CustomCheckbox from '../../ui-kit/Form/CustomCheckbox/CustomCheckbox'
import { t } from '../../utils/translate/t'
import * as S from './signup.styles'

export type SignInFormValues = {
    title: string
    firstName: string
    lastName: string
    email: string
    phone: string
    countryResidence: string
    privacyPolicy: boolean
    newsSubscribe?: boolean
    checkCorrectName: boolean
}
type Props = {
    msgRoot?: any
    setMsgRoot?: any
    refresh?: any
    tmp?: any
}
const Signup: FC<Props> = (Props) => {
    const form = useForm<SignInFormValues>()
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = form
    const onSubmit = (data: SignInFormValues) => {
        const signInPayload: SignInFormValues = {
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            countryResidence: data.countryResidence,
            privacyPolicy: data.privacyPolicy,
            newsSubscribe: data.newsSubscribe,
            checkCorrectName: data.checkCorrectName,
        }
    }
    const privacyLink: string = 'https://fidelcrest.com/privacy-policy'
    const termsLink: string = 'https://fidelcrest.com/general-terms-and-conditions'
    return (
        <>
            <S.SignUpPage>
                <S.PromoBanner>
                    Winter 2FOR1 offer enable: Promo code WIN-2FOR1 is valid until 29th of January
                </S.PromoBanner>
                <S.SignInPageWrapper>
                    <LogoGroup />
                    <S.TitleTextWrapper>
                        <S.GreetingText>{t('create_an_account')}</S.GreetingText>
                        <S.Subtitle>{t('enter_details')}</S.Subtitle>
                    </S.TitleTextWrapper>

                    <S.Form onSubmit={handleSubmit(onSubmit)}>
                        <S.Label htmlFor="title">
                            Title
                            <TitleSelect register={register} required setValue={form.setValue} />
                            <S.ErrorSelect>{errors.title?.message}</S.ErrorSelect>
                        </S.Label>
                        <S.InputRow>
                            <S.Label htmlFor="firstName">
                                {t('first_name')}
                                <S.InputText
                                    {...register('firstName', {
                                        required: `${t('first_name')} ${t('required')}`,
                                    })}
                                    type="text"
                                    placeholder={t('type_here')}
                                />
                                <S.ErrorInputText>{errors.firstName?.message}</S.ErrorInputText>
                            </S.Label>
                            <S.Label htmlFor="lastName">
                                {t('last_name')}
                                <S.InputText
                                    {...register('lastName', {
                                        required: `${t('last_name')} ${t('required')}`,
                                    })}
                                    type="text"
                                    placeholder={t('type_here')}
                                />
                                <S.ErrorInputText>{errors.lastName?.message}</S.ErrorInputText>
                            </S.Label>
                        </S.InputRow>
                        <S.Label htmlFor="email">
                            Email
                            <S.InputText
                                type="text"
                                placeholder={t('type_here')}
                                {...register('email', {
                                    required: `${t('email_address')} ${t('required')}`,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: `${t('error_email')}`,
                                    },
                                    validate: (fieldValue: string) => {
                                        return (
                                            fieldValue !== 'admin@example.com' ||
                                            'Enter a diff email address'
                                        )
                                    },
                                })}
                            />
                            <S.ErrorInputText>{errors.email?.message}</S.ErrorInputText>
                        </S.Label>
                        <S.InputRow>
                            <S.Label htmlFor="phone">
                                {t('phone_number')}
                                <S.InputText
                                    {...register('phone', {
                                        required: `${t('phone_number')} ${t('required')}`,
                                    })}
                                    type="text"
                                    placeholder={t('type_here')}
                                />
                                <S.ErrorInputText>{errors.phone?.message}</S.ErrorInputText>
                            </S.Label>
                            <S.Label htmlFor="countryResidence">
                                {t('country_of_residence')}
                                <S.CountrySelectWrapper>
                                    <CountrySelect
                                        register={register}
                                        required
                                        setValue={form.setValue}
                                        defaultValue={'Select...'}
                                        pageType
                                    />
                                </S.CountrySelectWrapper>
                                <S.ErrorInputText>
                                    {errors.countryResidence?.message}
                                </S.ErrorInputText>
                            </S.Label>
                        </S.InputRow>
                        <S.CheckBoxWrapper>
                            <S.CheckWrapper>
                                <CustomCheckbox
                                    register={register}
                                    name={'privacyPolicy'}
                                    setValue={form.setValue}
                                >
                                    l agree to the processing of my personal data according to our
                                    <Link target="_blank" rel="noreferrer" to={privacyLink}>
                                        PRIVACY POLICY.
                                    </Link>
                                </CustomCheckbox>
                                <S.ErrorCheckbox>{errors.privacyPolicy?.message}</S.ErrorCheckbox>
                            </S.CheckWrapper>
                            <CustomCheckbox
                                register={register}
                                name={'newsSubscribe'}
                                setValue={form.setValue}
                            >
                                I want to Received our newsletter.
                            </CustomCheckbox>
                            <S.CheckWrapper>
                                <CustomCheckbox
                                    register={register}
                                    name={'checkCorrectName'}
                                    setValue={form.setValue}
                                >
                                    I confirmed that my name is correct and matched my government
                                    issued ID.
                                </CustomCheckbox>
                                <S.ErrorCheckbox>
                                    {errors.checkCorrectName?.message}
                                </S.ErrorCheckbox>
                            </S.CheckWrapper>
                        </S.CheckBoxWrapper>
                        <S.BtnLogIn type="submit">Login</S.BtnLogIn>
                    </S.Form>

                    <S.TextGroup>
                        <S.TextWrap>
                            {t('agree_general_message')}
                            <Link target="_blank" rel="noreferrer" to={termsLink}>
                                {t('agree_general')}
                            </Link>
                        </S.TextWrap>
                        <S.TextWrap>
                            {t('account_already')}
                            <Link to={'/login'}>{t('log_in')}</Link>
                        </S.TextWrap>
                    </S.TextGroup>
                </S.SignInPageWrapper>
            </S.SignUpPage>
        </>
    )
}

export default Signup
