import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'

interface IChangePasswordPayload {
    id: number
    old_password: string
    new_password: string
}

interface IChangePasswordResponse {
    ok: number
}

export const changePasswordAPI = createApi({
    reducerPath: 'changePasswordAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        changePassword: build.mutation<IChangePasswordResponse, IChangePasswordPayload>({
            query: (payload) => ({
                url: '/change-password/' + payload.id,
                method: 'POST',
                body: { ...payload },
            }),
        }),
    }),
})
