import styled from 'styled-components'
import { devices } from '../../devices'
import chart from '../../img/svg/challenge/total_payment_chart.png'

export const TotalPaymentChartStyles = styled.div`
    background-image: url(${chart});
    background-size: 555px 363px;
    min-width: 555px;
    height: 363px;
    border-radius: 22px;
    @media ${devices.xl} {
        max-width: 555px;
        min-width: 320px;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px 0 30px 0;
    gap: 30px;

    @media ${devices.xl} {
        flex-direction: column;
    }
`
