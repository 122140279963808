import { FC } from 'react'
import { SubHeader } from '../../layouts/MainElements/mainElements.styles'
import CopyToClipboard from '../../ui-kit/CopyToClipboard/CopyToClipboard'
import { t } from '../../utils/translate/t'
import * as S from './phaseDetail.styles'

interface IPhaseDetail {
    id: number | null
    token: string | null
}

const PhaseDetail: FC<IPhaseDetail> = ({ id, token }) => {
    return (
        <>
            <S.Wrap>
                <SubHeader>Account {id || 58047}</SubHeader>
                <S.PhaseTable>
                    <S.Tr>
                        <S.Td>{t('capital')}</S.Td>
                        <S.Td>$10,000</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('risk')}</S.Td>
                        <S.Td>{t('risk_undefined_normal')}</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('broker')}</S.Td>
                        <S.Td>Foreign Exchange Clearing House</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('platform')}</S.Td>
                        <S.Td>Metatrader4</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('spreads')}</S.Td>
                        <S.Td>Raw</S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('payment_method')}</S.Td>
                        <S.Td>Card</S.Td>
                    </S.Tr>
                </S.PhaseTable>
            </S.Wrap>
            <S.Wrap>
                <SubHeader>Credentials</SubHeader>
                <S.CredentialsTable>
                    <S.Tr>
                        <S.Td>Login</S.Td>
                        <S.Td>
                            <CopyToClipboard>1654454</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>Password</S.Td>
                        <S.Td>
                            <CopyToClipboard>6Rya0LNrgI</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                    <S.Tr>
                        <S.Td>{t('server')}</S.Td>
                        <S.Td>
                            <CopyToClipboard>SAFASoftwire-Demo</CopyToClipboard>
                        </S.Td>
                    </S.Tr>
                </S.CredentialsTable>
                <S.TermUseBtn
                    target="_blank"
                    rel="noreferrer"
                    to={`https://trade.fidelcrest.com/api3/tos6.php?id=${id}&amp;token=${token}`}
                >
                    {t('agree_tos')}
                </S.TermUseBtn>
                <S.Metrix to={`/metrix/${id}`}>{t('metrix')}</S.Metrix>
            </S.Wrap>
        </>
    )
}
export default PhaseDetail
