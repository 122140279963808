import styled from 'styled-components'

export const SpanWrap = styled.span`
    margin-bottom: 5px;
    display: block;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 12px;
    color: rgb(33, 33, 33);
`

export const Input = styled.input`
    height: 42px;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 12px;
    font-family: Montserrat, serif;
    font-style: italic;
    color: #999999;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #f4f4f4;
    border: none;
    &.border-red {
        border-color: rgb(220 38 38);
    }
`
