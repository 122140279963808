import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { IGetOrdersResponse } from '../../DTO/IGetOrdersResponse'
import { baseQueryWithReauth } from '../../api/baseQuery'

export const billingAPI = createApi({
    reducerPath: 'billingAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getOrders: build.query<IGetOrdersResponse[], number>({
            query: (id) => ({
                url: `/orders/${id}`,
            }),
        }),
    }),
})
