import { DefaultTheme } from 'styled-components'
import { ITheme, ThemeEnum } from './interfaces/styled'

export const baseTheme: ITheme = {
    colors: {
        bgPage: '#F5F5F5',
        bgContent: '#FFFFFF', // block: '#FFFFFF', // Dark theme #1A1A1A
        bgItems: '#FFFFFF',
        bgInput: '#F4F4F4', // StyledInput
        bgMenu: '#212121',
        bgCurrentMenuItem: '#FFAA06',
        bgMsgCounter: '#B5120E',
        bgCurrentTableRow: 'rgba(255, 170, 6, 0.1)',
        bgLoginPage: '#333645',
        bgStatusGreen: 'rgba(77, 181, 14, 0.2)',
        bgStatusYellow: 'rgba(255, 170, 6, 0.2)',
        bgStatusRed: 'rgba(181, 18, 14, 0.2)',

        bgCompletedStatus: 'rgba(77, 181, 14, 0.1)', //#4DB50E;
        bgFailedStatus: 'rgba(181, 18, 14, 1)', //#B5120E
        bgAwaitingPaymentStatus: 'rgba(255, 170, 6, 1)',
        bgInProgressStatus: 'rgba(255, 245, 157, 0.95)',

        bgCompletedSwitch: 'rgba(77, 181, 14, 1)', //#4DB50E;
        bgFailedSwitch: 'rgba(181, 18, 14, 0.1)', //#B5120E
        bgAwaitingPaymentSwitch: 'rgba(255, 170, 6, 0.2)',
        bgInProgressSwitch: 'rgba(255, 245, 157, 0.95)',

        bgWhiteForQR: '#FCFFFF',

        textStandart: '#212121',
        textMenu: '#999999',
        textCurrentItemMenu: '#FCFFFF',
        textLogOutBtn: '#FCFFFF',
        textLogo: '#FCFFFF',
        textBreadCrumbs: '#999999',
        textBreadCrumbsCurrent: '#212121',
        textLink: '#FFAA06',
        textGrey: '#999999',
        textPayGrey: '#f6f1eb',
        textStatusGreen: '#4DB50E',

        btnChat: '#3A4246',

        btnLogIn: '#3A4246',
        btnSignIn: '#3A4246',
        bgPromoBanner: '#54B9D1',
        bgBtnSignIn: '#F3AE3E',
        bgBtnLogIn: '#AAAAAA',

        bgTotal: '#FFF9EE',
        borderTable: '#E8E8E8',
    },

    media: {
        extraLarge: '(max-width: 3960px)',
        large: '(max-width: 1440px)',
        medium: '(max-width: 1080px)',
        mobile: '(max-width: 576px)',
    },

    sizes: {
        // in px
        header: {
            height: 80,
            width: 1200,
        },
        container: { width: 1200 },
        footer: {
            height: 128,
            width: 1200,
        },
        menu: { width: 240 },
    },

    durations: {
        // in ms
        ms300: 300,
    },

    order: {
        // z-index
        header: 50,
        footer: 50,
    },
}

export const lightTheme: DefaultTheme = {
    ...baseTheme,
    type: ThemeEnum.light,

    colors: {
        ...baseTheme.colors,
    },
}

export const darkTheme: DefaultTheme = {
    ...baseTheme,
    type: ThemeEnum.dark,

    colors: {
        ...baseTheme.colors,

        bgPage: '#0F0F0F',
        bgContent: '#1A1A1A',
        bgItems: '#0F0F0F',

        textBreadCrumbsCurrent: '#FCFFFF',
        textStandart: '#FCFFFF',
        // bgTotal: '#FFF9EE',
        borderTable: '#565656',
    },
}
