import styled from 'styled-components'
import { devices } from '../../devices'
import iconGreen from '../../img/svg/dashboard/phase_green_icon.svg'
import iconRed from '../../img/svg/dashboard/phase_red_icon.svg'
import iconYellow from '../../img/svg/dashboard/phase_yellow_icon.svg'

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    @media ${devices.xl} {
        row-gap: 0;
    }
    @media ${devices.xl} {
        > * {
            &:not(:last-child) {
                > div {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media ${devices.sm} {
        flex-direction: column;
        gap: 0;

        > * {
            &:first-child {
                > div {
                    margin-bottom: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &:not(:first-child):not(:last-child) {
                > div {
                    margin: 0;
                    border-radius: 0;
                }
            }
            &:last-child {
                > div {
                    margin-top: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }
`

export const Columns = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
`

export const ColumnsWithGap20 = styled(Columns)`
    gap: 20px;
`

export const PhasesBlock = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 360px;
    margin: 35px 0 35px 0;
    background: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};
    border-radius: 15px;
    padding: 20px;
`

export const UserId = styled.div`
    width: 51px;
    height: 27px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;

    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const PhaseDate = styled.p`
    width: 67px;
    height: 18px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;

    /* 50% text */
    color: ${({ theme }) => theme.colors.textGrey};

    max-width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
export const Phases = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`
export const Phase = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 319px;
    gap: 20px;
    height: 80px;
    background: ${({ theme }) => theme.colors.bgContent};
    /* Shadow */

    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
    border-radius: 15px;

    padding: 17px 20px;
`

const Icon = styled.div`
    display: flex;
    min-width: 44px;
    height: 44px;

    background-image: url(${iconGreen});
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${({ theme }) => theme.colors.bgCompletedStatus};

    border-radius: 50%;
`

export const CompletedIcon = styled(Icon)`
    background-image: url(${iconGreen});
    background-color: ${({ theme }) => theme.colors.bgCompletedStatus};
`
export const InProgressIcon = styled(Icon)`
    background-image: url(${iconYellow});
    background-color: ${({ theme }) => theme.colors.bgInProgressStatus};
`
export const FailedIcon = styled(Icon)`
    background-image: url(${iconRed});
    background-color: ${({ theme }) => theme.colors.bgFailedSwitch};
`
export const AwaitingPaymentIcon = styled(Icon)`
    background-image: url(${iconYellow});
    background-color: ${({ theme }) => theme.colors.bgAwaitingPaymentSwitch};
`
