import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { IPspOrderPayload } from '../../../DTO/IPspOrder'
import { baseQueryWithReauth } from '../../baseQuery'

export interface IPspOrderResponse {
    account_id: number
    checkout: string
}

export const pspOrderAPI = createApi({
    reducerPath: 'pspOrderAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        pspOrder: build.mutation<IPspOrderResponse, IPspOrderPayload>({
            query: (payload) => ({
                url: '/psp-order',
                method: 'POST',
                body: { ...payload },
            }),
        }),
    }),
})
