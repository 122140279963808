import { DevTool } from '@hookform/devtools'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { updateUserStorage } from '../../../api/services/auth/auth.helper'
import { useAuth } from '../../../hooks/useAuth'
import { profileAPI } from '../../../services/ProfileService/ProfileService'
import { ToastConfig } from '../../../ui-kit/Toast/Toast.config'
import CountrySelect from '../../CountrySelect/CountrySelect'

import { t } from '../../../utils/translate/t'
import * as S from '../../PrivateBillingInfo/privateBillingInfo.styles'
import { FormValues } from '../PersonalInformationForm/PersonalInformationForm'

export interface CorporateFormValues extends FormValues {
    companyName: string
    companyRegNr: string
    companyVatNr: string
}

const ErrorWithPosition = styled(S.Error)`
    bottom: -15px;
`

const defaultValues = {
    companyName: 'ABC+ OU',
    companyRegNr: '1212313',
    companyVatNr: 'vsd4334',
    firstName: 'Alex',
    lastName: 'Kim',
    email: 'superherokim2020@gmail.com',
    phone: '+37256173321',
    city: 'Tallinn',
    address: 'Narva mnt 1',
    zipCode: '10110',
    countryResidence: 'UA',
    promoCode: '',
}

const CorporateInformationForm: FC = () => {
    const [canSubmit, setCanSubmit] = useState(false)
    const [changeProfile, response] = profileAPI.useChangeProfileCorporateMutation()
    const { user } = useAuth()
    const dispatch = useDispatch()
    const defaultCountryResidence = user?.country ? user?.country : 'UA' // EE, DK, DE, UA
    const form = useForm<CorporateFormValues>({
        defaultValues: {
            companyName: user?.company_name ? user?.company_name : defaultValues.companyName,
            companyRegNr: user?.company_reg_nr ? user?.company_reg_nr : defaultValues.companyRegNr,
            companyVatNr: user?.company_tax_nr ? user?.company_tax_nr : defaultValues.companyVatNr,

            firstName: user?.first_name ? user?.first_name : defaultValues.firstName,
            lastName: user?.last_name ? user?.last_name : defaultValues.lastName,
            email: user?.email ? user?.email : defaultValues.email,
            phone: user?.phone ? user?.phone : defaultValues.phone,
            city: user?.city ? user?.city : defaultValues.city,
            address: user?.legal_address ? user?.legal_address : defaultValues.address,
            zipCode: user?.zip_code ? user?.zip_code : defaultValues.zipCode,
            countryResidence: user?.country ? user?.country : defaultValues.countryResidence,
            promoCode: '',
        },
    })
    const {
        register,
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = form

    useEffect(() => {
        if (response.isSuccess) {
            setCanSubmit(false)
            toast.success('Changes saved!', ToastConfig)
        }
    }, [response])

    const onSubmit = (data: CorporateFormValues) => {
        if (!user?.id) {
            console.log('User id not found')
            toast.error(t('error_something_wrong'), ToastConfig)
            return
        }
        const profileCorporatePayload = {
            id: Number(user?.id),
            account_type: 'corporate',
            company_name: data.companyName,
            company_reg_nr: data.companyRegNr,
            company_tax_nr: data.companyVatNr,
            country: data.countryResidence,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            city: data.city,
            legal_address: data.address,
            zip_code: data.zipCode,
        }

        try {
            changeProfile(profileCorporatePayload).unwrap()
            setCanSubmit(true)
            updateUserStorage('country', data.countryResidence)
        } catch (error) {
            console.log(error)
            toast.error(t('error_something_wrong'), ToastConfig)
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="companyName">{t('company_name')}</S.StyledLabel>
                            <S.StyledInput
                                id="companyName"
                                type="text"
                                {...register('companyName', {
                                    required: `${t('company_name')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.companyName?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="companyRegNr">
                                {t('company_reg_nr')}
                            </S.StyledLabel>
                            <S.StyledInput
                                id="companyRegNr"
                                type="text"
                                {...register('companyRegNr', {
                                    required: `${t('company_reg_nr')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.companyRegNr?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                    </S.Column>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="companyVATNumber">
                                {t('company_tax_nr')}
                            </S.StyledLabel>
                            <S.StyledInput
                                id="companyVATNumber"
                                type="text"
                                {...register('companyVatNr', {
                                    required: `${t('company_tax_nr')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.companyVatNr?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                    </S.Column>
                </S.Wrapper>
                <S.Wrapper>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="FirstName">{t('first_name')}</S.StyledLabel>
                            <S.StyledInput
                                id="FirstName"
                                type="text"
                                placeholder={t('type_here')}
                                {...register('firstName', {
                                    required: `${t('first_name')} ${t('required')}`,
                                })}
                            />
                            <ErrorWithPosition>{errors.firstName?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="email">{t('email_address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'email'}
                                placeholder={t('type_here')}
                                {...register('email', {
                                    required: `${t('email_address')} ${t('required')}`,
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: `${t('error_email')}`
                                    },
                                    validate: (fieldValue) => {
                                        return (
                                            fieldValue !== 'admin@example.com' ||
                                            'Enter a diff email address'
                                        )
                                    },
                                })}
                            />
                            <ErrorWithPosition>{errors.email?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="city">{t('city')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'city'}
                                {...register('city', {
                                    required: `${t('city')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.city?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="zipCode">{t('zipcode')}</S.StyledLabel>
                            <S.StyledInput
                                type="number"
                                {...register('zipCode', {
                                    required: `${t('zipcode')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.zipCode?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                    </S.Column>
                    <S.Column>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="lastName">{t('last_name')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'lastName'}
                                {...register('lastName', {
                                    required: `${t('last_name')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.lastName?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>

                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="phone">{t('phone_number')}</S.StyledLabel>
                            <S.StyledInput
                                type="tel"
                                id={'phone'}
                                {...register('phone', {
                                    required: `${t('phone_number')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.phone?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="address">{t('address')}</S.StyledLabel>
                            <S.StyledInput
                                type="text"
                                id={'address'}
                                {...register('address', {
                                    required: `${t('address')} ${t('required')}`,
                                })}
                                placeholder={t('type_here')}
                            />
                            <ErrorWithPosition>{errors.address?.message}</ErrorWithPosition>
                        </S.FormInputWrapper>
                        <S.FormInputWrapper>
                            <S.StyledLabel htmlFor="countryResidence">
                            {t('country_of_residence')}
                            </S.StyledLabel>
                            <S.BillingInfoCountySelectWrap>
                                <CountrySelect
                                    register={register}
                                    required={true}
                                    setValue={form.setValue}
                                    defaultValue={
                                        user?.country
                                            ? user?.country
                                            : defaultValues.countryResidence
                                    }
                                />
                            </S.BillingInfoCountySelectWrap>
                            <ErrorWithPosition>
                                {errors.countryResidence?.message}
                            </ErrorWithPosition>
                        </S.FormInputWrapper>
                    </S.Column>
                </S.Wrapper>

                <S.ButtonWrapper>
                    <S.ColumnReverse>
                        <S.ResetBtn type={'reset'}>Reset</S.ResetBtn>
                    </S.ColumnReverse>

                    <S.ColumnReverse>
                        <S.SubmitBtn disabled={canSubmit} type={'submit'}>
                        {t('save')}
                        </S.SubmitBtn>
                    </S.ColumnReverse>
                </S.ButtonWrapper>

                <DevTool control={control} />
            </form>
            <ToastContainer />
        </>
    )
}

export default CorporateInformationForm
