import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../api/baseQuery'
import { IMessageCounterDTO, IMessageDTO, IMessageSeenDTO } from '../../DTO/IMessage'

export const messageAPI = createApi({
    reducerPath: 'messageAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getMessages: build.query<IMessageDTO[], void>({
            query: () => ({
                url: '/messages',
            }),
        }),
        getUnseenMessageCounter: build.query<IMessageCounterDTO, void>({
            query: () => ({
                url: '/unseen',
            }),
        }),
        getMessageSeen: build.query<IMessageSeenDTO, string>({
            query: (messageID) => ({
                url: '/seen/' + messageID,
            }),
        }),
    }),
})
