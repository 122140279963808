import { FC, useMemo } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useTheme } from 'styled-components'
import { t } from '../../utils/translate/t'

type SelectOptionType = { label: string; value: string }

interface IProps {
    setValue: UseFormSetValue<any>
    register: UseFormRegister<any>
    required: boolean
    defaultValue: string
    pageType?: boolean
}

const CountrySelect: FC<IProps> = ({ defaultValue, pageType, register, required, setValue }) => {
    const theme = useTheme()
    const options = useMemo(() => countryList().getData(), [])
    const countries = useMemo(() => countryList().getValueList(), [])
    const customStyles: any = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: pageType ? theme.colors.bgMenu : theme.colors.bgPage, //'#F4F4F4', '#212121'
            border: pageType ? 'none' : '1px solid ' + theme.colors.textGrey,
            padding: pageType ? '4px' : 0,
            fontSize: pageType ? '12px' : '14px',
            color:
                pageType && state.isSelected
                    ? theme.colors.textCurrentItemMenu
                    : theme.colors.textStandart,
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: pageType ? theme.colors.textGrey : theme.colors.textStandart,
            backgroundColor: pageType
                ? state.isSelected
                    ? theme.colors.borderTable
                    : theme.colors.bgMenu
                : state.isSelected
                ? theme.colors.borderTable
                : theme.colors.bgPage,
            padding: '.5rem 3rem .5rem .5rem',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: pageType ? theme.colors.textGrey : theme.colors.textStandart,
            fontSize: '12px',
            fontWeight: 400,
            fontStyle: 'italic',
        }),
        menuList: (base: any, state: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    }
    const changeHandler = (option: SelectOptionType | null) => {
        setValue('countryResidence', option?.value.toUpperCase() || '')
    }

    let requiredOption = {}
    if (required) {
        requiredOption = {
            required: `${t('country')} ${t('required')}`,
        }
    }

    return (
        <Select
            {...register('countryResidence', requiredOption)}
            options={options}
            isSearchable
            defaultInputValue={countries[defaultValue.toLowerCase()] || ''}
            onChange={changeHandler}
            styles={customStyles}
        />
    )
}
export default CountrySelect
