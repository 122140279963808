import styled from 'styled-components'
import { devices } from '../../devices'

export const LogoGroup = styled.div`
    background: transparent;
    display: flex;
    min-width: 240px;
    align-items: center;
    height: 80px;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;

    @media ${devices.md} {
        display: none;
    }
`
export const Logo = styled.img`
    padding: 0.2em 0.1em;
    border: 0;
    margin-right: 10px;
`
export const Company = styled.span`
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 2.29px;
    color: ${({ theme }) => theme.colors.textLogo};
`
