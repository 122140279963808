import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { devices } from '../../devices'
import iconCrown from '../../img/svg/leaderboard/crown.svg'

export const StyledTableWraper = styled.div`
    max-width: calc(100vw - 280px);
    @media screen and (${({ theme }) => theme.media.medium}) {
        margin: 0 2vw 2vh;
        overflow-x: auto;
    }

    @media ${devices.md} {
        max-width: 100%;
    }
`
export const StyledLeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-wrap: avoid;
    width: 211px;
    min-height: 180px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    border-radius: 140px 140px 0 0;
    padding-bottom: 10px;
`
export const StyledLeaderName = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.textStandart};

    & span {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        margin: 0 15px;
        text-align: center;
        overflow: hidden;
        word-wrap: break-word;
        @media ${devices.sm} {
            font-size: 14px;
            margin: 0 5px;
        }
    }
`
export const StyledLeaderWrap = styled.div`
    margin: 0 5px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:nth-child(2) ${StyledLeaderName}:before {
        content: ' ';
        width: 37px;
        height: 37px;
        background-color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        background-image: url(${iconCrown});
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin: 0 auto 12px;
        border-radius: 50%;
    }

    &:nth-child(1) .leaderPedestal {
        @media (max-width: 640px) {
            width: 29vw;
            min-height: 150px;
        }
    }
    &:nth-child(2) .leaderPedestal {
        height: 280px;
        padding-bottom: 78px;
        @media (max-width: 640px) {
            width: 29vw;
            min-height: 180px;
            height: 210px;
        }
    }

    &:nth-child(3) .leaderPedestal {
        height: 220px;
        padding-bottom: 15px;
        @media (max-width: 640px) {
            width: 29vw;
            min-height: 160px;
        }
    }
`
export const StyledLeaderTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:last-child {
        align-items: end;
    }
`
export const StyledLeaderPodium = styled.div`
    display: flex;
    width: 100%;
    height: inherit;
    flex-direction: row;
    align-items: center;
    max-width: 659px;
    margin: 0 auto 1px;
    @media screen and (${({ theme }) => theme.media.medium}) {
        margin-left: 30px;
    }

    @media ${devices.md} {
        align-items: end;
        justify-content: center;
        min-width: 320px;

        > :nth-child(1) {
            height: 150px;
        }
        > :nth-child(2) {
            display: flex;
            align-items: center;
            &.div {
                height: 198px;
                padding-bottom: 55px;
            }
        }
        > :nth-child(3) {
            height: 150px;
        }
    }
    @media (max-width: 640px) {
        margin-left: 0;
    }
`
export const StyledTableCellText = styled.span``
export const StyledLeaderOtherInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 0 20px;
    @media ${devices.sm} {
        padding: 0 8px;
    }
`
export const StyledLeaderTextInfo = styled.span`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textStandart};
`
export const StyledLeaderTextTag = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.textMenu};
`
export const TableWrapper = styled.div`
    overflow-x: scroll;
    max-width: 100vw;
`
export const StyledTable = styled.table`
    max-width: calc(100vw - 280px);
    width: 100%;
    white-space: nowrap;
    background-color: ${({ theme }) => theme.colors.bgContent};
    border-collapse: collapse;
    border-bottom: 10px ${({ theme }) => theme.colors.bgContent};
    box-sizing: border-box;
    min-width: 720px;
    border-radius: 15px;
    margin-top: 15px;
`
export const StyledTableHead = styled.thead``
export const StyledRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderTable};

    &.current {
        background-color: ${({ theme }) => theme.colors.bgCurrentTableRow};
        border-radius: 15px;
        box-sizing: border-box;
    }
`
export const StyledTd = styled.td`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.textStandart};

    &.userLabel {
        width: 2%;
    }

    &.userPlace {
        width: auto;
    }

    &.userCountry {
        height: 100%;
        text-align: center;
    }
`
export const StyledTh = styled.th`
    color: ${({ theme }) => theme.colors.textMenu};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
        text-align: center;
    }
`
export const StyledTableBody = styled.tbody`
    padding-bottom: 10px;

    & ${StyledRow} {
        height: 60px;
    }
`

export const StyledTdLabel = styled.div`
    padding: 10px;
    width: 51px;
    height: 40px;
    border-radius: 15px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textStandart};
    border: 1px solid ${({ theme }) => theme.colors.textStandart};
    margin: 10px 30px 10px 20px;
`
export const StyledLeaderMetrixLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLink};
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`
export const ImgWrapper = styled.div`
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    overflow: hidden;
    width: 25px;
    height: 25px;
    margin: 5px auto;
    @media ${devices.sm} {
        width: 15px;
        height: 15px;
    }
`

export const ImgCountryFlag = styled.img`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    object-fit: cover;
`

export const SelectSizeWrapper = styled.div`
    display: flex;
    width: 350px;
    height: 80px;
    align-items: center;
    margin-top: 20px;
    border-radius: 15px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.bgContent};

    & label {
        margin-right: 20px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.textStandart};
    }
`

export const LastUpdate = styled.span`
    display: block;
    font-weight: 500;
    width: 350px;
    margin-top: 15px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.bgContent};
    color: ${({ theme }) => theme.colors.textStandart};
    border-radius: 15px;
`

export const IsLoading = styled.div`
    margin-right: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textStandart};
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
`
export const ErrorMessage = styled.div`
    padding: 50px;
    color: ${({ theme }) => theme.colors.bgMsgCounter};

    & p {
        font-size: 24px;
        font-weight: 600;
    }

    & span {
        font-size: 14px;
        font-weight: 500;
    }
`
