import { FC } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'
import { useTheme } from 'styled-components'
import Select from 'react-select'
import { ISupportPayload } from '../../DTO/IContactSupport'
import { t } from '../../utils/translate/t'

type SelectOptionType = { label: string; value: string }
const topics: SelectOptionType[] = [
    { label: t('support_topic_review'), value: 'review' },
    { label: t('support_topic_payout'), value: 'payout' },
    { label: t('support_topic_refund'), value: 'refund' },
    { label: t('support_topic_technical_support'), value: 'technical_support' },
    { label: t('support_topic_other'), value: 'other' },
]

interface ISelectProps {
    setValue: UseFormSetValue<ISupportPayload>
    register: UseFormRegister<ISupportPayload>
    required: boolean
}

const SupportTopicSelect: FC<ISelectProps> = (props) => {
    const theme = useTheme()
    const customStyles: any = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: theme.colors.bgPage,
            color: theme.colors.textGrey,
            boxShadow: 'none',
            border: 0,
            width: '100%',
            borderRadius: '10px',
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textStandart,
            fontSize: '14px',
            fontWeight: 500,
            fontStyle: 'italic',
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: theme.colors.textMenu,
            cursor: 'pointer',
            backgroundColor: state.isSelected ? theme.colors.borderTable : theme.colors.bgPage,
            fontSize: '14px',
            padding: '10px',
        }),
        menuList: (base: any, state: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    }

    const changeHandler = (option: SelectOptionType | null) => {
        props.setValue('topic', option?.value.toUpperCase() || '')
    }

    let requiredOption = {}
    if (props.required) {
        requiredOption = {
            required: t('support_topic_required'),
        }
    }
    return (
        <>
            <Select
                {...props.register('topic', requiredOption)}
                styles={customStyles}
                options={topics}
                onChange={changeHandler}
            />
        </>
    )
}
export default SupportTopicSelect
