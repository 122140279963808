import styled from 'styled-components'

export const StyledTabs = styled.div``

interface Props {
    backgroundColor?: string
}

export const StyledTabsWrap = styled.div<Props>`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderTable};
    display: flex;
    height: 31px;
    flex-direction: row;
    gap: 10px;
`
export const StyledTabsBtnWrap = styled.div<Props>`
    display: flex;
    flex-flow: wrap;
    max-width: 555px;
    min-height: 42px;
    gap: 10px;
`
export const StyledTabContent = styled.div`
    margin-top: 20px;
`
export let StyledTab = styled.div<Props>`
    display: flex;
    height: 31px;
    align-items: center;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;

    cursor: pointer;

    &.current {
        color: ${({ theme }) => theme.colors.bgCurrentMenuItem};
        border-bottom: 1px solid ${({ theme }) => theme.colors.bgCurrentMenuItem};
    }
`

export const StyledBtnTab = styled(StyledTab)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 20px;
    min-width: 97px;
    height: 42px;

    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.bgCurrentMenuItem};

    /* buttons */
    background: ${({ theme }) => theme.colors.bgContent};
    border: 1px solid ${({ theme }) => theme.colors.borderTable};
    border-radius: 10px;

    &.active {
        color: ${({ theme }) => theme.colors.textLogOutBtn};
        background: ${({ theme }) => theme.colors.bgCurrentMenuItem};
    }

    &:hover {
        cursor: pointer;
    }
`
