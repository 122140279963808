import ThemeToggle from '../../components/ThemeToggle/ThemeToggle'
import * as S from './header.styles'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Theme } from '../../App'
import { useAuth } from '../../hooks/useAuth'
import { openMenu } from '../../store/menu/menu.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import { t } from '../../utils/translate/t'

interface IBurgerProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

export interface IHeader {
    title: string
    theme: Theme
    setTheme: Dispatch<SetStateAction<Theme>>
}

const Header: FC<IHeader> = (props) => {
    const dispatch = useDispatch()
    const { logout } = useActions()
    const navigate = useNavigate()

    const { isMenuOpen } = useSelector((state: any) => state.menu)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const handleClick = () => {
        dispatch(openMenu(!isMenuOpen))
        setIsMenuVisible(!isMenuOpen)
    }

    const { user } = useAuth()
    const title = props.title
    const theme = props.theme
    const setTheme = props.setTheme
    const [open, setOpen] = useState(false)
    const [fullName, setFullName] = useState('Guess')

    const logoutEvent = () => {
        logout()
        navigate('/')
    }

    useEffect(() => {
        if (user?.first_name && user?.last_name) {
            setFullName(user.first_name + ' ' + user.last_name)
        }
    }, [user])

    useEffect(() => {
        setIsMenuVisible(isMenuOpen)
    }, [isMenuOpen])

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={'anonymous'} />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap"
            />
            <S.HeaderWrap>
                <S.Header>
                    <S.LogoGroup>
                        <S.Logo src={'/svg/logo.svg'} alt="logo" />
                        <S.Company>{t('logotext')}</S.Company>
                    </S.LogoGroup>
                    <S.BodyHeaderMobile>
                        <S.LogoWrapper>
                            <S.BurgerBtn onClick={handleClick}>
                                <S.Cross className={isMenuVisible ? 'cross' : ''}>
                                    <S.BtnLine />
                                </S.Cross>
                            </S.BurgerBtn>
                            <S.LogoMobile src={'/svg/logo-mobile.svg'} alt="logo" />
                        </S.LogoWrapper>
                        <S.HeaderButtonsMobile>
                            <ThemeToggle {...{ theme, setTheme }} />
                            <S.LanguageBtn></S.LanguageBtn>
                            <S.ProfileDropdown onClick={logoutEvent}>
                                <S.ProfileAvatar></S.ProfileAvatar>
                                <S.ProfileName></S.ProfileName>
                            </S.ProfileDropdown>
                        </S.HeaderButtonsMobile>
                    </S.BodyHeaderMobile>
                    <S.BodyHeader>
                        <S.HeaderRow>
                            <S.PageName>{title}</S.PageName>
                            <S.BreadCrumbs>
                                <S.OtherPage>{t('fidelcrest_trade')}</S.OtherPage>
                                <S.ActivePage>{title}</S.ActivePage>
                            </S.BreadCrumbs>
                        </S.HeaderRow>
                        <S.HeaderButtons>
                            <ThemeToggle {...{ theme, setTheme }} />
                            <S.LanguageBtn></S.LanguageBtn>
                            <S.ProfileDropdown>
                                <S.ProfileAvatar></S.ProfileAvatar>
                                <S.ProfileName>{fullName}</S.ProfileName>
                            </S.ProfileDropdown>
                        </S.HeaderButtons>
                    </S.BodyHeader>
                </S.Header>
            </S.HeaderWrap>
        </>
    )
}

export default Header
