import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../baseQuery'

interface ISharedAccountResponse {
    AccountID: string
}

export const sharedAccountAPI = createApi({
    reducerPath: 'sharedAccountAPI',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        fetchSharedAccountID: build.query<ISharedAccountResponse, string>({
            query: (uuid) => ({
                url: `/shared-account/${uuid}`,
            }),
        }),
    }),
})
