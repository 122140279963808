import { FC, useEffect } from 'react'
import { IAppProps } from '../../App'
import * as S from './messages.styles'
import Message from '../../components/Messages/Message'
import { t } from '../../utils/translate/t'

const Messages: FC<IAppProps> = (props) => {
    useEffect(() => {
        return () => {
            props.setTitle(t('messages'))
        }
    }, [])

    return (
        <>
            <S.MessagesContainer>
                <Message />
            </S.MessagesContainer>
        </>
    )
}
export default Messages
