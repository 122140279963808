import Cookies from 'js-cookie'
import { IAuthResponse, ITokens } from '../../../store/user/user.interface'

export const getXToken = () => {
    const XToken = Cookies.get(AuthEnum.X_TOKEN)
    return XToken || null
}

export const getUserFromStorage = () => {
    return JSON.parse(localStorage.getItem('user') || '{}')
}

export const saveTokenStorage = (data: ITokens) => {
    Cookies.set(AuthEnum.X_TOKEN, data.token)
}

export const removeFromStorage = () => {
    Cookies.remove(AuthEnum.X_TOKEN)
    localStorage.removeItem('user')
}

export const saveToStorage = (data: IAuthResponse) => {
    saveTokenStorage(data)
    localStorage.setItem('user', JSON.stringify(data))
}
export const updateUserStorage = (key: keyof IAuthResponse, value: any) => {
    let userData = JSON.parse(localStorage.getItem('user') || '')
    if (!userData) return
    userData = { ...(userData as IAuthResponse), [key]: value }
    localStorage.setItem('user', JSON.stringify(userData))
}

export enum AuthEnum {
    X_TOKEN = 'x-token',
}
