import { FC, ReactElement, useEffect, useState } from 'react'
import { IMessageDTO } from '../../DTO/IMessage'
import { messageAPI } from '../../services/MessageService/MessageService'
import { t } from '../../utils/translate/t'
import Accordion from '../Accordion/Accordion'
import Seen from './Seen'
import * as S from './message.styles'

const Message: FC = () => {
    let messageHeader: ReactElement
    let messageDetails: ReactElement
    let messageHeaders: ReactElement[] = []
    let messagesDetails: ReactElement[] = []

    const [countUnseenMsg, setCountUnseenMsg] = useState(0)

    const { data: messagesData, isSuccess, isLoading, error } = messageAPI.useGetMessagesQuery()
    let errorPlaced = error && ('status' in error || 'data' in error)
    const [messagesArray, setMessagesArray] = useState<IMessageDTO[] | undefined>([])
    useEffect(() => {
        setMessagesArray(messagesData)
    }, [isSuccess])

    messagesArray?.forEach((messageItem: IMessageDTO) => {
        let handleMessageSeen = () => {
            if (!messageItem.Seen && messagesArray?.length >= 0) {
                setCountUnseenMsg((prevCountUnseenMsg) => prevCountUnseenMsg + 1)
                return console.log(`Count of unseenMessage: ${countUnseenMsg}`)
            }
            return console.log(`This message with ID ${messageItem.RowID} is already seen`)
        }
        messageHeader = (
            <>
                <Seen messageID={messageItem.RowID} messageSeen={messageItem.Seen} />
                <S.MessageTitle onClick={handleMessageSeen} key={messageItem.RowID}>
                    {messageItem.Subject}
                </S.MessageTitle>
            </>
        )
        messageHeaders.push(messageHeader)

        messageDetails = (
            <S.MessageDetail>
                <S.MessageDate>
                    {t('date')}: {messageItem.Created}
                </S.MessageDate>
                <S.MessageBody dangerouslySetInnerHTML={{ __html: messageItem.Body }} />
            </S.MessageDetail>
        )
        messagesDetails.push(messageDetails)
    })

    if (!messagesData && errorPlaced) {
        return (
            <>
                {error && (
                    <>
                        {'status' in error ? (
                            <S.ErrorMessage>
                                <p>ERROR {error.status}</p>
                                <span>{t('error_reload_page')}</span>
                            </S.ErrorMessage>
                        ) : (
                            <></>
                        )}
                        {'data' in error ? <>{error.data}</> : <></>}
                    </>
                )}
            </>
        )
    }

    return (
        <>
            <S.MessagesListWrapper>
                {isLoading && (
                    <>
                        <div>{t('loading')}...</div>
                    </>
                )}
                {(messagesArray?.length == 0 || undefined) && (
                    <>
                        <h3>{t('no_messages')}</h3>
                    </>
                )}
                {isSuccess && (
                    <Accordion
                        headerData={messageHeaders}
                        detailData={messagesDetails.map((item: ReactElement, index: number) => {
                            return <div key={index}>{item}</div>
                        })}
                    />
                )}
            </S.MessagesListWrapper>
        </>
    )
}
export default Message
